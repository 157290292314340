/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculatePermanentResidenceSponsorshipFederalFee: React.FC<IProps> = ({
	products
}) => {
	const SponsorshipFederalFee = products.find(element => element.contratationProductId == "102")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setPermanentResidenceSponsorshipFederalFee, getPermanentResidenceSponsorshipFederalFee, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
		if (((principalUsed()?.phase==='fase2' && principalUsed()?.province==='Quebec') || principalUsed()?.phase==='ambas'|| principalUsed()?.province!=='Quebec' )  && member.type===FamilyTypes.principal  && principalUsed()?.purposeVisit === "Residencia Permanente" &&  member.sponsorship === 'spouseOrPartner') {
			 	countwpFee++
				const memberInfo = {
					"memberName": member.name + " " + member.lastname,
					"memberType": member.type
				}
				arrayOfMembers.push(memberInfo) 
		}
	})
	if (SponsorshipFederalFee !== undefined) {
		setPermanentResidenceSponsorshipFederalFee({
			id: SponsorshipFederalFee.id,
			productContratationId: SponsorshipFederalFee.contratationProductId,
			description: SponsorshipFederalFee.description,
			totalFee: countwpFee * SponsorshipFederalFee.fee,
			name: SponsorshipFederalFee.name,
			quantity: countwpFee,
			fee: SponsorshipFederalFee.price,
			memberInfo:arrayOfMembers,
			type:'gf'

		})
	}
	return (
		<>
			{getPermanentResidenceSponsorshipFederalFee().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPermanentResidenceSponsorshipFederalFee().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span><Description>{getPermanentResidenceSponsorshipFederalFee().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{' '}{getPermanentResidenceSponsorshipFederalFee().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPermanentResidenceSponsorshipFederalFee().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePermanentResidenceSponsorshipFederalFee)
