/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Input,
	Select,
} from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../../../../Components/Modal/modal.css'
import 'react-datepicker/dist/react-datepicker.css';
import MyComponent from '../student-visa/DocusignCheckEnvelopeId'
import DateOfBirth from '../../MembersData/VisaQuestions/CustomComponents/DateOfBirth'
import { CivilStatusSelector } from 'Components/CivilStatusSelector'
import { observer } from 'mobx-react'
import { jsonArray, keys } from '../../ContratationAnswers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}

const MembersFormStudy: React.FC<IProps> = observer(({ closeModal, edit, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const { principalUsed, currentMember, handleChangeFamilyMember, getVisaTypeFromSurvey, familyMembers, setMembersErrors, membersErrors, displayErrorGlobal, setCurrentMember, setDisplayErrorGlobal } = rootStore.familyStore

	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [showStudyQuesitons, setShowStudyQuesitons] = React.useState(true)
	const [showCAQ, setShowCAQ] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [verificationDocusign, setverificationDocusign] = React.useState(false)
	const [activateDocument, setActivateDocument] = React.useState<string>("")
	const [displayErrors, setDisplayErrors] = useState(displayErrorGlobal)
	const [errorDate, setErrorDate] = useState(false)
	const [ageForBiometrics, setAgeForBiometrics] = useState(false)

	const totalProfFees = rootStore.familyStore.getTotalProfessionalFeesClean()



	const handleDiscountCodeChange = (e: any) => {
		const discountCode = e.target.value;
		handleChange('discountCode', discountCode);  
	
		
		if (discountCode === 'EDUCATIONTODAY' || discountCode === 'CANADATODAY'  || discountCode === 'canadatoday'   || discountCode === 'educationtoday') {
			/* popupDiscountWarning(); */
		}
	};
	
	const popupDiscountWarning = () => {
		let timerInterval: any;
		let progress = 0;
		const totalTime = 5000; // Tiempo total para completar la barra de progreso (5 segundos)
		const intervalTime = 50; // Intervalo para actualizar la barra de progreso
	
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html: `
				<p>Entiendo que este descuento no aplica para aplicaciones a permiso de estudios que deban ser enviadas antes del 1 de Noviembre 2024.</p>
				<p>En consecuencia, Immiland Law no se compromete bajo ninguna circunstancia a enviar mi aplicación a permiso de estudios antes del 1 de Noviembre 2024.</p>
				<br>
				<div id="progress-bar-container" style="position: relative; width: 100%; height: 10px; background-color: #e0e0e0; margin-bottom: 10px;">
					<div id="progress-bar" style="width: 0%; height: 100%; background-color: #13C296;"></div>
				</div>
				<button id="confirm-button" disabled class="swal2-confirm swal2-styled" style="background-color: #d3d3d3; color: white; font-size: 16px; padding: 10px 20px;">Entiendo</button>
			`,
			didOpen: () => {
				const confirmButton = Swal.getPopup()!.querySelector('#confirm-button') as HTMLButtonElement;
				const progressBar = Swal.getPopup()!.querySelector('#progress-bar') as HTMLElement;
	
				// Actualizar la barra de progreso
				timerInterval = setInterval(() => {
					progress += intervalTime;
					const percent = (progress / totalTime) * 100;
					progressBar.style.width = `${percent}%`;
	
					// Habilitar el botón cuando el progreso llegue al 100%
					if (progress >= totalTime) {
						clearInterval(timerInterval);
						confirmButton.disabled = false;
						confirmButton.style.backgroundColor = '#13C296'; // Cambia el color del botón cuando está habilitado
						confirmButton.classList.add('btn-no-shadow');
					}
				}, intervalTime);
	
				confirmButton.addEventListener('click', () => {
					Swal.close();
					// Aquí puedes añadir lógica adicional si necesitas hacer algo cuando clickeen el botón.
				});
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false, // Esto desactiva el botón "Entendido" predeterminado de SweetAlert2
			focusConfirm: false,
			customClass: {
				confirmButton: 'btn-no-shadow',
			},
			buttonsStyling: false,
		});
	};

	useEffect(() => {
        if (currentMember.type === FamilyTypes.children && currentMember.age <= 5) {
            handleChange('civilStatus', 'Soltero');
        }
    }, [currentMember.type, currentMember.age]);



	const { increaseEstep } = rootStore.formBuilderStore
	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
	}, [emailForUploaders])
	useEffect(() => {
		validateShowBiometrics()
		validateShowCAQ()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => {
		validateActiveDocument("biometrics")
		validateActiveDocument("hasLoa")
		validateActiveDocument("academicRecord")
		validateActiveDocument("statusEvidence")
		validateActiveDocument("coopLetter")
	}, [activateDocument])
	const validateActiveDocument = (documenttype: string) => {
		if (documenttype === "biometrics") {
			return currentMember.documents[0].active
		}
		if (documenttype === "hasLoa") {
			return currentMember.documents[1].active
		}
		else if (documenttype === "statusEvidence") {
			return currentMember.documents[3].active
		}
		else if (documenttype === "academicRecord") {
			return currentMember.documents[4].active
		}
		else if (documenttype === "coopLetter") {
			return currentMember.documents[5].active
		}
		else {
			return undefined
		}
	}
	const handleChangeMember = (id: any) => {
		const newCurrentMember = familyMembers.find((member) => member.id === id);
		if (newCurrentMember) {
			// Set the newCurrentMember as the currentMember
			setCurrentMember(newCurrentMember);
		}
	}
	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {

		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })

		handleChangeFamilyMember(newFamily)
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}

		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}

		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}

		if (currentMember.etaImmiland === 'no') {
			setShowBiometricSelect(false)
			handleChange('hasBiometrics', false)
			return
		} else if (
			currentMember.nationality.toLocaleLowerCase() === 'brazil' &&
			currentMember.etaImmiland === 'si' &&
			currentMember.visaEU === 'si' &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			handleChange('hasBiometrics', false)
			return
		}
	}
	const validateShowCAQ = () => {
		if (
			currentMember.quebec === 'si' ||
			(principalUsed()?.quebec == 'si' &&
				currentMember.type === FamilyTypes.children &&
				currentMember.age >= 5 &&
				currentMember.age <= 17)
		) {
			setShowCAQ(true)
			currentMember.validateCAQquestions = true
			setShowStudyQuesitons(true)
		} else if (
			currentMember.quebec === 'no' ||
			principalUsed()?.quebec == 'no' ||
			currentMember.type != FamilyTypes.children ||
			(currentMember.age < 5 && currentMember.age > 17)
		) {
			setShowCAQ(false)
			currentMember.validateCAQquestions = false
		}

		validateNormalVisitorVisaLogic()
	}

	const validateNormalVisitorVisaLogic = () => {
		//if member is grandchildren o otros , is visitor visa logic
		if (
			currentMember.type === FamilyTypes.grandchildren ||
			currentMember.type === FamilyTypes.otros
		) {
			handleChange('normalVisitorVisaLogic', true)
			return
		}

		//When we are calculating fees with normal visitor visa logic
		if (
			(principalUsed()?.principalHasPathway === 'si' &&
				currentMember.type === FamilyTypes.conyuge) ||
			(currentMember.type === FamilyTypes.children && currentMember.age < 5)
		) {
			handleChange('normalVisitorVisaLogic', true)
		} else {
			handleChange('normalVisitorVisaLogic', false)
		}
	}


	const emailRegex = new RegExp(
		'^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
	)

	const checkIfAllowSave = () => {
		if (currentMember.isPreviousClient === "si" && currentMember.validContract !== true && currentMember.type === FamilyTypes.principal) {
			return false
		}

		//end uploaders and docusign

		if (new Date() < currentMember.birthday) {
			return false
		}

		if (currentMember.email != currentMember.confirmEmail) {
			return false
		}

		if (((!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
			if (currentMember.type === FamilyTypes.principal) {
				return false
			}
			if (currentMember.type === FamilyTypes.conyuge && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				return false
			}

			if (currentMember.type === FamilyTypes.children && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				return false
			}
		}

		if (currentMember.wantsDependantChildren === "si" && !currentMember.howManyDependantChild) {
			return false
		}

		if (currentMember.wantsDependantChildren === "si" && currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) {
			return false
		}

		if (((ageForBiometrics === true
			&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
			&& currentMember.type === FamilyTypes.principal)
			||
			((currentMember.type === FamilyTypes.conyuge
				|| currentMember.type === FamilyTypes.children)
				&&
				(ageForBiometrics === true)
				&&
				(ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
			))) {
			if (!currentMember.validBiometrics) {
				return false
			}
		}

		/* if ((currentMember.civilStatus === "Casado" || currentMember.civilStatus === "Unión marital de hecho") && currentMember.type === FamilyTypes.principal) {
			if (!currentMember.wantsAddConyugue) {
				return false
			}
		} */



		if (!currentMember.email && currentMember.type === FamilyTypes.principal) {
			return false
		}

		if (!currentMember.confirmEmail && currentMember.type === FamilyTypes.principal) {
			return false
		}

		/* if (currentMember.type !== 'Conyuge o Pareja de hecho' && !currentMember.civilStatus) {
			return false
		} */

		if (currentMember.type === 'Solicitante principal' && !currentMember.phoneNumber) {
			return false
		}

		if (!currentMember.validcoopLetter && currentMember.type === FamilyTypes.principal) {
			return false
		}

		if (!currentMember.migratoryStatus && currentMember.type === FamilyTypes.principal) {
			return false
		}
		/* if (!currentMember.appliedBefore && currentMember.type === FamilyTypes.principal) {
			return false
		} */
		if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted
			|| !currentMember.phoneNumber) {
			return false
		} else {
			return true
		}
	}
	const checkErrorFamily = () => {
		//uploaders and docusign
		const familyErrorInfo: any = []
		familyMembers.map((familyMember: any) => {
			let counter = 0
			if (familyMember.isPreviousClient === "si" && familyMember.validContract !== true && familyMember.type === FamilyTypes.principal) {
				counter++
			}

			//end uploaders and docusign

			if (new Date() < familyMember.birthday) {
				counter++
			}

			if (familyMember.email != familyMember.confirmEmail) {
				counter++
			}

			if (((!familyMember.validBiometrics && familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !familyMember.isPreviousClient)) {
				if (familyMember.type === FamilyTypes.principal) {
					counter++
				}
				if (familyMember.type === FamilyTypes.conyuge && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
				}

				if (familyMember.type === FamilyTypes.children && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
				}
			}

			if (familyMember.wantsDependantChildren === "si" && !familyMember.howManyDependantChild) {
				counter++
			}

			if (familyMember.wantsDependantChildren === "si" && familyMember.howManyDependantChild && familyMember.howManyDependantChild < 0) {
				counter++
			}

			if ((( ageForBiometrics === true
				&& familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
				&& familyMember.type === FamilyTypes.principal)
				||
				((familyMember.type === FamilyTypes.conyuge
					|| familyMember.type === FamilyTypes.children)
					&&
					(ageForBiometrics === true)
					&&
					(ETA.find((etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
				))) {
				if (!familyMember.validBiometrics) {
					counter++
				}
			}


			if (!familyMember.email && familyMember.type === FamilyTypes.principal) {
				counter++
			}

			if (!familyMember.confirmEmail && familyMember.type === FamilyTypes.principal) {
				counter++
			}

			if (familyMember.type !== 'Conyuge o Pareja de hecho' && !familyMember.civilStatus) {
				counter++
			}

			if (familyMember.type === 'Solicitante principal' && !familyMember.phoneNumber) {
				counter++
			}

			if (!familyMember.name) {
				counter++
			}
			if (!familyMember.lastname) {
				counter++
			}
			if (!familyMember.birthdayFormatted) {
				counter++
			}
			if (!familyMember.phoneNumber && familyMember.type === FamilyTypes.principal) {
				counter++
			}
			if (!currentMember.validcoopLetter && familyMember.type === FamilyTypes.principal) {
				counter++
			}
			if (!currentMember.migratoryStatus && familyMember.type === FamilyTypes.principal) {
				counter++
			}
			familyErrorInfo.push({ id: familyMember.id, errors: counter })
		})
		setMembersErrors(familyErrorInfo)
		return familyErrorInfo
	}
	const popupDisplaySchoolLetter = () => {
		const checkboxText = 'ACEPTAR';
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html: `
			<p>Recuerde que este es uno de los requisitos de elegibilidad para aplicar a un permiso co-op. Debe conseguir este documento para poder aplicar por esta vía.
            </p>

			<input type="checkbox" id="checkbox-terms">
			<label for="checkbox-terms">${checkboxText}</label>
		  `,
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: '¡Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
			preConfirm: () => {
				const checkbox = document.getElementById('checkbox-terms') as HTMLInputElement;
				if (!checkbox?.checked) {
					Swal.showValidationMessage('Debes aceptar las condiciones para continuar');
					return false;
				}
			},
		});
	}

	let indexChildren = 0
	const formPersonal = () => {
		return (
			<div>
				<div className='flex flex-wrap justify-between'>
					{currentMember.type === 'Solicitante principal' &&
						<BoxModal>
							<Label>Tipo de solicitud</Label>
							<Label style={{ color: '#22AD5C' }} >{currentMember.typeOfApplication}</Label>
						</BoxModal>}

					<BoxModal>
						<Label>Nombres: (Como aparece en su pasaporte)</Label>
						<Input
							name='Nombres'
							className='custom-input'
							label='Nombres'
							placeholder='Nombres'
							type='text'
							value={currentMember.name}
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('name', e.target.value) : handleChange('name', undefined) }
							}}
							size='small'
						/>
						{!currentMember.name && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Los nombres son obligatorios</span>}
					</BoxModal>

					<BoxModal>
						<Label>Apellidos: (Como aparece en su pasaporte) </Label>
						<Input
							name='Apellidos'
							className='custom-input'
							label='Apellidos'
							placeholder='Apellidos'
							type='text'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('lastname', e.target.value) : handleChange('lastname', undefined) }
							}}
							value={currentMember.lastname}
							size='small'
						/>
						{!currentMember.lastname && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Los apellidos son obligatorios
							</span>}
					</BoxModal>

					{currentMember.type === FamilyTypes.principal &&
						<BoxModal>
							<Label>Email</Label>
							<Input
								className='custom-input'
								label='Email'
								defaultValues={currentMember.email}
								placeholder='Email'
								type='email'
								id='email'
								name='Email'
								onChange={(e: any) => {
									const emailLowerCase = e.target.value.toLowerCase();
									emailLowerCase !== '' && emailLowerCase.match(emailRegex)
									  ? handleChange('email', emailLowerCase)
									  : handleChange('email', undefined);
								  }}
								value={currentMember.email}
								size='small'
								required={true}
								autoComplete='off'
							/>
							{!currentMember.email && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su email y revise que este escrito de forma correta</span>}
						</BoxModal>}

					{currentMember.type === FamilyTypes.principal &&
						<BoxModal>
							<Label>Confirmar Email</Label>
							<div className='relative'>
								<Input
									className='custom-input'
									label='Email'
									defaultValues={currentMember.confirmEmail}
									placeholder='Email'
									type='email'
									id='email'
									name='email'
									value={currentMember.confirmEmail}
									onChange={(e: any) => {
										const confirmEmailLowerCase = e.target.value.toLowerCase(); 
										confirmEmailLowerCase !== '' && confirmEmailLowerCase.match(emailRegex)
										  ? handleChange('confirmEmail', confirmEmailLowerCase)
										  : handleChange('confirmEmail', undefined);
										confirmEmailLowerCase !== '' && localStorage.setItem("email", confirmEmailLowerCase);
									  }}
									size='small'
									required={true}
									autoComplete='off'
								/>
							</div>
							{(!currentMember.confirmEmail || currentMember.confirmEmail !== currentMember.email) && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>El campo escrito en " email" y en "confirmar email" deben ser iguales</span>}
						</BoxModal>}

					{currentMember.type === 'Solicitante principal' &&
						<BoxModal>
							<Label>Número de telefono : (+código del país) + (000-000-0000)</Label>
							<Input
								name='Numero de telefono'
								defaultValues={currentMember.phoneNumber}
								className='custom-input'
								label='Numero de telefono'
								placeholder='Numero de telefono'
								type='text'
								value={currentMember.phoneNumber}
								onChange={(e: any) => handleChange('phoneNumber', e.target.value)}
								size='small'
							/>
							{!currentMember.phoneNumber && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su numero de telefono </span>}
						</BoxModal>}

					<DateOfBirth displayErrors={displayErrors} errorDate={errorDate} setErrorDate={setErrorDate} setAgeForBiometrics={setAgeForBiometrics} edit={edit} />

					<BoxModal>
						<Label>País emisor de su pasaporte</Label>
						<div style={{ color: '#22AD5C' }} className='relative '>
							{currentMember.type === FamilyTypes.principal ? <>{currentMember.passportCountry}</> : <CountrySelector
								handleChange={(e: any) => {
									{ e.target.value != '' ? handleChange('passportCountry', e.target.value) : handleChange('passportCountry', undefined) }
								}}
								value={currentMember.passportCountry || ''}
								label='Pais'
							/>}
						</div>
						{!currentMember.countryOfCitizenship && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe seleccionar pais de ciudadanía
							</span>}
					</BoxModal>

					<BoxModal>
						<Label>Estado Civil</Label>
						<div className='relative'>
							{currentMember.type === FamilyTypes.children && currentMember.age <= 5 ? (
								// Display "Soltero" for children
								<p style={{ color: '#22AD5C', fontWeight: 'bold' }}>Soltero</p>
							) : (
								// Existing code for other member types
								currentMember.type !== 'Conyuge o Pareja de hecho' ? (
									<CivilStatusSelector
										handleChange={(e: any) => {
											e.target.value !== '' 
												? handleChange('civilStatus', e.target.value)
												: handleChange('civilStatus', undefined);

											if (e.target.value === "Casado" || e.target.value === "Unión marital de hecho") {
												console.log('Casado o en UMdH, cm:', currentMember.type);
											} else {
												handleChange('wantsAddConyugue', 'no');
												handleChange('ApplyWithSpouse', 'no');
											}
										}}
										value={currentMember.civilStatus || ''}
										label='Pais'
									/>
								) : (
									<p style={{ color: '#22AD5C' }}>{principalUsed()?.civilStatus}</p>
								)
							)}
						</div>
						{(!currentMember.civilStatus && displayErrors === true) &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe seleccionar su estado civil
							</span>}
					</BoxModal>
					<BoxModal>
						<Label>
							¿Cual es su estatus migratorio en Canadá?
						</Label>
						<Select
							name='¿Cual es su estatus migratorio en Canadá?'
							defaultValue={currentMember.migratoryStatus}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='¿Cual es su estatus migratorio en Canadá?'
							value={currentMember.migratoryStatus}
							onChange={(e: any) => {
								if (e.target.value === "visitante" && (principalUsed()?.typeOfApplication === "Study Visa Extension" || currentMember.typeOfApplication === "Study Visa Extension")) { /* empty */ }
								{ e.target.value != '' ? handleChange('migratoryStatus', e.target.value) : handleChange('migratoryStatus', undefined) }
							}}
						>
							<option value=''>Seleccione</option>
							<option value='estudiante'>Estudiante</option>
						</Select>
						{!currentMember.migratoryStatus && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar su estatus migratorio actual</span>}
					</BoxModal>

					<BoxModal>
						<Label >
							¿Tiene biométricos válidos?
						</Label>
						<Select
							name='tieneBiometricos'
							defaultValue={currentMember.validBiometrics}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Tiene biometricos'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('validBiometrics', e.target.value) : handleChange('validBiometrics', undefined) }
								if (e.target.value === "si") {
									currentMember.documents[0].active = true
								} else {
									currentMember.documents[0].active = false
								}

							}}
							value={currentMember.validBiometrics}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.validBiometrics &&
							displayErrors === true &&
							<span className='text-tomato-500 text-xs italic'
								style={{
									marginTop: '-11px',
									marginBottom: '11px'
								}}>Debe responder si tiene biometricos
							</span>}
					</BoxModal>

					<BoxModal>
						<Label >
							¿Posee una carta de escuela que confirme que el co-op es requisito mandatorio de su programa de estudios?
							?
						</Label>
						<Select
							name='Posee  carta de la escuela'
							defaultValue={currentMember.validcoopLetter}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Posee una carta de escuela que confirme que el co-op es requisito mandatorio de su programa de estudios?                                            '
							value={currentMember.validcoopLetter}
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('validcoopLetter', e.target.value) : handleChange('validcoopLetter', undefined) }
								{ e.target.value === 'no' && popupDisplaySchoolLetter() }
							}}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.validcoopLetter && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si posee  carta de la escuela</span>}
					</BoxModal>
					<BoxModal>
						<p className='md:h-2 h8' style={{ fontSize: '0.7rem', fontWeight: 'bold', color: '#22AD5C', marginBottom: '10px' }}>¡Obtenga un descuento!</p>
						<Label
							style={{ fontSize: '0.7rem' }}
						>
							¿Anteriormente ha realizado algún proceso de visado con Immiland?
						</Label>
						<Select
							name='Anteriormente ha realizado algun proceso de visado con Immiland'
							defaultValue={currentMember.isPreviousClient}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Anteriormente ha realizado algun proceso de visado con Immiland'
							value={currentMember.isPreviousClient}
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('isPreviousClient', e.target.value) : handleChange('isPreviousClient', undefined) }
							}}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.isPreviousClient && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá</span>}
					</BoxModal>
					{/* boton contrac docusign */}
					{currentMember.isPreviousClient === "si" && <BoxModal className='sm:w-96 px-3 mb-3 md:mb-3 w-full' style={{ marginTop: '12px' }}>
						<MyComponent setVerificationDocusign={setverificationDocusign} />
						{currentMember.isPreviousClient === "si" && !currentMember.validContract && membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>No puede guardar sus datos si su número de contrato no ha sido verificado</span>}
					</BoxModal>
					}
					{!currentMember.validContract && currentMember.type === FamilyTypes.principal && (currentMember.validContract === undefined || currentMember.validContract === false) && /* totalProfFees >= 700 && */
						<BoxModal>
						<Label>Código de Descuento</Label>
						<Input
							className='custom-input'
							label='Codigo'
							placeholder='Codigo'
							type='text'
							value={currentMember.discountCode}
							onChange={(e: any) => handleDiscountCodeChange(e)}
							size='small'
						/>
						</BoxModal>
					}
				</div>
			</div>)
	}

	const sorted = () => {
		const principalMember = familyMembers.find(member => member.type === FamilyTypes.principal);
		const conyugeMembers = familyMembers.filter(member => member.type === FamilyTypes.conyuge);
		const childrenMembers = familyMembers.filter(member => member.type === FamilyTypes.children);
		const sortedFamilyMembers = [];
		if (principalMember) {
			sortedFamilyMembers.push(principalMember);
		}
		if (conyugeMembers.length > 0) {
			sortedFamilyMembers.push(...conyugeMembers);
		}
		if (childrenMembers.length > 0) {
			sortedFamilyMembers.push(...childrenMembers);
		}

		return sortedFamilyMembers
	}

	return (
		<div className='my-4 h-full'>
			<div className='flex flex-wrap justify-center'>
				<Box>

					<>
						<form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
							<div className='flex flex-wrap -mx-3 mb-6'>
								<div className='mx-16'>
									{sorted() && sorted().length > 0 && sorted().map((member, index) => {
										{ member.type === FamilyTypes.children && indexChildren++ }
										const memberError = membersErrors.find((error: any) => error.id === member.id);
										if (memberError && memberError.errors !== undefined) {
											return (
												<div>
													<div className='form-tab-continer'>
														{memberError.errors ? <>
															<div key={member.id} className='w-full'>
																<button className='custom-input-error'
																	onClick={(e) => {
																		e.preventDefault();
																		handleChangeMember(member.id);
																		if (memberError.errors > 0) {
																			setDisplayErrorGlobal(true);
																		} else {
																			setDisplayErrorGlobal(false);
																		}
																	}}
																>
																	<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																	<div className='flex flex-row gap-4'>
																		<div className='error-flag-container'>
																			<div className='error-flag-simbol'>
																				<div>x</div>
																			</div>
																			<p> Tiene {memberError.errors} errores</p>
																		</div>
																		<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																	</div>

																</button>
															</div></> :
															<>
																<div key={member.id} className='w-full'>
																	<button className='custom-input-success'
																		onClick={(e) => {
																			e.preventDefault();
																			handleChangeMember(member.id);
																			if (memberError.errors > 0) {
																				setDisplayErrorGlobal(true);
																			} else {
																				setDisplayErrorGlobal(false);
																			}
																		}}
																	>
																		<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																		<div className='flex flex-row gap-4'>
																			<div className='success-flag-container'>
																				<div className='success-flag-simbol'>
																					<div>
																						<FontAwesomeIcon style={{ fontSize: '10px', color: 'white' }} icon={faCheck} />
																					</div>
																				</div>
																				<p> Tiene datos correctos</p>
																			</div>
																			<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																		</div>

																	</button>
																</div>
															</>}

														{member.id === currentMember.id &&
															formPersonal()}
													</div>

												</div>
											);
										} else {
											return (
												<div>
													<div className='form-tab-continer'>

														<button className='custom-input-button' onClick={(e) => {
															e.preventDefault();
															handleChangeMember(member.id);
														}} key={member.id} >

															{member.type} {member.type === FamilyTypes.children && `#${indexChildren} `}
														</button>

														{member.id === currentMember.id &&
															formPersonal()}
													</div>
												</div>
											);
										}
									})}


									{membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 ? <div className='errors-main-flag'>
										<div className='error-flag-simbol'>
											<div>x</div>
										</div>
										<div>
											<p style={{ color: '#BC1C21', fontSize: '0.9rem' }}>{`tiene ${membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0)} errores en su formulario`}</p>
											<p style={{ color: '#F56060', fontSize: '0.7rem' }}>Por favor, revise cada categoria y corrijalos para poder continuar</p>
										</div>
									</div> : null}
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
										{edit === false ?
											<Button type='button' onClick={() => {
												setCurrentMember(familyMembers[0])
												setStep(3)
											}} className='backbutton-disc2'>
												Atras
											</Button> : null
										}

										<Button type='button' onClick={() => {
											console.log('hola andrea', familyMembers)
											window.scrollTo(0, 0)
											if (checkIfAllowSave() === true && (checkErrorFamily().some((item: any) => item.errors > 0) === false)) {
												jsonArray.length = 0
												handleChange('principal', true)
												handleChange('valid', true)

												for (const familyMem of familyMembers) {
													const answers: any = {
													}
													for (const key in keys) {
														answers[keys[key]] = familyMem[key as keyof typeof familyMem]
													}
													jsonArray.push(answers)
													familyMem.answers = []
													familyMem.answers[0] = answers
												}
												closeModal()
												setDisplayErrors(false)
												increaseEstep()
											} else {
												checkErrorFamily()
												setDisplayErrors(true)
											}
										}} className='startbutton-disc2'>
											Guardar
										</Button>
									</div>
								</div>
							</div>

						</form>
					</>
				</Box>

			</div>
		</div>

	)

})

export default MembersFormStudy

