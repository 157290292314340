/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
/* eslint-disable no-empty */
import {
  FamilyTypes,
  IDetails,
  IFamilyGroup,
  IFamilys,
  IProduct,
  initialDetails,
  initialValues,
  productInitialDetails,
} from '../types'
import { action, makeObservable, observable } from 'mobx'
import { v4 as uuidv4 } from 'uuid';
import { RootStore } from 'stores/rootStore'
import { configure } from 'mobx'


configure({
  enforceActions: 'never',
})

export default class FamilyStore {
  rootStore: RootStore

  @observable familyMembers: IFamilys[] = []
  @observable lastStep=1
  @observable familygroup: IFamilyGroup[] = []
  @observable currentMember: IFamilys = initialValues
  @observable membersErrors: any = []
  @observable totalBiometricos: IDetails = initialDetails
  @observable totalBiometricsFamily: IDetails = initialDetails
  @observable totalEta: IDetails = initialDetails
  @observable totalFees: IDetails = initialDetails
  @observable totalProfesional: IDetails = initialDetails
  @observable totalCaq: IDetails = initialDetails
  @observable totalCaqWork: IDetails = initialDetails
  @observable totalMifi: IDetails = initialDetails
  @observable totalTransactionFee: IDetails = initialDetails
  @observable totalProfesionalStudy: IDetails = initialDetails
  @observable totalProfesionalExtention: IDetails = initialDetails
  @observable totalGovermentExtention: IDetails = initialDetails
  @observable totalFeeStudy: IDetails = initialDetails
  @observable visaTypeSurvey = ''
  @observable totalPrice = 0
  @observable countGovermentFeeETA = 0
  @observable countProfessionalFeeETA = 0
  @observable totalProfesionalFeeWork: IDetails = initialDetails
  @observable totalGovermentFeeWork: IDetails = initialDetails
  @observable totalCloseWorkPermitFee: IDetails = initialDetails
  @observable totalLMIAMIFIReviewFee: IDetails = initialDetails
  @observable totalLMIAExemption: IDetails = initialDetails
  @observable visitorVisaTotalPrice: IDetails = initialDetails
  @observable totalPreviousDiscount = 0
  @observable totalPreviousRejectionDiscount = 0
  @observable totalDiscountCode = 0
  @observable totalCanadaTaxFee: IDetails = initialDetails
  @observable resumeService = ''
  @observable productAplicantsService: any[] = []
  @observable isPreviousClient = false
  @observable answersJson = ''
  @observable answersJsonArray: string[] = []
  //after new implementation
  @observable visitorVisaDetails: IProduct = productInitialDetails
  @observable visitorVisaDetailsLessThanSixMonths: IProduct = productInitialDetails
  @observable etaDetails: IProduct = productInitialDetails
  @observable etaFederalFeesDetails: IProduct = productInitialDetails
  @observable visitorVisaFederalDetails: IProduct = productInitialDetails
  @observable ExpiredPermitDetails: IProduct = productInitialDetails
  @observable Atip: IProduct = productInitialDetails
  @observable AtipFee: IProduct = productInitialDetails
  @observable ExpiredPermitDetailsDate: IProduct = productInitialDetails  
  @observable ExpiredPermitDetailsMoreThan90: IProduct = productInitialDetails
  @observable ExpiredRestoration: IProduct = productInitialDetails
  @observable visitorRecordDetails: IProduct = productInitialDetails
  @observable visitorRecordFederalDetails: IProduct = productInitialDetails
  @observable transitVisaDetails: IProduct = productInitialDetails
  @observable transitVisaDetailsFee: IProduct = productInitialDetails
  @observable superVisaDetails: IProduct = productInitialDetails
  @observable superVisaFederalFeeDetails: IProduct = productInitialDetails
  @observable dependantVisitorLessFiveDetails: IProduct = productInitialDetails
  @observable dependantVisitorPlussFiveDetails: IProduct = productInitialDetails
  @observable submissionDetails: IProduct = productInitialDetails
  @observable biometricsDetails: IProduct = productInitialDetails
  @observable independantChildrenBiometrics: IProduct = productInitialDetails
  @observable familyBiometricsDetails: IProduct = productInitialDetails
  @observable canadaTaxFeesDetails: IProduct = productInitialDetails
  @observable discountHundredDetails: IProduct = productInitialDetails
  @observable discountCodeDetails: IProduct = productInitialDetails
  @observable discountPreviousClient: IProduct = productInitialDetails
  @observable discountDetails: IProduct = productInitialDetails
  @observable CAQStudentDetails: IProduct = productInitialDetails
  @observable CAQExtentionStudentDetails: IProduct = productInitialDetails
  @observable CAQStudentGovernmentFee: IProduct = productInitialDetails
  @observable CAQWorkerDetails: IProduct = productInitialDetails
  @observable CAQWorkerGovernmentFeeDetails: IProduct = productInitialDetails
  @observable citizenshipFeeDetails: IProduct = productInitialDetails
  @observable DocumentAmendmentFeeDetails: IProduct = productInitialDetails
  @observable PremandamusFeeDetails: IProduct = productInitialDetails
  @observable DocumentReplacementFeeDetails: IProduct = productInitialDetails
  @observable DocumentReplacementGFFeeDetails: IProduct = productInitialDetails
  @observable citizenshipMinorGovernmentFeeDetails: IProduct = productInitialDetails
  @observable citizenshipAdultGovernmentFeeDetails: IProduct = productInitialDetails
  @observable CAQMinorDetails: IProduct = productInitialDetails
  @observable CAQMinorGovernmentFeeDetails: IProduct = productInitialDetails
  @observable studyPermitLangDetails: IProduct = productInitialDetails
  @observable studyPermitAcademicDetails: IProduct = productInitialDetails
  @observable studyPermitAcademicStreamDetails: IProduct = productInitialDetails
  @observable studyPermitFederalFeeDetails: IProduct = productInitialDetails
  @observable PartnerOpenWorkSpouseDetails: IProduct = productInitialDetails
  @observable OpenWorkPermitHolderFederalFeeDetails: IProduct = productInitialDetails
  @observable OpenWorkPermitVulnerableDetails: IProduct = productInitialDetails
  @observable LMIAExExtentionPOEFeeDetails: IProduct = productInitialDetails
  @observable LMIAPosExtentionPOEFeeDetails: IProduct = productInitialDetails
  @observable LMIAExExtentionONFeeDetails: IProduct = productInitialDetails
  @observable LMIAPosExtentionONFeeDetails: IProduct = productInitialDetails
  @observable WorkPermitIncludesExtentionFederalFee: IProduct = productInitialDetails
  @observable workPermitExemptONDetails: IProduct = productInitialDetails
  @observable workPermitExemptPOEDetails: IProduct = productInitialDetails
  @observable workPermitPositivePOEDetails: IProduct = productInitialDetails
  @observable workPermitPositiveONDetails: IProduct = productInitialDetails
  @observable partnerOpenWorkPermitPOEDetails: IProduct = productInitialDetails
  @observable partnerOpenWorkPermitONDetails: IProduct = productInitialDetails
  @observable partnerWorkingHolidayONDetails: IProduct = productInitialDetails
  @observable WorkingHolidayProfesionalFeeDetails: IProduct = productInitialDetails
  @observable studyPermitExtentionONDetails: IProduct = productInitialDetails
  @observable studyPermitLanguageExtentionONDetails: IProduct = productInitialDetails
  @observable spousePartnerOpenWorkPermitExtentionOnDetails: IProduct = productInitialDetails
  @observable travelDocumentRenovationStickerVisaDetails: IProduct = productInitialDetails
  @observable dependantStudyPermitExtentionOverFiveDetails: IProduct = productInitialDetails
  @observable dependantStudyPermitExtentionLessFiveDetails: IProduct = productInitialDetails
  @observable PGWPPOE: IProduct = productInitialDetails
  @observable PGWPON: IProduct = productInitialDetails
  @observable PGWPEx: IProduct = productInitialDetails
  @observable SponsorshipApplicationIn: IProduct = productInitialDetails
  @observable PermanentResidenceSponsorshipFederalFee: IProduct = productInitialDetails
  @observable OpenWorkPermitMainApplicant: IProduct = productInitialDetails
  @observable otherPRFederal: IProduct = productInitialDetails
  @observable spouseotherPRFederal: IProduct = productInitialDetails
  @observable depedantotherPRFederal: IProduct = productInitialDetails
  @observable offertemplate: IProduct = productInitialDetails
  @observable CSQDetails: IProduct = productInitialDetails
  @observable coopWorkPermitDetails: IProduct = productInitialDetails
  @observable CSQApplicationFeeMainApplicant: IProduct = productInitialDetails
  @observable dependantExEntry: IProduct = productInitialDetails
  @observable spouseExEntry: IProduct = productInitialDetails
  @observable CSQGovernmentApplicationFee: IProduct = productInitialDetails
  @observable QCsponsorshipfee: IProduct = productInitialDetails
  @observable QCAddMember: IProduct = productInitialDetails
  @observable permanentResidenceSponsorshipFeeParents: IProduct = productInitialDetails
  @observable QCsponsorship: IProduct = productInitialDetails
  @observable RSWPphaseone: IProduct = productInitialDetails
  @observable spouseRSWPphaseone: IProduct = productInitialDetails
  @observable CSQpr: IProduct = productInitialDetails
  @observable CSQSpouseParner: IProduct = productInitialDetails
  @observable CSQSpouseParnerFee: IProduct = productInitialDetails
  @observable CSQDependantChild: IProduct = productInitialDetails
  @observable dependantChildIndependentSponsor: IProduct = productInitialDetails
  @observable CSQGovernmentApplicationFeeDependentChild: IProduct = productInitialDetails
  @observable PermanentResidenceSponsorshipFederalFeeDepentantChildIncluded: IProduct = productInitialDetails
  @observable SponsorshipApplicationOut: IProduct = productInitialDetails
  @observable ExpressEntryExpressionOfInterestPhase1: IProduct = productInitialDetails
  @observable ExpressEntryPhaseTwo: IProduct = productInitialDetails
  @observable PReconomicPA: IProduct = productInitialDetails
  @observable PReconomicSpouse: IProduct = productInitialDetails
  @observable PReconomicDepChild: IProduct = productInitialDetails
  @observable PN: IProduct = productInitialDetails
  @observable PNProgram: IProduct = productInitialDetails
  @observable PNAB: IProduct = productInitialDetails
  @observable PNBC: IProduct = productInitialDetails
  @observable PNMN: IProduct = productInitialDetails
  @observable PNNB: IProduct = productInitialDetails
  @observable PNNL: IProduct = productInitialDetails
  @observable PNNS: IProduct = productInitialDetails
  @observable PNON: IProduct = productInitialDetails
  @observable PNPEI: IProduct = productInitialDetails
  @observable PNSA: IProduct = productInitialDetails
  @observable SpousePN: IProduct = productInitialDetails
  @observable DependentPN: IProduct = productInitialDetails
  @observable SpousePartnerExpressEntryExpressionOfInterestPhase1: IProduct = productInitialDetails
  @observable LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee: IProduct = productInitialDetails
  @observable WorkingHolidayFee: IProduct = productInitialDetails
  @observable InternationalExperienceCanadaWorkPermit: IProduct = productInitialDetails
  @observable workPermitExemptONDetailsInsideCanada: IProduct = productInitialDetails
  @observable employmentLetter: IProduct = productInitialDetails
  @observable understood = false
  @observable displayErrorGlobal = false
  @observable matchid = ""

  @observable answers = ""

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action getLastStep = () => {
    return this.lastStep
  }

  @action setLastStep = (step:number) => {
    this.lastStep =step
  }

  @action getUnderstoodState = () => {
    return this.understood
  }

  @action setDisplayErrorGlobal = (value: boolean) => {
    this.displayErrorGlobal = value
  }

  @action setMembersErrors = (membersInfo: any) => {
    this.membersErrors = membersInfo

  }

  @action setUnderstoodState = (value: boolean) => {
    this.understood = value
  }
  @action setAnswers = (answersJsonAarray: string) => {
    this.answers = answersJsonAarray

  }

  @action getAnswers = () => {
    return this.answers
  }

  @action setVisitorVisa = (totalFees: IProduct) => {
    this.visitorVisaDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getVisitorVisaDetails = () => {
    return this.visitorVisaDetails
  }

  @action setVisitorVisaLessThanSixMonths = (totalFees: IProduct) => {
    this.visitorVisaDetailsLessThanSixMonths = totalFees
    this.calculateTotalPrice()
  }

  @action getVisitorVisaDetailsLessThanSixMonths = () => {
    return this.visitorVisaDetailsLessThanSixMonths
  }

  @action setExpiredPermit = (totalFees: IProduct) => {
    this.ExpiredPermitDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getExpiredPermit = () => {
    return this.ExpiredPermitDetails
  }

  @action setAtip = (totalFees: IProduct) => {
    this.Atip = totalFees
    this.calculateTotalPrice()
  }

  @action getAtip= () => {
    return this.Atip
  }

  @action setAtipFee = (totalFees: IProduct) => {
    this.AtipFee = totalFees
    this.calculateTotalPrice()
  }

  @action getAtipFee= () => {
    return this.AtipFee
  }

  @action setEmploymentletter = (totalFees: IProduct) => {
    this.employmentLetter = totalFees
    this.calculateTotalPrice()
  }

  @action getEmploymentletter = () => {
    return this.employmentLetter
  }


  @action setExpiredPermitDate = (totalFees: IProduct) => {
    this.ExpiredPermitDetailsDate = totalFees
    this.calculateTotalPrice()
  }

  @action getExpiredPermitDate = () => {
    return this.ExpiredPermitDetailsDate
  }


  @action setExpiredPermitMoreThan90 = (totalFees: IProduct) => {
    this.ExpiredPermitDetailsMoreThan90 = totalFees
    this.calculateTotalPrice()
  }

  @action getExpiredPermitMoreThan90 = () => {
    return this.ExpiredPermitDetailsMoreThan90
  }

  @action setExpiredRestoration = (totalFees: IProduct) => {
    this.ExpiredRestoration = totalFees
    this.calculateTotalPrice()
  }

  @action getExpiredRestoration = () => {
    return this.ExpiredRestoration
  }


  @action setRSWPphaseone = (totalFees: IProduct) => {
    this.RSWPphaseone = totalFees
    this.calculateTotalPrice()
  }

  @action getRSWPphaseone= () => {
    return this.RSWPphaseone
  }

  @action setspouseRSWPphaseone = (totalFees: IProduct) => {
    this.spouseRSWPphaseone = totalFees
    this.calculateTotalPrice()
  }

  @action getspouseRSWPphaseone= () => {
    return this.spouseRSWPphaseone
  }

  @action setCSQpr = (totalFees: IProduct) => {
    this.CSQpr = totalFees
    this.calculateTotalPrice()
  }

  @action getCSQpr= () => {
    return this.CSQpr
  }


  @action setCSQSpouseParner = (totalFees: IProduct) => {
    this.CSQSpouseParner = totalFees
    this.calculateTotalPrice()
  }

  @action getCSQSpouseParner= () => {
    return this.CSQSpouseParner
  }


  @action setCSQSpouseParnerFee = (totalFees: IProduct) => {
    this.CSQSpouseParnerFee = totalFees
    this.calculateTotalPrice()
  }

  @action getCSQSpouseParnerFee= () => {
    return this.CSQSpouseParnerFee
  }

  @action setCSQDependantChild = (totalFees: IProduct) => {
    this.CSQDependantChild = totalFees
    this.calculateTotalPrice()
  }

  @action getCSQDependantChild= () => {
    return this.CSQDependantChild
  }


  @action setotherPRFederal = (totalFees: IProduct) => {
    this.otherPRFederal = totalFees
    this.calculateTotalPrice()
  }

  @action getotherPRFederal= () => {
    return this.otherPRFederal
  }

  @action setspouseotherPRFederal = (totalFees: IProduct) => {
    this.spouseotherPRFederal = totalFees
    this.calculateTotalPrice()
  }

  @action getspouseotherPRFederal= () => {
    return this.spouseotherPRFederal
  }

  @action setdepedantotherPRFederal = (totalFees: IProduct) => {
    this.depedantotherPRFederal = totalFees
    this.calculateTotalPrice()
  }

  @action getdepedantotherPRFederal= () => {
    return this.depedantotherPRFederal
  }

  @action setoffertemplate = (totalFees: IProduct) => {
    this.offertemplate = totalFees
    this.calculateTotalPrice()
  }

  @action getoffertamplate= () => {
    return this.offertemplate
  }

  @action setEta = (totalFees: IProduct) => {
    this.etaDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getEtaDetails = () => {
    return this.etaDetails
  }

  @action setEtaFederalFee = (totalFees: IProduct) => {
    this.etaFederalFeesDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getEtaFederalFee = () => {
    return this.etaFederalFeesDetails
  }

  @action setQCsponsorship = (totalFees: IProduct) => {
    this.QCsponsorship = totalFees
    this.calculateTotalPrice()
  }

  @action getQCsponsorship = () => {
    return this.QCsponsorship
  }

  @action setdependantChildIndependentSponsor
    = (totalFees: IProduct) => {
      this.dependantChildIndependentSponsor
        = totalFees
      this.calculateTotalPrice()
    }

  @action getdependantChildIndependentSponsor = () => {
    return this.dependantChildIndependentSponsor

  }
  @action setQCAddMember = (totalFees: IProduct) => {
    this.QCAddMember = totalFees
    this.calculateTotalPrice()
  }

  @action getQCAddMember = () => {
    return this.QCAddMember
  }

  @action setQCsponsorshipFee = (totalFees: IProduct) => {
    this.QCsponsorshipfee = totalFees
    this.calculateTotalPrice()
  }

  @action getQCsponsorshipFee = () => {
    return this.QCsponsorshipfee
  }


  @action setResidenceSponsorshipFeeParents = (totalFees: IProduct) => {
    this.permanentResidenceSponsorshipFeeParents = totalFees
    this.calculateTotalPrice()
  }

  @action getResidenceSponsorshipFeeParents = () => {
    return this.permanentResidenceSponsorshipFeeParents
  }


  @action setOpenWorkPermitHolderFederalFee = (totalFees: IProduct) => {
    this.OpenWorkPermitHolderFederalFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getOpenWorkPermitHolderFederalFee = () => {
    return this.OpenWorkPermitHolderFederalFeeDetails
  }

  @action setOpenWorkPermitVulnerable = (totalFees: IProduct) => {
    this.OpenWorkPermitVulnerableDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getOpenWorkPermitVulnerable = () => {
    return this.OpenWorkPermitVulnerableDetails
  }
  @action setLMIAExExtentionPOE = (totalFees: IProduct) => {
    this.LMIAExExtentionPOEFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getLMIAExExtentionPOE = () => {
    return this.LMIAExExtentionPOEFeeDetails
  }


  @action setLMIAPosExtentionPOE = (totalFees: IProduct) => {
    this.LMIAPosExtentionPOEFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getLMIAPosExtentionPOE = () => {
    return this.LMIAPosExtentionPOEFeeDetails
  }

  @action setLMIAExExtentionON = (totalFees: IProduct) => {
    this.LMIAExExtentionONFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getLMIAExExtentionON = () => {
    return this.LMIAExExtentionONFeeDetails
  }

  @action setLMIAPosExtentionON = (totalFees: IProduct) => {
    this.LMIAPosExtentionONFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getLMIAPosExtentionON = () => {
    return this.LMIAPosExtentionONFeeDetails
  }

  @action setWorkPermitIncludesExtentionFederalFee = (totalFees: IProduct) => {
    this.WorkPermitIncludesExtentionFederalFee = totalFees
    this.calculateTotalPrice()
  }

  @action getWorkPermitIncludesExtentionFederalFee = () => {
    return this.WorkPermitIncludesExtentionFederalFee
  }

  @action setInternationalExperienceCanadaWorkPermit = (totalFees: IProduct) => {
    this.InternationalExperienceCanadaWorkPermit = totalFees
    this.calculateTotalPrice()
  }

  @action getInternationalExperienceCanadaWorkPermit = () => {
    return this.InternationalExperienceCanadaWorkPermit
  }

  @action setVisitorFederal = (totalFees: IProduct) => {
    this.visitorVisaFederalDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getVisitorFederal = () => {
    return this.visitorVisaFederalDetails
  }

  @action setVisitorRecord = (totalFees: IProduct) => {
    this.visitorRecordDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getVisitorRecord = () => {
    return this.visitorRecordDetails
  }

  @action setVisitorRecordFederal = (totalFees: IProduct) => {
    this.visitorRecordFederalDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getVisitorRecordFederal = () => {
    return this.visitorRecordFederalDetails
  }

  @action setStudyPermitExtentionON = (totalFees: IProduct) => {
    this.studyPermitExtentionONDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getStudyPermitExtentionON = () => {
    return this.studyPermitExtentionONDetails
  }

  @action setStudyLanguagePermitExtentionON = (totalFees: IProduct) => {
    this.studyPermitLanguageExtentionONDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getStudyLanguagePermitExtentionON = () => {
    return this.studyPermitLanguageExtentionONDetails
  }

  @action setSpousePartnerOpenWorkPermitExtentionON = (totalFees: IProduct) => {
    this.spousePartnerOpenWorkPermitExtentionOnDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getSpousePartnerOpenWorkPermitExtentionON = () => {
    return this.spousePartnerOpenWorkPermitExtentionOnDetails
  }

  @action setTravelDocumentRenovationStickerVisaON = (totalFees: IProduct) => {
    this.travelDocumentRenovationStickerVisaDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getTravelDocumentRenovationStickerVisaON = () => {
    return this.travelDocumentRenovationStickerVisaDetails
  }

  @action setDependantStudyPermitExtentionOverFiveDetails = (totalFees: IProduct) => {
    this.dependantStudyPermitExtentionOverFiveDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getDependantStudyPermitExtentionOverFiveDetails = () => {
    return this.dependantStudyPermitExtentionOverFiveDetails
  }

  @action setdependantStudyPermitExtentionLessFiveDetails = (totalFees: IProduct) => {
    this.dependantStudyPermitExtentionLessFiveDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getdependantStudyPermitExtentionLessFiveDetails = () => {
    return this.dependantStudyPermitExtentionLessFiveDetails
  }

  @action setPartnerOpenWorkSpouse = (totalFees: IProduct) => {
    this.PartnerOpenWorkSpouseDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getPartnerOpenWorkSpouse = () => {
    return this.PartnerOpenWorkSpouseDetails
  }

  @action setTransitVisaDetails = (totalFees: IProduct) => {
    this.transitVisaDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getTransitVisaDetails = () => {
    return this.transitVisaDetails
  }

  @action setTransitVisaDetailsFee = (totalFees: IProduct) => {
    this.transitVisaDetailsFee = totalFees
    this.calculateTotalPrice()
  }

  @action getTransitVisaDetailsFee = () => {
    return this.transitVisaDetailsFee
  }

  @action setSuperVisaDetails = (totalFees: IProduct) => {
    this.superVisaDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getSuperVisaDetails = () => {
    return this.superVisaDetails
  }

  @action setSuperVisaFederalFee = (totalFees: IProduct) => {
    this.superVisaFederalFeeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getSuperVisaFederalFee = () => {
    return this.superVisaFederalFeeDetails
  }

  @action setDependantVisitorLessFive = (totalFees: IProduct) => {
    this.dependantVisitorLessFiveDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getDependantVisitorLessFive = () => {
    return this.dependantVisitorLessFiveDetails
  }

  @action setDependantVisitorPlusFive = (totalFees: IProduct) => {
    this.dependantVisitorPlussFiveDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getDependantVisitorPlusFive = () => {
    return this.dependantVisitorPlussFiveDetails
  }

  @action setSubmission = (totalFees: IProduct) => {
    this.submissionDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getSubmission = () => {
    return this.submissionDetails
  }

  @action setBiomectics = (totalFees: IProduct) => {
    this.biometricsDetails = totalFees
    this.calculateTotalPrice()
  }

  @action setBiometricsIndependantChildren = (totalFees: IProduct) => {
    this.independantChildrenBiometrics = totalFees
    this.calculateTotalPrice()
  }

  @action getBiometricsIndependantChildren = () => {
    return this.independantChildrenBiometrics
  }

  @action getBiomectics = () => {
    return this.biometricsDetails
  }

  @action setFamliyBiometrics = (totalFees: IProduct) => {
    this.familyBiometricsDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getFamliyBiometrics = () => {
    return this.familyBiometricsDetails
  }

  @action setBiometrics = (totalFees: IProduct) => {
    this.biometricsDetails = totalFees
    this.calculateTotalPrice()
  }


  @action setCalculateCAQStudent = (totalFees: IProduct) => {
    this.CAQStudentDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getCalculateCAQStudent = () => {
    return this.CAQStudentDetails
  }

  @action setCalculateCAQExtentionStudent = (totalFees: IProduct) => {
    this.CAQExtentionStudentDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getCalculateCAQExtentionStudent = () => {
    return this.CAQExtentionStudentDetails
  }

  @action setCAQStudentGovernmentFee = (totalFees: IProduct) => {
    this.CAQStudentGovernmentFee = totalFees
    this.calculateTotalPrice()
  }
  @action getCAQStudentGovernmentFee = () => {
    return this.CAQStudentGovernmentFee
  }

  @action setCalculateCAQWorker = (totalFees: IProduct) => {
    this.CAQWorkerDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculateCAQWorker = () => {
    return this.CAQWorkerDetails
  }

  @action setCalculateCAQWorkerGovermentFees = (totalFees: IProduct) => {
    this.CAQWorkerGovernmentFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculateCAQWorkerGovermentFees = () => {
    return this.CAQWorkerGovernmentFeeDetails
  }


  @action setCitizenship = (totalFees: IProduct) => {
    this.citizenshipFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCitizenship = () => {
    return this.citizenshipFeeDetails
  }
  @action setDocumentAmendment = (totalFees: IProduct) => {
    this.DocumentAmendmentFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getDocumentAmendment = () => {
    return this.DocumentAmendmentFeeDetails
  }

  @action setPremandamus = (totalFees: IProduct) => {
    this.PremandamusFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getPremandamus = () => {
    return this.PremandamusFeeDetails
  }

  @action setDocumentReplacement = (totalFees: IProduct) => {
    this.DocumentReplacementFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getDocumentReplacement = () => {
    return this.DocumentReplacementFeeDetails
  }

  @action setDocumentReplacementGF = (totalFees: IProduct) => {
    this.DocumentReplacementGFFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getDocumentReplacementGF = () => {
    return this.DocumentReplacementGFFeeDetails
  }

  @action setCitizenshipMinor = (totalFees: IProduct) => {
    this.citizenshipMinorGovernmentFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCitizenshipMinor = () => {
    return this.citizenshipMinorGovernmentFeeDetails
  }


  @action setCitizenshipAdult = (totalFees: IProduct) => {
    this.citizenshipAdultGovernmentFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCitizenshipAdult= () => {
    return this.citizenshipAdultGovernmentFeeDetails
  }
  @action setCalculateCAQMinor = (totalFees: IProduct) => {
    this.CAQMinorDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculateCAQMinor = () => {
    return this.CAQMinorDetails
  }

  @action setCalculateCAQMinorGovermentFees = (totalFees: IProduct) => {
    this.CAQMinorGovernmentFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculateCAQMinorGovermentFees = () => {
    return this.CAQMinorGovernmentFeeDetails
  }


  @action setCalculatestudyPermitLangDetails = (totalFees: IProduct) => {
    this.studyPermitLangDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculatestudyPermitLangDetails = () => {
    return this.studyPermitLangDetails
  }
  @action setCalculatestudyPermitAcademicStreamDetails = (totalFees: IProduct) => {
    this.studyPermitAcademicStreamDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculatestudyPermitAcademicStreamDetails = () => {
    return this.studyPermitAcademicStreamDetails
  }
  @action setCalculatestudyPermitAcademicDetails = (totalFees: IProduct) => {
    this.studyPermitAcademicDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getsetCalculatestudyPermitAcademicDetails = () => {
    return this.studyPermitAcademicDetails
  }
  @action setCalculatestudyPermitFederalFeeDetails = (totalFees: IProduct) => {
    this.studyPermitFederalFeeDetails = totalFees
    this.calculateTotalPrice()
  }
  @action getCalculatestudyPermitFederalFeeDetails = () => {
    return this.studyPermitFederalFeeDetails
  }

  @action getBiometrics = () => {
    return this.biometricsDetails
  }

  @action setCanadaTaxFeesDetails = (totalFees: IProduct) => {
    this.canadaTaxFeesDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getCanadaTaxFeesDetails = () => {
    return this.canadaTaxFeesDetails
  }

  @action setDiscountHundrerDetails = (totalFees: IProduct) => {
    this.discountHundredDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getDiscountHundrerDetails = () => {
    return this.discountHundredDetails
  }

  @action setDiscountCodeDetails = (totalFees: IProduct) => {
    this.discountCodeDetails = totalFees
    this.calculateTotalPrice()
  }

  @action getDiscountCodeDetails = () => {
    return this.discountCodeDetails
  }

  @action setDiscountPreviousClient = (totalFees: IProduct) => {
    this.discountPreviousClient = totalFees
    this.calculateTotalPrice()
  }

  @action getDiscountPreviousClient = () => {
    return this.discountPreviousClient
  }

  @action setDiscount = (product: IProduct) => {
    this.discountDetails = product
    this.calculateTotalPrice()
  }

  @action getDiscount = () => {
    return this.discountDetails
  }

  @action setWorkPermitExemptONDetails = (product: IProduct) => {
    this.workPermitExemptONDetails = product
    this.calculateTotalPrice()
  }

  @action getWorkPermitExemptONDetails = () => {
    return this.workPermitExemptONDetails
  }

  @action setWorkPermitExemptONDetailsInsideCanada = (product: IProduct) => {
    this.workPermitExemptONDetailsInsideCanada = product
    this.calculateTotalPrice()
  }

  @action getWorkPermitExemptONDetailsInsideCanada = () => {
    return this.workPermitExemptONDetailsInsideCanada
  }

  @action setWorkPermitExemptPOE = (product: IProduct) => {
    this.workPermitExemptPOEDetails = product
    this.calculateTotalPrice()
  }

  @action getWorkPermitExemptPOE = () => {
    return this.workPermitExemptPOEDetails
  }

  @action setWorkPermitPositivePOE = (product: IProduct) => {
    this.workPermitPositivePOEDetails = product
    this.calculateTotalPrice()
  }

  @action getWorkPermitPositivePOE = () => {
    return this.workPermitPositivePOEDetails
  }

  @action setWorkPermitPositiveON = (product: IProduct) => {
    this.workPermitPositiveONDetails = product
    this.calculateTotalPrice()
  }

  @action getWorkPermitPositiveON = () => {
    return this.workPermitPositiveONDetails
  }

  @action setPartnerOpenWorkPermitPOE = (product: IProduct) => {
    this.partnerOpenWorkPermitPOEDetails = product
    this.calculateTotalPrice()
  }

  @action getPartnerOpenWorkPermitPOE = () => {
    return this.partnerOpenWorkPermitPOEDetails
  }

  @action setPartnerOpenWorkPermitON = (product: IProduct) => {
    this.partnerOpenWorkPermitONDetails = product
    this.calculateTotalPrice()
  }

  @action getPartnerOpenWorkPermitON = () => {
    return this.partnerOpenWorkPermitONDetails
  }

  @action setPartnerWorkingHolidayOn = (product: IProduct) => {
    this.partnerWorkingHolidayONDetails = product
    this.calculateTotalPrice()
  }

  @action getPartnerWorkingHolidayON = () => {
    return this.partnerWorkingHolidayONDetails
  }

  @action setWorkingHolidayFee = (product: IProduct) => {
    this.WorkingHolidayFee = product
    this.calculateTotalPrice()
  }

  @action getWorkingHolidayFee = () => {
    return this.WorkingHolidayFee
  }


  @action setPGWPPOE = (product: IProduct) => {
    this.PGWPPOE = product
    this.calculateTotalPrice()
  }

  @action getPGWPPOE = () => {
    return this.PGWPPOE
  }

  @action setPGWPEx = (product: IProduct) => {
    this.PGWPEx = product
    this.calculateTotalPrice()
  }

  @action getPGWPEx = () => {
    return this.PGWPEx
  }

  @action setPGWPEON = (product: IProduct) => {
    this.PGWPON = product
    this.calculateTotalPrice()
  }

  @action getPGWPEON = () => {
    return this.PGWPON
  }

  @action setSponsorshipApplicationIn = (product: IProduct) => {
    this.SponsorshipApplicationIn = product
    this.calculateTotalPrice()
  }

  @action getSponsorshipApplicationIn = () => {
    return this.SponsorshipApplicationIn
  }

  @action setPermanentResidenceSponsorshipFederalFee = (product: IProduct) => {
    this.PermanentResidenceSponsorshipFederalFee = product
    this.calculateTotalPrice()
  }

  @action getPermanentResidenceSponsorshipFederalFee = () => {
    return this.PermanentResidenceSponsorshipFederalFee
  }

  @action setOpenWorkPermitMainApplicant = (product: IProduct) => {
    this.OpenWorkPermitMainApplicant = product
    this.calculateTotalPrice()
  }

  @action getOpenWorkPermitMainApplicant = () => {
    return this.OpenWorkPermitMainApplicant
  }

  @action setCSQ = (product: IProduct) => {
    this.CSQDetails = product
    this.calculateTotalPrice()
  }

  @action setCSQApplicationFeeMainApplicant = (product: IProduct) => {
    this.CSQApplicationFeeMainApplicant = product
    this.calculateTotalPrice()
  }

  @action getCSQApplicationFeeMainApplicant = () => {
    return this.CSQApplicationFeeMainApplicant
  }

  @action setdependantExEntry = (product: IProduct) => {
    this.dependantExEntry = product
    this.calculateTotalPrice()
  }

  @action getdependantExEntry = () => {
    return this.dependantExEntry
  }

  @action setSpouseExEntry = (product: IProduct) => {
    this.spouseExEntry = product
    this.calculateTotalPrice()
  }

  @action getSpouseExEntry = () => {
    return this.spouseExEntry
  }




  @action getCSQ = () => {
    return this.CSQDetails
  }

  @action setCoop = (product: IProduct) => {
    this.coopWorkPermitDetails = product
    this.calculateTotalPrice()
  }

  @action getCoop = () => {
    return this.coopWorkPermitDetails
  }

  @action setCSQGovernmentApplicationFee = (product: IProduct) => {
    this.CSQGovernmentApplicationFee = product
    this.calculateTotalPrice()
  }

  @action getCSQGovernmentApplicationFee = () => {
    return this.CSQGovernmentApplicationFee
  }

  @action setCSQCSQGovernmentApplicationFeeDependentChild = (product: IProduct) => {
    this.CSQGovernmentApplicationFeeDependentChild = product
    this.calculateTotalPrice()
  }

  @action getCSQGovernmentApplicationFeeDependentChild = () => {
    return this.CSQGovernmentApplicationFeeDependentChild
  }

  @action setPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded = (product: IProduct) => {
    this.PermanentResidenceSponsorshipFederalFeeDepentantChildIncluded = product
    this.calculateTotalPrice()
  }
  @action getPermanentResidenceSponsorshipFederalFeeDepentantChildIncluded = () => {
    return this.PermanentResidenceSponsorshipFederalFeeDepentantChildIncluded
  }

  @action setSponsorshipApplicationOut = (product: IProduct) => {
    this.SponsorshipApplicationOut = product
    this.calculateTotalPrice()
  }
  @action getSponsorshipApplicationOut = () => {
    return this.SponsorshipApplicationOut
  }

  @action setExpressEntryExpressionOfInterestPhase1 = (product: IProduct) => {
    this.ExpressEntryExpressionOfInterestPhase1 = product
    this.calculateTotalPrice()
  }
  @action getExpressEntryExpressionOfInterestPhase1 = () => {
    return this.ExpressEntryExpressionOfInterestPhase1
  }

  @action setExpressEntryPhaseTwo = (product: IProduct) => {
    this.ExpressEntryPhaseTwo = product
    this.calculateTotalPrice()
  }
  @action getExpressEntryPhaseTwo = () => {
    return this.ExpressEntryPhaseTwo
  }


  @action setPReconomicPA = (product: IProduct) => {
    this.PReconomicPA = product
    this.calculateTotalPrice()
  }
  @action getPReconomicPA = () => {
    return this.PReconomicPA
  }

  @action setPReconomicSpouse = (product: IProduct) => {
    this.PReconomicSpouse = product
    this.calculateTotalPrice()
  }
  @action getPReconomicSpouse = () => {
    return this.PReconomicSpouse
  }

  @action setPReconomicDepChild = (product: IProduct) => {
    this.PReconomicDepChild = product
    this.calculateTotalPrice()
  }
  @action getPReconomicDepChild = () => {
    return this.PReconomicDepChild
  }

  @action setPN = (product: IProduct) => {
    this.PN = product
    this.calculateTotalPrice()
  }
  @action getPN = () => {
    return this.PN
  }

  @action setPNProgram = (product: IProduct) => {
    this.PNProgram = product
    this.calculateTotalPrice()
  }
  @action getPNPProgram = () => {
    return this.PNProgram
  }
  @action setPNAB = (product: IProduct) => {
    this.PNAB = product
    this.calculateTotalPrice()
  }
  @action getPNAB = () => {
    return this.PNAB
  }

  @action setPNBC = (product: IProduct) => {
    this.PNBC = product
    this.calculateTotalPrice()
  }
  @action getPNBC = () => {
    return this.PNBC
  }

  @action setPNMN = (product: IProduct) => {
    this.PNMN = product
    this.calculateTotalPrice()
  }
  @action getPNMN  = () => {
    return this.PNMN
  }

  @action setPNNB= (product: IProduct) => {
    this.PNNB = product
    this.calculateTotalPrice()
  }
  @action getPNNB  = () => {
    return this.PNNB
  }

  @action setPNNL= (product: IProduct) => {
    this.PNNL = product
    this.calculateTotalPrice()
  }
  @action getPNNL  = () => {
    return this.PNNL
  }

  @action setPNNS= (product: IProduct) => {
    this.PNNS = product
    this.calculateTotalPrice()
  }
  @action getPNNS  = () => {
    return this.PNNS
  }

  
  @action setPNON= (product: IProduct) => {
    this.PNON = product
    this.calculateTotalPrice()
  }
  @action getPNON  = () => {
    return this.PNON
  }

  @action setPNPIE= (product: IProduct) => {
    this.PNPEI = product
    this.calculateTotalPrice()
  }
  @action getPNPEI  = () => {
    return this.PNPEI
  }

  @action setPNSA= (product: IProduct) => {
    this.PNSA = product
    this.calculateTotalPrice()
  }
  @action getPNSA = () => {
    return this.PNSA
  }

  @action setSpousePN = (product: IProduct) => {
    this.SpousePN = product
    this.calculateTotalPrice()
  }
  @action getSpousePN = () => {
    return this.SpousePN
  }


  @action setDependentPN = (product: IProduct) => {
    this.DependentPN = product
    this.calculateTotalPrice()
  }
  @action getDependentPN = () => {
    return this.DependentPN
  }



  @action setSpousePartnerExpressEntryExpressionOfInterestPhase1 = (product: IProduct) => {
    this.SpousePartnerExpressEntryExpressionOfInterestPhase1 = product
    this.calculateTotalPrice()
  }
  @action getSpousePartnerExpressEntryExpressionOfInterestPhase1 = () => {
    return this.SpousePartnerExpressEntryExpressionOfInterestPhase1
  }

  @action setLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee = (product: IProduct) => {
    this.LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee = product
    this.calculateTotalPrice()
  }
  @action getLabourMarketImpactAssessmentExemptEmployerComplianceFederalFee = () => {
    return this.LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee
  }


  //END new implementation

  @action principalUsed = () => {
    //Search in familyMembers if principal is used
    return this.familyMembers.find((family) => family.principal === true)
  }

  @action conyugueUsed = () => {
    //Search in familyMembers if principal is used
    return this.familyMembers.find((family) => family.type === FamilyTypes.conyuge)
  }

  @action numberOfChildrenUsed = () => {
    //Search in familyMembers if principal is used
    /*   return this.familyMembers.find((family) => family.type === FamilyTypes.children) */
    const children = this.familyMembers.filter(family => family.type === FamilyTypes.children);
    return children.length;
  }

  @action numberOfGroupFamilies = () => {
    //Search in familyMembers if principal is used
    return this.familygroup.length
  }
  @action applicantsNames = () => {
    //Search in familyMembers if principal is used
    const output = []
    for (let i = 0; i < this.familyMembers.length; ++i) {
      if (this.familyMembers[i].etaImmiland === 'no') {
      } else {
        output.push(this.familyMembers[i].name + ' ' + this.familyMembers[i].lastname)
      }
    }
    const outputToString = output.toString()
    return outputToString
  }
  @action docusignData = () => {
    //Search in familyMembers if principal is used

    const today = new Date()
    const date: string = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const data = {
      biometrics: this.getBiometrics().totalFee,
      eta: this.totalEta,
      govermentFee:
        this.getTotalGovFeesClean() ,
        //lmia exception if applied
        lmiaException: this.totalLMIAExemption.price + this.totalGovermentExtention.price,
      profesionalFee:
        this.getTotalProfessionalFeesClean() - this.getDiscount().totalFee,
      total: this.totalPrice,
      resume: this.getResumeService(),
      applicantsInfo: this.getProductAplicantsService(),
      currentDate: date,
      mifi: this.getTotalMifiFeesClean() > 0 ? this.getTotalMifiFeesClean() : 0,
      matchId: this.getMatchId()
    }
    return data
  }

  @action setFamilyMember = (family: IFamilys[]) => {
    this.familyMembers = family
  }

  @action getMatchId = () => {
    let id
    if (this.matchid === "") {
      id = uuidv4()
      this.matchid = id.toString()
    }
    return this.matchid
  }
  @action setCurrentMember = (family: IFamilys) => {
    this.currentMember = family
  }

  @action handleChangeFamilyMember = (familyChange: IFamilys) => {
    const newFamilys = [...this.familyMembers]
    const index = newFamilys.findIndex((family: IFamilys) => family.id === familyChange.id)
    newFamilys[index] = familyChange
    this.familyMembers = newFamilys
    this.currentMember = familyChange

    //find index of familygroup dependein of family.groupId
    const indexFamilyGroup = this.familygroup.findIndex(
      (familygroup: IFamilyGroup) => familygroup.id === familyChange.groupId
    )

    //set familygroup.family dependein of family.groupId
    this.familygroup[indexFamilyGroup].family = this.familyMembers.filter(
      (family: IFamilys) => family.groupId === familyChange.groupId
    )
  }

  @action setFamilyGroup = (familygroup: IFamilyGroup[]) => {
    this.familygroup = familygroup
  }

  //set price of totalBiometricos
  @action setVisaTypeFromSurvey = (visaType: string) => {
    this.visaTypeSurvey = visaType
  }
  //set price of totalBiometricos
  @action getVisaTypeFromSurvey = () => {
    return this.visaTypeSurvey
  }

  //set price of totalBiometricos
  @action setTotalBiometricos = (totalBiometricos: IDetails) => {
    if (totalBiometricos.price = 75) {
      this.totalBiometricos = totalBiometricos
    } else if (totalBiometricos.price > 75) {
      this.totalBiometricsFamily = totalBiometricos
    }

    this.calculateTotalPrice()
  }


  //set price of totalBiometricos
  @action setTotalEta = (totalEta: IDetails) => {
    this.totalEta = totalEta
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalFees = (totalFees: IDetails) => {
    this.totalFees = totalFees
    this.calculateTotalPrice()
  }



  //set price of totalBiometricos
  @action setTotalProfesional = (totalProfesional: IDetails) => {
    this.totalProfesional = totalProfesional
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalCaq = (totalCaq: IDetails) => {
    this.totalCaq = totalCaq
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalMifi = (totalMifi: IDetails) => {
    this.totalMifi = totalMifi
    this.calculateTotalPrice()
  }
  //set price of totalBiometricos
  @action setTotalProfesionalStudy = (totalProfesionalStudy: IDetails) => {
    this.totalProfesionalStudy = totalProfesionalStudy
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalExtentionProfesional = (totalExtentionProfesional: IDetails) => {
    this.totalProfesionalExtention = totalExtentionProfesional
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action setTotalExtentionGoverment = (totalExtentionGoverment: IDetails) => {
    this.totalGovermentExtention = totalExtentionGoverment
    this.calculateTotalPrice()
  }

  @action setTotalFeesStudy = (totalFeeStudy: IDetails) => {
    this.totalFeeStudy = totalFeeStudy
    this.calculateTotalPrice()
  }

  //work
  @action setTotalProfesionalFeesWork = (totalProfesionalWork: IDetails) => {
    this.totalProfesionalFeeWork = totalProfesionalWork
    this.calculateTotalPrice()
  }
  //work
  @action setTotalGovermentFeesWork = (totalGovermentWork: IDetails) => {
    this.totalGovermentFeeWork = totalGovermentWork
    this.calculateTotalPrice()
  }

  //work
  @action setTotalCaqQuebecWork = (totalCaq: IDetails) => {
    this.totalCaqWork = totalCaq
    this.calculateTotalPrice()
  }
  //work
  @action setCloseWorkPermitFee = (totalCloseWorkPermitFee: IDetails) => {
    this.totalCloseWorkPermitFee = totalCloseWorkPermitFee
    this.calculateTotalPrice()
  }

  //work
  @action setLMIAMIFIReviewFee = (totalLMIAMIFIReviewFee: IDetails) => {
    this.totalLMIAMIFIReviewFee = totalLMIAMIFIReviewFee
    this.calculateTotalPrice()
  }

  //work
  @action setLMIAExemption = (totalLMIAExemption: IDetails) => {
    this.totalLMIAExemption = totalLMIAExemption
    this.calculateTotalPrice()
  }
  //set price of totalTransaction
  @action setTotalTransactionFee = (setTotalTransactionFee: IDetails) => {
    this.totalTransactionFee = setTotalTransactionFee
    this.calculateTotalPrice()
  }

  //set price of totalTransaction
  @action setTotalCanadaTaxFee = (setTotalCanadaTaxFee: IDetails) => {
    this.totalCanadaTaxFee = setTotalCanadaTaxFee
    this.calculateTotalPrice()
  }

  //set price of totalBiometricos
  @action getTotalTransactionFee = () => {
    return (this.getTotalCanadaTaxFee() + this.getTotalMifiFeesClean() + + this.getTotalGovFeesClean() + this.independantChildrenBiometrics.totalFee + this.biometricsDetails.totalFee + (this.getTotalProfessionalFeesClean() - this.getDiscount().totalFee)) * 0.04
  }


  //set price of totalBiometricos





  //set price of totalBiometricos
  @action getTotalGovFeesClean = () => {
    return this.etaFederalFeesDetails.totalFee +
      this.visitorVisaFederalDetails.totalFee +
      this.superVisaFederalFeeDetails.totalFee +
      this.ExpiredPermitDetailsMoreThan90.totalFee +
      //stud
      this.studyPermitFederalFeeDetails.totalFee +
      /*  this.CAQStudentGovernmentFee.totalFee +  this is mifi now*/
      this.OpenWorkPermitHolderFederalFeeDetails.totalFee +
      this.WorkPermitIncludesExtentionFederalFee.totalFee +
      this.visitorRecordFederalDetails.totalFee +
      /*  this.CAQMinorGovernmentFeeDetails.totalFee +   this is mifi now*/
      /*  this.CAQWorkerGovernmentFeeDetails.totalFee + this is mifi now*/
      this.PermanentResidenceSponsorshipFederalFee.totalFee +
      /* this.CSQGovernmentApplicationFeeDependentChild.totalFee + this is mifi now */
      this.CSQGovernmentApplicationFee.totalFee +
      this.PermanentResidenceSponsorshipFederalFeeDepentantChildIncluded.totalFee +
      this.LabourMarketImpactAssessmentExemptEmployerComplianceFederalFee.totalFee +
      this.WorkingHolidayFee.totalFee +
      this.permanentResidenceSponsorshipFeeParents.totalFee +
      this.QCAddMember.totalFee +
      this.dependantChildIndependentSponsor.totalFee +
      this.PReconomicPA.totalFee +
      this.PReconomicSpouse.totalFee +
      this.PReconomicDepChild.totalFee+
      this.PNProgram.totalFee+
      this.AtipFee.totalFee +
      this.DocumentReplacementGFFeeDetails.totalFee+
      this.citizenshipMinorGovernmentFeeDetails.totalFee+
      this.citizenshipAdultGovernmentFeeDetails.totalFee+
      this.PNAB.totalFee+
      this.PNBC.totalFee+
      this.PNMN.totalFee+
      this.PNNB.totalFee+
      this.PNNL.totalFee+
      this.PNNS.totalFee+
      this.PNON.totalFee+
      this.PNPEI.totalFee+
      this.PNSA.totalFee
  }


  @action getTotalMifiFeesClean = () => {
    return this.CSQGovernmentApplicationFeeDependentChild.totalFee +
      this.CAQWorkerGovernmentFeeDetails.totalFee +
      this.CAQMinorGovernmentFeeDetails.totalFee +
      this.CAQStudentGovernmentFee.totalFee +
      this.CSQDetails.totalFee +
      this.CSQApplicationFeeMainApplicant.totalFee+
      this.QCsponsorshipfee.totalFee +
      this.QCAddMember.totalFee+
      this.CSQSpouseParnerFee.totalFee
  }

  
     

  @action getTotalProfessionalFeesClean = () => {
    return this.visitorVisaDetails.totalFee +
      this.etaDetails.totalFee +
      this.transitVisaDetails.totalFee +
      this.superVisaDetails.totalFee +
      this.dependantVisitorLessFiveDetails.totalFee +
      this.submissionDetails.totalFee +
      this.ExpiredPermitDetails.totalFee +
      this.Atip.totalFee +
      this.ExpiredRestoration.totalFee 
      //study
      + this.CAQStudentDetails.totalFee +
      this.CAQExtentionStudentDetails.totalFee +
      this.CAQMinorDetails.totalFee +
      this.studyPermitAcademicDetails.totalFee +
      this.studyPermitAcademicStreamDetails.totalFee +
      this.studyPermitLangDetails.totalFee +
      this.dependantVisitorPlussFiveDetails.totalFee +
      this.PartnerOpenWorkSpouseDetails.totalFee +
      this.visitorVisaDetailsLessThanSixMonths.totalFee
      //work
      + this.partnerOpenWorkPermitONDetails.totalFee +
      this.partnerOpenWorkPermitPOEDetails.totalFee +
      this.workPermitExemptONDetails.totalFee +
      this.workPermitExemptPOEDetails.totalFee +
      this.workPermitPositiveONDetails.totalFee +
      this.workPermitPositivePOEDetails.totalFee +
      this.partnerWorkingHolidayONDetails.totalFee +
      this.CAQWorkerDetails.totalFee +
      this.employmentLetter.totalFee+
      this.OpenWorkPermitVulnerableDetails.totalFee+
      //extension
      this.visitorRecordDetails.totalFee +
      this.studyPermitExtentionONDetails.totalFee +
      this.spousePartnerOpenWorkPermitExtentionOnDetails.totalFee +
      this.travelDocumentRenovationStickerVisaDetails.totalFee +
      this.dependantStudyPermitExtentionOverFiveDetails.totalFee +
      this.dependantStudyPermitExtentionLessFiveDetails.totalFee +
      this.PGWPEx.totalFee +
      this.PGWPON.totalFee +
      this.PGWPPOE.totalFee +
      this.LMIAExExtentionPOEFeeDetails.totalFee +
      this.LMIAPosExtentionPOEFeeDetails.totalFee +
      this.LMIAExExtentionONFeeDetails.totalFee +
      this.LMIAPosExtentionONFeeDetails.totalFee +
      this.workPermitExemptONDetailsInsideCanada.totalFee +
      this.studyPermitLanguageExtentionONDetails.totalFee +
      //coop
      this.coopWorkPermitDetails.totalFee +
      //permanent residence
      this.SponsorshipApplicationIn.totalFee +
      this.OpenWorkPermitMainApplicant.totalFee +
      this.QCsponsorship.totalFee +
      /* this.CSQDetails.totalFee +  this is mifi now */
      this.SponsorshipApplicationOut.totalFee +
      this.ExpressEntryExpressionOfInterestPhase1.totalFee +
      this.SpousePartnerExpressEntryExpressionOfInterestPhase1.totalFee +
      this.InternationalExperienceCanadaWorkPermit.totalFee +
      this.dependantExEntry.totalFee +
      this.spouseExEntry.totalFee +
      this.ExpressEntryPhaseTwo.totalFee+
      this.RSWPphaseone.totalFee+
      this.spouseRSWPphaseone.totalFee+
      this.CSQpr.totalFee+
      this.CSQSpouseParner.totalFee+
      this.CSQDependantChild.totalFee+
      this.otherPRFederal.totalFee+
      this.spouseotherPRFederal.totalFee+
      this.depedantotherPRFederal.totalFee+
      this.PN.totalFee+
      this.SpousePN.totalFee+
      this.DependentPN.totalFee+
      this.citizenshipFeeDetails.totalFee+
      this.DocumentReplacementFeeDetails.totalFee+
      this.DocumentAmendmentFeeDetails.totalFee+
      this.offertemplate.totalFee +
      this.PremandamusFeeDetails.totalFee
  }

  @action setResumeService = (resumeService: string) => {
    this.resumeService = resumeService + " ||| " + this.resumeService
  }

  @action setResumeServiceToEmptyString = () => {

    this.resumeService = ""
  }
  @action setProductAplicantService = (newObject: any[] | undefined) => {

    if (newObject) {
      newObject.forEach((newApplicant) => {
        const exists = this.productAplicantsService.some(
          (existingApplicant) =>
            existingApplicant.memberName === newApplicant.memberName &&
            existingApplicant.memberType === newApplicant.memberType &&
            existingApplicant.productName === newApplicant.productName &&
            existingApplicant.productId === newApplicant.productId
        );

        if (!exists) {
          this.productAplicantsService.push(newApplicant);
        }
      });
    }
  };

  @action setProductAplicantServiceToEmptyArray = () => {
    this.productAplicantsService.length = 0
  };



  //set price of totalBiometricos
  @action getTotalCanadaTaxFee = () => {
    if (this.principalUsed()?.inCanada === 'si') {
      return (this.getTotalProfessionalFeesClean() - this.getDiscount().totalFee) * 0.13
    } else {
      return 0
    }
  }


  //set price of totalTransaction
  @action setRejectionDiscount = (totalPreviousRejectionDiscount: IDetails) => {
    this.totalPreviousRejectionDiscount = totalPreviousRejectionDiscount.price
    this.calculateTotalPrice()
  }

  //set price of totalTransaction
  @action setDiscountCode = (discountCode: string) => {
    if (discountCode == "PLANETAJUANCANADAIMMILAND2023") {
      this.totalDiscountCode = (this.getTotalProfessionalFeesClean()) * 0.05
    }
    else {
      this.totalDiscountCode = 0
    }
    this.calculateTotalPrice()
  }

  @action setAnswersJson = (answersJson: string) => {
    this.answersJson = answersJson
  }

  @action getAnswersJson = () => {
    return this.answersJson
  }

  @action setAnswersJsonArray = (answersJsonArray: string[]) => {
    this.answersJsonArray = answersJsonArray
  }

  calculateTotalPrice = () => {
    this.totalPrice = this.getTotalGovFeesClean() + this.getTotalMifiFeesClean() + +this.independantChildrenBiometrics.totalFee + this.biometricsDetails.totalFee + this.getTotalProfessionalFeesClean() + this.getTotalTransactionFee() +
      this.getTotalCanadaTaxFee() - this.getDiscount().totalFee
  }

  @action getResumeService = () => {
    return this.resumeService
  }

  @action getProductAplicantsService = () => {
    return this.productAplicantsService
  }

  @action setCountGovermentFeeETA = (numberOfETA: number) => {
    this.countGovermentFeeETA = numberOfETA
  }

  @action setCountProfessionalFeeETA = (numberOfETA: number) => {
    this.countProfessionalFeeETA = numberOfETA
  }
}
