/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Input, Select } from 'Components'
import { FamilyTypes, IFamilys } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { CivilStatusSelector } from 'Components/CivilStatusSelector'
import DateOfBirth from './CustomComponents/DateOfBirth'
import MyComponent from '../student-visa/DocusignCheckEnvelopeId'
import { nanoid } from '@reduxjs/toolkit'
import { observer } from 'mobx-react'
import { WorkingHoliday } from '../../Data/WorkingHolidayCountries'
import { YoungProfessionals } from '../../Data/YoungProfessionalsCountries'
import { InternationalCoOp } from '../../Data/InternationalCoOpCountries'
import { jsonArray, keys } from '../../ContratationAnswers'
import { Checkbox, Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const PersonalDataWork: React.FC<IProps> = observer(({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const { increaseEstep, step: stepLine } =
		rootStore.formBuilderStore
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		setFamilyMember,
		getVisaTypeFromSurvey,
		familyMembers,
		setMembersErrors,
		membersErrors,
		setCurrentMember,
		displayErrorGlobal,
		setDisplayErrorGlobal,
		familygroup
	} = rootStore.familyStore
	const handleChangeMember = (id: any) => {
		const newCurrentMember = familyMembers.find((member) => member.id === id);
		if (newCurrentMember) {
			// Set the newCurrentMember as the currentMember
			setCurrentMember(newCurrentMember);
		}
	}

	const [verificationDocusign, setverificationDocusign] = React.useState(false)
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [displayErrors, setDisplayErrors] = React.useState(displayErrorGlobal)
	const [errorDate, setErrorDate] = React.useState(true)
	const [ageForBiometrics, setAgeForBiometrics] = React.useState(false)
	const [notValid, setNotValid] = React.useState(false)
	const fileUploadRef = useRef(null)
	const indexFirstConyugue = familyMembers.findIndex((member) => member.type === FamilyTypes.conyuge);
	const [showWarning, setShowWarning] = useState(false);
	const [checkboxChecked, setCheckboxChecked] = useState(false);

	const totalProfFees = rootStore.familyStore.getTotalProfessionalFeesClean()


	const handleDiscountCodeChange = (e: any) => {
		const discountCode = e.target.value;
		handleChange('discountCode', discountCode);  
	
		
		if (discountCode === 'EDUCATIONTODAY' || discountCode === 'CANADATODAY'  || discountCode === 'canadatoday'   || discountCode === 'educationtoday') {
			/* popupDiscountWarning(); */
		}
	};
	
	const popupDiscountWarning = () => {
		let timerInterval: any;
		let progress = 0;
		const totalTime = 5000; // Tiempo total para completar la barra de progreso (5 segundos)
		const intervalTime = 50; // Intervalo para actualizar la barra de progreso
	
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html: `
				<p>Entiendo que este descuento no aplica para aplicaciones a permiso de estudios que deban ser enviadas antes del 1 de Noviembre 2024.</p>
				<p>En consecuencia, Immiland Law no se compromete bajo ninguna circunstancia a enviar mi aplicación a permiso de estudios antes del 1 de Noviembre 2024.</p>
				<br>
				<div id="progress-bar-container" style="position: relative; width: 100%; height: 10px; background-color: #e0e0e0; margin-bottom: 10px;">
					<div id="progress-bar" style="width: 0%; height: 100%; background-color: #13C296;"></div>
				</div>
				<button id="confirm-button" disabled class="swal2-confirm swal2-styled" style="background-color: #d3d3d3; color: white; font-size: 16px; padding: 10px 20px;">Entiendo</button>
			`,
			didOpen: () => {
				const confirmButton = Swal.getPopup()!.querySelector('#confirm-button') as HTMLButtonElement;
				const progressBar = Swal.getPopup()!.querySelector('#progress-bar') as HTMLElement;
	
				// Actualizar la barra de progreso
				timerInterval = setInterval(() => {
					progress += intervalTime;
					const percent = (progress / totalTime) * 100;
					progressBar.style.width = `${percent}%`;
	
					// Habilitar el botón cuando el progreso llegue al 100%
					if (progress >= totalTime) {
						clearInterval(timerInterval);
						confirmButton.disabled = false;
						confirmButton.style.backgroundColor = '#13C296'; // Cambia el color del botón cuando está habilitado
						confirmButton.classList.add('btn-no-shadow');
					}
				}, intervalTime);
	
				confirmButton.addEventListener('click', () => {
					Swal.close();
					// Aquí puedes añadir lógica adicional si necesitas hacer algo cuando clickeen el botón.
				});
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
			showCloseButton: false,
			showCancelButton: false,
			showConfirmButton: false, // Esto desactiva el botón "Entendido" predeterminado de SweetAlert2
			focusConfirm: false,
			customClass: {
				confirmButton: 'btn-no-shadow',
			},
			buttonsStyling: false,
		});
	};


	const popupSubmittionLetter = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;">Immiland recomienda altamente en estos casos incluir un submission letter para contrarestar las razones del rechazo previo. Por lo tanto, este item será incluido en su contrato.</span><br></b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}
	const allowedCountries = [
		"Antigua and Barbuda",
		"Argentina",
		"Brazil",
		"Costa Rica",
		"Mexico",
		"Morocco",
		"Panama",
		"Philippines",
		"Saint Kitts and Nevis",
		"Saint Lucia",
		"Saint Vincent and the Grenadines",
		"Seychelles",
		"Thailand",
		"Trinidad and Tobago",
		"Uruguay"
	];
	let indexChildren = 0

	useEffect(() => {
        if (currentMember.type === FamilyTypes.children && currentMember.age <= 5) {
            handleChange('civilStatus', 'Soltero');
        }
    }, [currentMember.type, currentMember.age]);

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => { }, [emailForUploaders])
	useEffect(() => { }, [notValid])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}
	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}
	const emailRegex = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')
	const regex = new RegExp('^[a-zA-Z0-9\\s]+$')
	const checkIfAllowSave = () => {

		if (currentMember.type === 'Solicitante principal' && currentMember.address === undefined) {
			setNotValid(true)
			console.log('no pasa')
			return false
		}
		/* 	if (currentMember.countryOfCitizenship &&
				allowedCountries.includes(currentMember.countryOfCitizenship)) {
				if (currentMember.type !== FamilyTypes.principal) {
					if (!currentMember.havePreviousUSAVisa) {
						console.log("no pasa: Debe seleccionar si cumple con los criterios mencionados");
						return false;
					}
				}

				if (currentMember.type === FamilyTypes.principal && familyMembers[0].purposeVisit === 'Visitar o transito') {
					if (!currentMember.havePreviousUSAVisa) {
						console.log("no pasa: Debe seleccionar si cumple con los criterios mencionados");
						return false;
					}
				}
			} */

		if (currentMember.typeOfWorkPermit === "Postulación a Young Professionals" && YoungProfessionals.find((young) => young.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
			if (currentMember.email != currentMember.confirmEmail) {
				console.log('no pasa')
				return false
			}
			if (new Date() < currentMember.birthday) {
				console.log('no pasa')
				return false
			}
			if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted
				|| !currentMember.countryOfCurrentResidency
				|| (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal)) {
				console.log('no pasa')
				return false
			} else {
				console.log('el tipo', currentMember.typeOfWorkPermit)
				return true
			}
		}
		if (currentMember.typeOfWorkPermit === "Postulación a International CoOp" && InternationalCoOp.find((CoOp) => CoOp.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
			if (currentMember.email != currentMember.confirmEmail) {
				console.log('no pasa')
				return false
			}
			if (new Date() < currentMember.birthday) {
				console.log('no pasa')
				return false
			}
			if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted
				|| !currentMember.countryOfCurrentResidency
				|| (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal)) {
				console.log('no pasa')
				return false
			} else {
				console.log('el tipo', currentMember.typeOfWorkPermit)
				return true
			}
		}
		if (WorkingHoliday.find((holidays) => holidays.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
			if (currentMember.email != currentMember.confirmEmail) {
				console.log('no pasa')
				return false
			}
			if (new Date() < currentMember.birthday) {
				console.log('no pasa')
				return false
			}
			if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted
				|| !currentMember.countryOfCurrentResidency
				|| (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal)) {
				console.log('no pasa')
				return false
			} else {
				console.log('el tipo', currentMember.typeOfWorkPermit)
				return true
			}
		} else {
			if (((currentMember.type === FamilyTypes.conyuge
				|| currentMember.type === FamilyTypes.children) && principalUsed()?.purposeVisit === 'Trabajo'
				&&
				principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense'
				&&
				ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				&& (currentMember.appliedBefore === "si")
				&& currentMember.wantsBP === "no")) {
				if (currentMember.validBiometrics === undefined) {
					console.log('no pasa')
					return false
				}
			}
			if (((ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
			) && currentMember.type === FamilyTypes.principal)
				||
				((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				) && currentMember.type === FamilyTypes.conyuge && principalUsed()?.wantsBP === "si")
				||
				((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				) && currentMember.type === FamilyTypes.children && principalUsed()?.wantsBP === "si")
			) {
				/* if (!currentMember.haveEta && currentMember.typeOfWorkPermit !== "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") {
					console.log('no pasa')
					return false
				} */
				if (currentMember.haveEta === "no" && !currentMember.addEta) {
					console.log('no pasa')
					return false
				}
				/* if (!currentMember.wantsBP) {
					console.log('no pasa')
					return false
				} */
			}
			if (currentMember.isPreviousClient === "si" && currentMember.validContract !== true && currentMember.type === FamilyTypes.principal) {
				console.log('no pasa')
				return false
			}
			if (currentMember.appliedBefore === "si" && (!currentMember.canadianVisaRejection || (currentMember.validBiometrics === undefined && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
				if (currentMember.type === FamilyTypes.principal) {
					console.log('no pasa')
					return false
				}
			}
			if (currentMember.appliedBefore === "si" && (!currentMember.canadianVisaRejection || (!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
				if (currentMember.type === FamilyTypes.principal) {
					console.log('no pasa')
					return false
				}
			}
			if (currentMember.appliedBefore === "si" && ((!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true))) {
				if (currentMember.type === FamilyTypes.conyuge && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					console.log('no pasa')
					return false
				}
				if (currentMember.type === FamilyTypes.children && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					console.log('no pasa')
					return false
				}
			}
			if (new Date() < currentMember.birthday) {
				console.log('no pasa')
				return false
			}
			if (currentMember.email != currentMember.confirmEmail) {
				console.log('no pasa')
				return false
			}
			if (currentMember.wantsDependantChildren === "si" && !currentMember.howManyDependantChild && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp") {
				console.log('no pasa')
				return false
			}
			if (currentMember.wantsDependantChildren === "si" && currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0 && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp") {
				console.log('no pasa')
				return false
			}
			if (((currentMember.appliedBefore === "si"
				&& ageForBiometrics === true
				&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
				&& currentMember.type === FamilyTypes.principal)
				||
				((currentMember.type === FamilyTypes.conyuge
					|| currentMember.type === FamilyTypes.children)
					&&
					(ageForBiometrics === true)
					&&
					(ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
					&&
					currentMember.appliedBefore === "si"
				))) {
				if (!currentMember.validBiometrics) {
					console.log('no pasa')
					return false
				}
			}
			/* if (currentMember.typeOfWorkPermit !== 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá') {
				if ((currentMember.civilStatus === "Casado" || currentMember.civilStatus === "Unión marital de hecho") && currentMember.type === FamilyTypes.principal) {
					if (!currentMember.wantsAddConyugue) {
						console.log('no pasa')
						return false
					}
				}
			} */
			if (currentMember.type === FamilyTypes.principal && currentMember.typeOfApplication) {
				if (!currentMember.wantsDependantChildren && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp" && currentMember.typeOfWorkPermit !== "PGWP") {
					console.log('no pasa')
					return false
				}
			}
			if (!currentMember.email && currentMember.type === FamilyTypes.principal) {
				console.log('no pasa')
				return false
			}
			if (!currentMember.confirmEmail && currentMember.type === FamilyTypes.principal) {
				console.log('no pasa')
				return false
			}
			if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.employerName) {
				console.log('no pasa')
				return false
			}
			if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.employerEmail) {
				console.log('no pasa')
				return false
			}
			if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.companyName) {
				console.log('no pasa')
				return false
			}
			if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted
				|| !currentMember.countryOfCitizenship || !currentMember.countryOfCurrentResidency
				|| (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal)
				|| !currentMember.appliedBefore) {
				console.log('no pasa')
				return false
			} else {
				return true
			}
		}
	}

	const checkErrorFamily = () => {
		const familyErrorInfo: any = []
		familyMembers.map((familyMember: any) => {
			let counter = 0
			if (familyMember.type === FamilyTypes.principal && currentMember.address === undefined) {
				setNotValid(true)
				console.log('Error')
				counter++
			}

			if (familyMember.type === FamilyTypes.conyuge &&
				(familyMember.lastname === '' || familyMember.lastname === undefined)) {
				console.log('Error')
				counter++
			}

			if (familyMember.type === FamilyTypes.conyuge &&
				(familyMember.name === '' || familyMember.name === undefined)) {
				console.log('Error')
				counter++
			}

			if (familyMember.type === FamilyTypes.conyuge &&
				(familyMember.countryOfCitizenship === '' || familyMember.countryOfCitizenship === undefined)) {
				console.log('Error ')
				counter++
			}

			if (familyMember.type === FamilyTypes.conyuge &&
				(familyMember.countryOfCurrentResidency === '' || familyMember.countryOfCurrentResidency === undefined)) {
				console.log('Error ')
				counter++
			}

			/* 	if (familyMember.type === FamilyTypes.principal) {
					if (!familyMember.havePreviousUSAVisa) {
						counter++;
					}
				}
	 */
			/* if (familyMember.countryOfCitizenship &&
				allowedCountries.includes(familyMember.countryOfCitizenship)) {
				if ((familyMember.type !== FamilyTypes.principal || (familyMember.type === FamilyTypes.principal && familyMembers[0].purposeVisit === 'Visitar o transito'))) {
					// Aplicar las validaciones relevantes
					if (!familyMember.havePreviousUSAVisa) {
						counter++;
					}
				}
			} */
			if ( familyMember.type === FamilyTypes.principal &&
				currentMember.typeOfWorkPermit === "Postulación a Young Professionals" &&
				YoungProfessionals.find((young) => young.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
				if (currentMember.email != currentMember.confirmEmail) {
					console.log('Error')
					counter++
				}
				if (new Date() < currentMember.birthday) {
					console.log('Error')
					counter++
				}
				if (!currentMember.name) {
					console.log('Error')
					counter++
				}
				if (!currentMember.lastname) {
					console.log('Error')
					counter++
				}
				if (!currentMember.birthdayFormatted) {
					console.log('Error')
					counter++
				}
				if (!currentMember.countryOfCurrentResidency) {
					console.log('Error')
					counter++
				}
				if (currentMember.type === 'Solicitante principal' && !currentMember.address) {
					console.log('Error')
					counter++
				}
				if (!currentMember.civilStatus && currentMember.type === 'Solicitante principal') {
					console.log('Error')
					counter++
				}
				if (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
			}
			if (currentMember.typeOfWorkPermit === "Postulación a International CoOp" && InternationalCoOp.find((CoOp) => CoOp.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
				if (currentMember.email != currentMember.confirmEmail) {
					console.log('Error')
					counter++
				}
				if (new Date() < currentMember.birthday) {
					console.log('Error')
					counter++
				}
				if (!currentMember.name) {
					console.log('Error')
					counter++
				}
				if (!currentMember.lastname) {
					console.log('Error')
					counter++
				}
				if (!currentMember.birthdayFormatted) {
					console.log('Error')
					counter++
				}
				if (!currentMember.countryOfCurrentResidency) {
					console.log('Error')
					counter++
				}
				if (currentMember.type === 'Solicitante principal' && !currentMember.address) {
					console.log('Error')
					counter++
				}
				if (!currentMember.civilStatus && currentMember.type === 'Solicitante principal') {
					console.log('Error')
					counter++
				}
				if (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
			}
			if (WorkingHoliday.find((holidays) => holidays.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())) {
				if (currentMember.email != currentMember.confirmEmail) {
					console.log('Error')
					counter++
				}
				if (new Date() < currentMember.birthday) {
					console.log('Error')
					counter++
				}
				if (!currentMember.name) {
					console.log('Error')
					counter++
				}
				if (!currentMember.lastname) {
					console.log('Error')
					counter++
				}
				if (!currentMember.birthdayFormatted) {
					console.log('Error')
					counter++
				}
				if (!currentMember.countryOfCurrentResidency) {
					console.log('Error')
					counter++
				}
				if (currentMember.type === 'Solicitante principal' && !currentMember.address) {
					console.log('Error')
					counter++
				}
				if (!currentMember.civilStatus && currentMember.type === 'Solicitante principal') {
					console.log('Error')
					counter++
				}
				if (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
			} else {
				//START online application
				if (((currentMember.type === FamilyTypes.conyuge
					|| currentMember.type === FamilyTypes.children) && principalUsed()?.purposeVisit === 'Trabajo'
					&&
					principalUsed()?.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense'
					&&
					ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
					&& (currentMember.appliedBefore === "si")
					&& currentMember.wantsBP === "no")) {
					if (currentMember.validBiometrics === undefined) {
						counter++
					}
				}
				//END online application
				if (((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				) && currentMember.type === FamilyTypes.principal)
					||
					((ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
					) && currentMember.type === FamilyTypes.conyuge && principalUsed()?.wantsBP === "si")
					||
					((ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
					) && currentMember.type === FamilyTypes.children && principalUsed()?.wantsBP === "si")
				) {
				/* 	if (!currentMember.haveEta && currentMember.typeOfWorkPermit !== "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") {
						console.log('Error')
						counter++
					} */
					if (currentMember.haveEta === "no" && !currentMember.addEta) {
						console.log('Error')
						counter++
					}
				/* 	if (!currentMember.wantsBP) {
						console.log('Error')
						counter++
					} */
				}
				if (currentMember.isPreviousClient === "si" && currentMember.validContract !== true && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
				//cuado no responde nada in valid biometrics
				if (currentMember.appliedBefore === "si" && (!currentMember.canadianVisaRejection || (currentMember.validBiometrics === undefined && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
					if (currentMember.type === FamilyTypes.principal) {
						console.log('Error')
						counter++
					}
				}
				//is previous client check only for principal
				if (currentMember.appliedBefore === "si" && (!currentMember.canadianVisaRejection || (!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
					if (currentMember.type === FamilyTypes.principal) {
						console.log('Error')
						counter++
					}
				}
				if (currentMember.appliedBefore === "si" && ((!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true))) {
					if (currentMember.type === FamilyTypes.conyuge && ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
						console.log('Error')
						counter++
					}
					if (currentMember.type === FamilyTypes.children && ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
						console.log('Error')
						counter++
					}
				}
				if (new Date() < currentMember.birthday) {
					console.log('Error')
					counter++
				}
				if (currentMember.email != currentMember.confirmEmail) {
					console.log('Error')
					counter++
				}
				if (currentMember.wantsDependantChildren === "si" && !currentMember.howManyDependantChild && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp") {
					console.log('Error')
					counter++
				}
				if (currentMember.wantsDependantChildren === "si" && currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0 && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp") {
					console.log('Error')
					counter++
				}
				if (((currentMember.appliedBefore === "si"
					&& ageForBiometrics === true
					&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
					&& currentMember.type === FamilyTypes.principal)
					||
					((currentMember.type === FamilyTypes.conyuge
						|| currentMember.type === FamilyTypes.children)
						&&
						(ageForBiometrics === true)
						&&
						(ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
						&&
						currentMember.appliedBefore === "si"
					))) {
					if (!currentMember.validBiometrics) {
						console.log('Error')
						counter++
					}
				}

				if (currentMember.type === FamilyTypes.principal && currentMember.typeOfApplication) {
					if (!currentMember.wantsDependantChildren && currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp" && currentMember.typeOfWorkPermit !== "PGWP") {
						console.log('Error')
						counter++
					}
				}
				if (!currentMember.email && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
				if (!currentMember.confirmEmail && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
				if (!currentMember.name) {
					console.log('Error')
					counter++
				}
				if (!currentMember.lastname) {
					console.log('Error')
					counter++
				}
				if (!currentMember.birthdayFormatted) {
					console.log('Error')
					counter++
				}
				if (!currentMember.countryOfCitizenship) {
					console.log('Error')
					counter++
				}
				if (!currentMember.countryOfCurrentResidency) {
					console.log('Error')
					counter++
				}
				if (currentMember.type === FamilyTypes.principal && !currentMember.civilStatus) {
					console.log('Error')
					counter++
				}
				if (!currentMember.phoneNumber && currentMember.type === FamilyTypes.principal) {
					console.log('Error')
					counter++
				}
				if (!currentMember.appliedBefore) {
					console.log('Error')
					counter++
				}
				if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.employerName) {
					console.log('Error')
					counter++
				}
				if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.employerEmail) {
					console.log('Error')
					counter++
				}
				if (currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' && !currentMember.companyName) {
					console.log('Error')
					counter++
				}
			}
			familyErrorInfo.push({ id: familyMember.id, errors: counter })
		})
		setMembersErrors(familyErrorInfo)
		return familyErrorInfo
	}


	const popupBeforeRejection = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<br><p>Si ha tenido rechazos previos de visas canadiense de la MISMA NATURALEZA al visado actual que desea aplicar ahora, por ejemplo: tiene rechazo de solicitud de permiso de estudios, y actualmente está re aplicando a permiso de estudios. Le recomendamos enormemente que antes de la contratación de nuestros servicios, solicite un ATIP primero para poder conocer específicos de las razones de rechazo (esto va más a fondo de las razones estándar que aparecen en su carta de negación). Con este contexto, es posible identificar de manera más clara los elementos de rechazo para que usted pueda trabajar en su perfil antes de una segunda aplicación. Esta información de igual manera permite al consultor migratorio trabajar más claramente en los elementos clave de su re aplicación para contra argumentar las razones de rechazo previo. Si desea retener los servicios de Immiland para aplicar primero a su ATIP antes de pasar a su solicitud de visado, puede dirigirse al siguiente link: https://en.immilandcanada.com/otros-servicios-migratorios/visa-negada-atip</p>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupAddETA = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<p>Usted deberá tener una <b>Autorización de viaje electrónica (eTA) válida</b> al momento en que vayamos a procesar su solicitud.</p>' + '<br></br>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const formPersonal = () => {
		return (<><>
			<div className='flex flex-wrap justify-center'>
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Tipo de solicitud</Label>
						<Label style={{ color: '#22AD5C' }}>{currentMember.purposeVisit}</Label>
					</BoxModal>}
				<BoxModal>
					<Label>Nombres: (Como aparece en su pasaporte)</Label>
					<Input
						name='Nombres'
						className='custom-input'
						label='Nombres'
						placeholder='Nombres'
						type='text'
						value={currentMember.name}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('name', e.target.value) : handleChange('name', undefined) }
						}}
						size='small' />
					{!currentMember.name && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Los nombres son obligatorios</span>}
				</BoxModal>
				<BoxModal>
					<Label>Apellidos: (Como aparece en su pasaporte) </Label>
					<Input
						name='Apellidos'
						className='custom-input'
						label='Apellidos'
						placeholder='Apellidos'
						type='text'
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('lastname', e.target.value) : handleChange('lastname', undefined) }
						}}
						value={currentMember.lastname}
						size='small' />
					{!currentMember.lastname && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Los apellidos son obligatorios
						</span>}
				</BoxModal>
				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Email</Label>
						<Input
							className='custom-input'
							label='Email'
							defaultValues={currentMember.email}
							placeholder='Email'
							type='email'
							id='email'
							name='Email'
							onChange={(e: any) => {
								const emailLowerCase = e.target.value.toLowerCase(); // Convertir a minúsculas
								emailLowerCase !== '' && emailLowerCase.match(emailRegex)
								  ? handleChange('email', emailLowerCase)
								  : handleChange('email', undefined);
							  }}
						
							value={currentMember.email}
							size='small'
							required={true} 
							autoComplete='off'/>
						{!currentMember.email && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su email y revise que este escrito de forma correta</span>}
					</BoxModal>}
				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Confirmar Email</Label>
						<div className='relative'>
							<Input
								className='custom-input'
								label='Email'
								defaultValues={currentMember.confirmEmail}
								placeholder='Email'
								type='email'
								id='email'
								name='email'
								value={currentMember.confirmEmail}
								onChange={(e: any) => {
									const confirmEmailLowerCase = e.target.value.toLowerCase(); // Convertir a minúsculas
									confirmEmailLowerCase !== '' && confirmEmailLowerCase.match(emailRegex)
									  ? handleChange('confirmEmail', confirmEmailLowerCase)
									  : handleChange('confirmEmail', undefined);
									confirmEmailLowerCase !== '' && localStorage.setItem("email", confirmEmailLowerCase);
								  }}
								size='small'
								required={true} 
								autoComplete='off'/>
						</div>
						{(!currentMember.confirmEmail || currentMember.confirmEmail !== currentMember.email) && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>El campo escrito en " email" y en "confirmar email" deben ser iguales</span>}
					</BoxModal>}
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Número de telefono : (+código del país) + (000-000-0000)</Label>
						<Input
							name='Numero de telefono'
							defaultValues={currentMember.phoneNumber}
							className='custom-input'
							label='Numero de telefono'
							placeholder='Numero de telefono'
							type='text'
							value={currentMember.phoneNumber}
							onChange={(e: any) => handleChange('phoneNumber', e.target.value)}
							size='small' />
						{!currentMember.phoneNumber && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su numero de telefono </span>}
					</BoxModal>}
				<DateOfBirth displayErrors={displayErrors} errorDate={errorDate} setErrorDate={setErrorDate} setAgeForBiometrics={setAgeForBiometrics} edit={edit} />
				<BoxModal>
					<Label>País emisor de su pasaporte</Label>
					<div style={{ color: '#22AD5C' }}>
						{currentMember.type === FamilyTypes.principal ? <>{currentMember.countryOfCitizenship}</> : <CountrySelector
							handleChange={(e: any) => {
								{ e.target.value != '' ? handleChange('countryOfCitizenship', e.target.value) : handleChange('countryOfCitizenship', undefined) }
							}}
							value={currentMember.countryOfCitizenship || ''}
							label='Pais' />}
					</div>
					{!currentMember.countryOfCitizenship && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar país de ciudadanía
						</span>}
				</BoxModal>
				<BoxModal>
					<Label>País de residencia actual:</Label>
					<div className='relative'>
						<CountrySelector
							handleChange={(e: any) => {
								{ e.target.value != '' ? handleChange('countryOfCurrentResidency', e.target.value) : handleChange('countryOfCurrentResidency', undefined) }
							}}
							value={currentMember.countryOfCurrentResidency || ''}
							label='Pais' />
					</div>
					{!currentMember.countryOfCurrentResidency && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar país de residencia
						</span>}
				</BoxModal>
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Dirección</Label>
						<Input
							name='Direccion'
							defaultValues={currentMember.address}
							className='custom-input'
							label='Direccion'
							placeholder='Direccion'
							type='text'
							value={currentMember.address}
							onChange={(e: any) => {
								{ e.target.value != '' && e.target.value.match(regex) ? handleChange('address', e.target.value) : handleChange('address', undefined) }
							}}
							size='small' />
						{!currentMember.address && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe indicar su dirección. Este campo no puede tener Caracteres especiales(#~€$%).
							</span>}
					</BoxModal>}
				<BoxModal>
				<Label>Estado Civil</Label>
					<div className='relative'>
						{currentMember.type === FamilyTypes.children && currentMember.age <= 5 ? (
							// Display "Soltero" for children
							<p style={{ color: '#22AD5C', fontWeight: 'bold' }}>Soltero</p>
						) : (
							// Existing code for other member types
							currentMember.type !== 'Conyuge o Pareja de hecho' ? (
								<CivilStatusSelector
									handleChange={(e: any) => {
										e.target.value !== '' 
											? handleChange('civilStatus', e.target.value)
											: handleChange('civilStatus', undefined);

										if (e.target.value === "Casado" || e.target.value === "Unión marital de hecho") {
											console.log('Casado o en UMdH, cm:', currentMember.type);
										} else {
											handleChange('wantsAddConyugue', 'no');
											handleChange('ApplyWithSpouse', 'no');
										}
									}}
									value={currentMember.civilStatus || ''}
									label='Pais'
								/>
							) : (
								<p style={{ color: '#22AD5C' }}>{principalUsed()?.civilStatus}</p>
							)
						)}
					</div>
					{(!currentMember.civilStatus && (currentMember.type === FamilyTypes.principal || currentMember.type === FamilyTypes.children)) && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar su estado civil
						</span>}

					{currentMember.type === 'Solicitante principal' &&
						familyMembers[0].typeOfApplication !== 'Super visa' &&
						<>
							{
								(familyMembers[0].civilStatus === "Casado" ||
									familyMembers[0].civilStatus === "Unión marital de hecho") &&
									(familyMembers[0].typeOfWorkPermit !== "Postulación a Working Holiday"
										&& familyMembers[0].typeOfWorkPermit !== "Postulación a Young Professionals"
										&& familyMembers[0].typeOfWorkPermit !== "Postulación a International CoOp"
										&& familyMembers[0].typeOfWorkPermit !== "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") ?
									<BoxModal>
										<Switch
											className="custom-switch"
											checked={familyMembers[0].wantsAddConyugue === "si"}
											onChange={(checked) => {
												if (checked === true) {
													familyMembers[0].wantsAddConyugue = "si";
													familyMembers[0].ApplyWithSpouse = 'si'
													const newMember = {
														id: nanoid(),
														name: '',
														type: FamilyTypes.conyuge,
														lastname: '',
														nationality: '',
														age: -1,
														hasBiometrics: undefined,
														birthday: new Date(),
														birthdayFormatted: moment().format('YYYY-MM-DD'),
														visaType: '',
														groupId: familygroup[0].id,
														monthDifference: -1,
														documents: [
															{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }
														]
													};
													setFamilyMember([...familyMembers, newMember]);
												} else {
													familyMembers[0].wantsAddConyugue = "no";
													familyMembers[0].ApplyWithSpouse = 'no'
													const filteredFamilyMembers = familyMembers.filter((member) => member.type !== FamilyTypes.conyuge);
													setFamilyMember(filteredFamilyMembers);
													setCurrentMember(familyMembers[0]);
												}
											}}
										/>
										<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGE</label>
									</BoxModal> :
									<>
										<BoxModal>
											<Switch
												disabled
												className="custom-switch"
												style={{ backgroundColor: 'gray' }}
											/>
											<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGE</label>
										</BoxModal>
									</>
							}
							{(currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" &&
								currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" &&
								currentMember.typeOfWorkPermit !== "Postulación a International CoOp") ?
								<BoxModal>
									<div className='flex flex-row gap-4 items-center'>
										<div className='flex flex-row gap-2'>
											<Switch
												checked={familyMembers[0].wantsDependantChildren === "si"}
												className="custom-switch"
												onChange={(checked: boolean) => {
													if (checked === true) {
														familyMembers[0].wantsDependantChildren = "si";
													} else {
														familyMembers[0].wantsDependantChildren = "no";
													}
													if (!checked) {
														familyMembers[0].howManyDependantChild = undefined;
														const filteredFamilyMembers = familyMembers.filter(
															(member) => member.type !== FamilyTypes.children
														);
														setFamilyMember(filteredFamilyMembers);
														setCurrentMember(familyMembers[0]);
													}
												}}
											/>
											<label className="inline-block pl-[0.15rem] hover:cursor-pointer">
												AGREGAR HIJOS
											</label>
										</div>
										{familyMembers[0].wantsDependantChildren === "si" &&
											<Input
												style={{ width: '4rem', height: '30px' }}
												className='custom-input no-spinner'
												label='Numero de Hijos dependientes'
												placeholder='0'
												type='number'
												inputMode='numeric'
												min={1}
												value={familyMembers[0].howManyDependantChild}
												onKeyDown={(e: any) => {
													if (e.key === "ArrowUp" || e.key === "ArrowDown") {
														e.preventDefault();
													}
												}}
												onChange={(e: any) => {
													if (e.target.value > -1 && e.target.value < 10) {
														familyMembers[0].howManyDependantChild = e.target.value
														/* { e.target.value > 0 ? handleChange('howManyDependantChild', e.target.value) : handleChange('howManyDependantChild', undefined) } */
														{
															e.target.value != '' &&
																e.target.value > 0 &&
																localStorage.setItem("wantsDependantChildren", e.target.value);
														}
														// Use the functional form of setState to update familyMembers
														if (e.target.value === '') {
															const filteredFamilyMembers = familyMembers.filter(member => member.type !== FamilyTypes.children);
															setFamilyMember(filteredFamilyMembers)
															setCurrentMember(familyMembers[0])
															return
														}
														const updatedFamilyMembers = [...familyMembers];
														for (let i = 0; i < e.target.value; i++) {
															const newMember: IFamilys = {
																id: nanoid(),
																name: '',
																type: FamilyTypes.children,
																lastname: '',
																nationality: '',
																age: -1,
																hasBiometrics: undefined,
																birthday: new Date(),
																birthdayFormatted: moment().format('YYYY-MM-DD'),
																visaType: '',
																groupId: familygroup[0].id,
																monthDifference: -1,
																documents: [{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
																{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }]
															}
															updatedFamilyMembers.push(newMember);
														}
														setFamilyMember(updatedFamilyMembers)
													}
												}}
												size='small'
											/>
										}
									</div>
									{currentMember.wantsDependantChildren === "si" && ((currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) || (!currentMember.howManyDependantChild)) && displayErrors === true &&
										<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar si desea agregar a hijos dependientes</span>}
								</BoxModal> :
								<BoxModal>
									<Switch
										disabled
										className="custom-switch"
										style={{ backgroundColor: 'gray' }}
									/>
									<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR HIJOS</label>
								</BoxModal>}
						</>}
				</BoxModal>
				<BoxModal>
					<Label>
						¿Previamente ha aplicado para entrar o permanecer en Canadá?
					</Label>
					<Select
						name='¿Previamente ha aplicado para entrar o permanecer en Canadá?'
						defaultValue={currentMember.appliedBefore}
						className='custom-input'
						labelId='demo-simple-select-label'
						label='¿Previamente ha aplicado para entrar o permanecer en Canadá?'
						value={currentMember.appliedBefore}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('appliedBefore', e.target.value) : handleChange('appliedBefore', undefined) }
						}}
					>
						<option value=''>Seleccione</option>
						<option value='si'>Si</option>
						<option value='no'>No</option>
					</Select>
					{!currentMember.appliedBefore && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si previamente ha aplicado para entrar o permanecer en Canadá</span>}
				</BoxModal>
			{currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp" && currentMember.appliedBefore === "si" && currentMember.type === FamilyTypes.principal && (
          <BoxModal>
            <Label>
              ¿Ha tenido rechazos previos con Canadá del mismo tipo de solicitud a la que desea aplicar?
            </Label>
            <Select
              name='Alguna vez le ha sido negada una solicitud por el Gobierno de Canadá'
              defaultValue={currentMember.canadianVisaRejection}
              className='custom-input'
              labelId='demo-simple-select-label'
              label='Alguna vez le ha sido negada una solicitud por el Gobierno de Canadá'
              value={currentMember.canadianVisaRejection}
              onChange={(e: any) => {
                handleChange('canadianVisaRejection', e.target.value);
                if (e.target.value === 'si') {
                  setShowWarning(true);
                  if (principalUsed()?.typeOfApplication !== 'Electronic Travel Authorization - eTA') {
                    /* popupSubmittionLetter(); */
                  }
                } else {
                  setShowWarning(false);
                }
              }}
            >
              <option value=''>Seleccione</option>
              <option value='si'>Si</option>
              <option value='no'>No</option>
            </Select>
            {!currentMember.canadianVisaRejection &&
              currentMember.type === FamilyTypes.principal &&
              displayErrors === true && (
                <span
                  className='text-tomato-500 text-xs italic'
                  style={{ marginTop: '-11px', marginBottom: '11px' }}
                >
                  Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá
                </span>
              )}

            {/* Mostrar el div solo si la respuesta es 'si' */}
            {showWarning && principalUsed()?.typeOfApplication !== "Electronic Travel Authorization - eTA" && currentMember.typeOfClosedWorkPermit !== 'International Experience Canada work permit' && currentMember.purposeVisit !== "Residencia Permanente" && (
              <div style={{ height: '70%', width:'100%', position: 'static', marginTop:'2rem' }} className='rejected-doc-yellow-atip mt-2 mb-2'>
                <div className='flex flex-row items-start gap-4 items-center' style={{ width: '100%' }}>
                  <div className='flex flex-col' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                      <img src='/icons/warning.svg' alt='WarningIcon' style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                      <div style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: '#9D5425', fontSize: '13.8px', margin: '0px', fontWeight: '700' }}>
                          <b>Evite nuevos rechazos añadiendo Servicios de Atip</b>
                        </p>
                        <p style={{ color: '#9D5425', fontSize: '14px', margin: '0px', fontWeight: '400' }}>
                          <a>Obtenga detalles específicos del rechazo de su visa, más allá de las razones genéricas. <br />	
                          Con las notas del oficial, identificaremos los elementos que causaron el rechazo y le ofreceremos estrategias precisas para aumentar sus probabilidades de aprobación.</a>
                          <br />	<b style={{ fontWeight: '600', fontSize: '13.8px' }}>Se añadirá un cargo de $400 CAD</b> <a style={{ fontWeight: '400' }}>a su cotización<br /></a>
                        </p>
						<p style={{ color: '#9D5425', fontSize: '14px', margin: '0px', fontWeight: '400' }}>	
                          <a><b style={{ fontWeight: '700', fontSize: '13.8px' }}>IMPORTANTE:</b> Los tiempos de proceso de ATIP por parte del gobierno pueden tomar 
						  <b style={{ fontWeight: '600', fontSize: '13.8px' }}>de 60 hasta sobre los 90 días.</b> Si usted requiere aplicar a su visado al corto plazo, 
						  el ATIP puede que llegue después del envío de su aplicación. 
						  Tenga en consideración este elemento antes de la compra.</a>
                        </p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#F9FAFB', width: '100%', borderRadius: '10px', marginTop: '20px' }}>
                      <div style={{ fontSize: '1.1rem', margin: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                        <Checkbox
                          checked={checkboxChecked}
                          onChange={(e) => {
                            setCheckboxChecked(e.target.checked);
                            currentMember.wantsAtip = e.target.checked;
                            console.log('wantsAtip:', currentMember.wantsAtip);
                          }}
                        />
                        <b style={{ fontSize: '16px', fontWeight: '400', color: 'black' }}>Deseo añadir el servicio de ATIP</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </BoxModal>
        )}
				{(currentMember.typeOfWorkPermit === "Postulación a Young Professionals" || currentMember.typeOfWorkPermit === "Postulación a International CoOp") && currentMember.type === FamilyTypes.principal &&
					<>
						<BoxModal>
							<Label style={{ display: 'flex', alignItems: 'center' }}>
								Si su postulación es seleccionada, podrá solicitar un permiso de trabajo. ¿Desea incluir el permiso de trabajo (FASE 2)?
							</Label>
							<Select
								name='Si su postulación es seleccionada, recibirá una invitación. Solo en ese momento, podrá solicitar un permiso de trabajo. Para agilizar el proceso: ¿Desea incluir el permiso de trabajo (FASE 2)?'
								defaultValue=''
								className='custom-input'
								labelId='demo-simple-select-label'
								label='Si su postulación es seleccionada, recibirá una invitación. Solo en ese momento, podrá solicitar un permiso de trabajo. Para agilizar el proceso: ¿Desea incluir el permiso de trabajo (FASE 2)?'
								onChange={(e: any) => {
									{ e.target.value === 'si' ? handleChange('typeOfClosedWorkPermit', 'International Experience Canada work permit') : handleChange('typeOfClosedWorkPermit', undefined) }
								}}>
								<option value=''>Seleccione</option>
								<option value='si'>Si</option>
								<option value='no'>No</option>
							</Select>
						</BoxModal>
						{currentMember.typeOfClosedWorkPermit === 'International Experience Canada work permit' && <BoxModal>
							<Label style={{ display: 'flex', alignItems: 'center' }}>
								¿Desea incluir los gastos de validación por su empleador?
							</Label>
							<Select
								name='Si su postulación es seleccionada, recibirá una invitación. Solo en ese momento, podrá solicitar un permiso de trabajo. Para agilizar el proceso: ¿Desea incluir el permiso de trabajo (FASE 2)?'
								defaultValue=''
								className='custom-input'
								labelId='demo-simple-select-label'
								label='Si su postulación es seleccionada, recibirá una invitación. Solo en ese momento, podrá solicitar un permiso de trabajo. Para agilizar el proceso: ¿Desea incluir el permiso de trabajo (FASE 2)?'
								onChange={(e: any) => {
									{ e.target.value === 'si' ? handleChange('labourMarketImpactAssessment', e.target.value) : handleChange('labourMarketImpactAssessment', undefined) }
								}}>
								<option value=''>Seleccione</option>
								<option value='si'>Si</option>
								<option value='no'>No</option>
							</Select>
						</BoxModal>}
					</>}
				{((currentMember.appliedBefore === "si"
					&& currentMember.type === FamilyTypes.principal
					&& currentMember.typeOfWorkPermit !== "Postulación a Working Holiday"
					/*&& currentMember.typeOfWorkPermit !== "Postulación a Young Professionals"
					&& currentMember.typeOfWorkPermit !== "Postulación a International CoOp"
					&& currentMember.ageForBiometrics === true
					&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" */
				)
					||
					(currentMember.appliedBefore === "si" && currentMember.type === FamilyTypes.conyuge &&
						ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined
						&& currentMember.appliedBefore === "si")
					||
					(currentMember.type === FamilyTypes.children && currentMember.ageForBiometrics === true &&
						ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined
						&& currentMember.appliedBefore === "si"))
					&&
					<BoxModal>
						<Label>
							¿Tiene biométricos válidos?
						</Label>
						<Select
							name='tieneBiometricos'
							defaultValue={currentMember.validBiometrics}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Tiene biometricos'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('validBiometrics', e.target.value) : handleChange('validBiometrics', undefined) }
								if (e.target.value === "si") {
									currentMember.documents[0].active = true
								} else {
									currentMember.documents[0].active = false
								}
							}}
							value={currentMember.validBiometrics}>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{currentMember.documents[0].nameOfTheDocumentSubmitted !== undefined && currentMember.documents[0].nameOfTheDocumentSubmitted.length > 0 && currentMember.documents[0].nameOfTheDocumentSubmitted}
						{!currentMember.validBiometrics && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si tiene biometricos</span>}
					</BoxModal>}
				{currentMember.appliedBefore === "si" && currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<p className='md:h-2 h8' style={{ fontSize: '0.7rem', fontWeight: 'bold', color: '#22AD5C', marginBottom: '10px' }}>¡Obtenga un descuento!</p>
						<Label style={{ fontSize: '0.7rem' }}>¿Anteriormente ha realizado algún proceso de visado con Immiland?
						</Label>
						<Select
							name='Anteriormente ha realizado algun proceso de visado con Immiland'
							defaultValue={currentMember.isPreviousClient}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Anteriormente ha realizado algun proceso de visado con Immiland'
							value={currentMember.isPreviousClient}
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('isPreviousClient', e.target.value) : handleChange('isPreviousClient', undefined) }
							}}>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.isPreviousClient && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá</span>}
					</BoxModal>}
				{currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' ?
					<>
						<BoxModal>
							<Label>Nombre completo del empleador</Label>
							<Input
								name='Nombre del empleador'
								className='custom-input'
								label='Nombre del empleador'
								placeholder='Nombre del empleador'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('employerName', e.target.value) : handleChange('employerName', undefined) }
								}}
								value={currentMember.employerName}
								size='small' />
							{!currentMember.employerName && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe indicar el nombre del empleador
								</span>}
						</BoxModal>
						<BoxModal>
							<Label>Correo electronico de su empleador</Label>
							<Input
								name='Correo electronico de su empleador'
								className='custom-input'
								label='Correo electronico de su empleador'
								placeholder='Correo electronico de su empleador'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('employerEmail', e.target.value) : handleChange('employerEmail', undefined) }
								}}
								value={currentMember.employerEmail}
								size='small' />
							{!currentMember.employerEmail && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe agregar el email del empleador
								</span>}
						</BoxModal>
						<BoxModal>
							<Label>Nombre legal de la compañia</Label>
							<Input
								name='Nombre legal de la compañia'
								className='custom-input'
								label='Nombre legal de la compañia'
								placeholder='Nombre de la compañia'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('companyName', e.target.value) : handleChange('companyName', undefined) }
								}}
								value={currentMember.companyName}
								size='small' />
							{!currentMember.companyName && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe agregar el nombre de la compañia
								</span>}
						</BoxModal>
					</> : null}
				{/* boton contrac docusign */}
				{currentMember.isPreviousClient === "si" && currentMember.type === FamilyTypes.principal &&
					<BoxModal className='sm:w-96 px-3 mb-3 md:mb-3 w-full' style={{ marginTop: '12px' }}>
						<MyComponent setVerificationDocusign={setverificationDocusign} />
						{currentMember.isPreviousClient === "si" && !currentMember.validContract && membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>No puede guardar sus datos si su número de contrato no ha sido verificado</span>}
					</BoxModal>}
				{!currentMember.validContract && currentMember.type === FamilyTypes.principal && (currentMember.validContract === undefined || currentMember.validContract === false) && /* totalProfFees >= 700 && */ <BoxModal>
					<Label>Código de Descuento</Label>
					<Input
						className='custom-input'
						label='Codigo'
						placeholder='Codigo'
						type='text'
						value={currentMember.discountCode}
						onChange={(e: any) => handleDiscountCodeChange(e)}
						size='small'
					/>
					</BoxModal>
				}
				{(currentMember.typeOfWorkPermit === "Postulación a Working Holiday" ||
					currentMember.typeOfWorkPermit === "Postulación a Young Professionals" ||
					currentMember.typeOfWorkPermit === "Postulación a International CoOp" ||
					currentMember.typeOfWorkPermit === "Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá") ? null : <>

					{(((ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
					) && currentMember.type === FamilyTypes.principal && currentMember.haveEta === "no")
						||
						((ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
						) && currentMember.type === FamilyTypes.conyuge && principalUsed()?.wantsBP === "si" && currentMember.haveEta === "no")
						||
						((ETA.find(
							(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
						) && currentMember.type === FamilyTypes.children && principalUsed()?.wantsBP === "si" && currentMember.haveEta === "no")
					) && <BoxModal>
							<Label>
								¿Desea añadir el servicio de autorización de viaje electronica (eTA)?
							</Label>
							<Select
								name='Desea añadir el servicio de autorización de viaje electronica (eTA)'
								defaultValue={currentMember.addEta}
								className='custom-input'
								labelId='demo-simple-select-label'
								label='Desea añadir el servicio de autorización de viaje electronica (eTA)'
								value={currentMember.addEta}
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('addEta', e.target.value) : handleChange('addEta', undefined) }
									{ if (e.target.value === 'no') { popupAddETA() } }
								}}
							>
								<option value=''>Seleccione</option>
								<option value='si'>Si</option>
								<option value='no'>No</option>
							</Select>
							{!currentMember.addEta &&
								ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) &&
								currentMember.haveEta === "no" &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar si desea añadir el servicio de autorización de viaje electronica (eTA)</span>}
						</BoxModal>}

					{currentMember.countryOfCitizenship &&
						principalUsed()?.typeOfWorkPermit !== 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense' &&
						allowedCountries.includes(currentMember.countryOfCitizenship) &&
						(currentMember.type === FamilyTypes.conyuge) ?
						<BoxModal>
							<Label>¿Ha tenido una visa de visitante canadiense en los últimos 10 años o posee una visa de visitante americana válida y planea ingresar a Canadá por vía aérea?</Label>
							<div className='relative'>
								<Select
									name='¿Cumple con los siguientes criterios?'
									defaultValue={currentMember.havePreviousUSAVisa}
									className='custom-input'
									labelId='demo-simple-select-label'
									label='Procesos en el pasado'
									value={currentMember.havePreviousUSAVisa}
									onChange={(e: any) => {
										{
											e.target.value != '' ?
												handleChange('havePreviousUSAVisa', e.target.value) :
												handleChange('havePreviousUSAVisa', undefined)
										}

									}}
								>
									<option value=''>Seleccione</option>
									<option value='si'>Si</option>
									<option value='no'>No</option>
								</Select>
							</div>
							{!currentMember.havePreviousUSAVisa &&
								displayErrors === true &&
								<span className='text-tomato-500 text-xs italic'
									style={{
										marginTop: '-11px',
										marginBottom: '11px'
									}}>
									Debe seleccionar si cumple con los criterios mencionados
								</span>}
						</BoxModal> : null}

					{currentMember.countryOfCitizenship &&
						allowedCountries.includes(currentMember.countryOfCitizenship) &&
						(currentMember.type === FamilyTypes.children &&
							currentMember.ageForVisitorDependant === true) ?
						<BoxModal>
							<Label>¿Ha tenido una visa de visitante canadiense en los últimos 10 años o posee una visa de visitante americana válida y planea ingresar a Canadá por vía aérea?</Label>

							<div className='relative'>
								<Select
									name='¿Cumple con los siguientes criterios?'
									defaultValue={currentMember.havePreviousUSAVisa}
									className='custom-input'
									labelId='demo-simple-select-label'
									label='Procesos en el pasado'
									value={currentMember.havePreviousUSAVisa}
									onChange={(e: any) => {
										{
											e.target.value != '' ?
												handleChange('havePreviousUSAVisa', e.target.value) :
												handleChange('havePreviousUSAVisa', undefined)
										}

									}}
								>
									<option value=''>Seleccione</option>
									<option value='si'>Si</option>
									<option value='no'>No</option>
								</Select>
							</div>
							{!currentMember.havePreviousUSAVisa &&
								displayErrors === true &&
								<span className='text-tomato-500 text-xs italic'
									style={{
										marginTop: '-11px',
										marginBottom: '11px'
									}}>
									Debe seleccionar si cumple con los criterios mencionados
								</span>}
						</BoxModal> : null}

				</>}
				{currentMember.typeOfWorkPermit === 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' &&
					currentMember.typeOfStatusConyuge === 'Estudiante' &&
					<p style={{ marginBottom: '2rem' }}><b>NOTA:</b> Tenga en cuenta que su permiso de trabajo no será válido por más tiempo que el permiso de estudio de pareja.</p>}
			</div>
			{(((ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
			) && currentMember.type === FamilyTypes.principal) ||
				((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				) && currentMember.type === FamilyTypes.conyuge && principalUsed()?.wantsBP === "si") ||
				((ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase())
				) && currentMember.type === FamilyTypes.children && principalUsed()?.wantsBP === "si")
			) &&
				<div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', paddingBottom: "3rem" }}>
					<div style={{ display: 'flex', alignItems: "center", paddingBottom: '1rem' }}><img width="50" height="50" src="https://img.icons8.com/ios/50/BF3C3C/box-important--v1.png" alt="box-important--v1" />
						<h2>Informacion importante: </h2>
					</div>
					<p className='paragraph'>• Todos los ciudadanos extranjeros que se encuentran fuera y dentro de Canadá deben presentar su solicitud únicamente por medios electrónicos, a menos que se especifique lo contrario.</p>
				</div>}
		</>
		</>)
	}
	const sorted = () => {
		// Primero, filtra los miembros en grupos
		const principalMember = familyMembers.find(member => member.type === FamilyTypes.principal);
		const conyugeMembers = familyMembers.filter(member => member.type === FamilyTypes.conyuge);
		const childrenMembers = familyMembers.filter(member => member.type === FamilyTypes.children);
		// Luego, combina los grupos en el orden deseado
		const sortedFamilyMembers = [];
		if (principalMember) {
			sortedFamilyMembers.push(principalMember);
		}
		if (conyugeMembers.length > 0) {
			sortedFamilyMembers.push(...conyugeMembers);
		}
		if (childrenMembers.length > 0) {
			sortedFamilyMembers.push(...childrenMembers);
		}
		return sortedFamilyMembers
	}

	return (
		<div className='my-4 h-100'>
			<Box>
				<>
					<form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
						<div className='flex flex-wrap -mx-3 mb-6'>
							<div className='mx-16'>
								<div>
									{sorted() && sorted().length > 0 && sorted().map((member, index) => {
										{ member.type === FamilyTypes.children && indexChildren++ }
										const memberError = membersErrors.find((error: any) => error.id === member.id);
										if (memberError && memberError.errors !== undefined) {
											return (
												<div>
													<div className='form-tab-continer'>
														{memberError.errors ? <>
															<div key={member.id} className='w-full'>
																<button className='custom-input-error'
																	onClick={(e) => {
																		e.preventDefault();
																		handleChangeMember(member.id);
																		if (memberError.errors > 0) {
																			setDisplayErrorGlobal(true);
																		} else {
																			setDisplayErrorGlobal(false);
																		}
																	}}>
																	<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																	<div className='flex flex-row gap-4'>
																		<div className='error-flag-container'>
																			<div className='error-flag-simbol'>
																				<div>x</div>
																			</div>
																			<p> Tiene {memberError.errors} errores</p>
																		</div>
																		<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																	</div>
																</button>
															</div>
														</> :
															<>
																<div key={member.id} className='w-full'>
																	<button className='custom-input-success'
																		onClick={(e) => {
																			e.preventDefault();
																			handleChangeMember(member.id);
																			if (memberError.errors > 0) {
																				setDisplayErrorGlobal(true);
																			} else {
																				setDisplayErrorGlobal(false);
																			}
																		}}
																	>
																		<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																		<div className='flex flex-row gap-4'>
																			<div className='success-flag-container'>
																				<div className='success-flag-simbol'>
																					<div>
																						<FontAwesomeIcon style={{ fontSize: '10px', color: 'white' }} icon={faCheck} />
																					</div>
																				</div>
																				<p> Tiene datos correctos</p>
																			</div>
																			<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																		</div>
																	</button>
																</div>
															</>}
														{member.id === currentMember.id &&
															formPersonal()}
													</div>
												</div>
											);
										} else {
											return (
												<div>
													<div className='form-tab-continer'>
														<button className='custom-input-button'
															style={{ display: 'flex', justifyContent: 'space-between' }}
															onClick={(e) => {
																e.preventDefault();
																handleChangeMember(member.id);
															}} key={member.id} >
															{member.type} {member.type === FamilyTypes.children && `#${indexChildren} `}
															<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
														</button>
														{member.id === currentMember.id &&
															formPersonal()}
													</div>
												</div>)
										}
									})}
								</div>
								{membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 ?
									<div className='errors-main-flag'>
										<div className='error-flag-simbol'>
											<div>x</div>
										</div>
										<div>
											<p style={{ color: '#BC1C21', fontSize: '0.9rem' }}>{`tiene ${membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0)} errores en su formulario`}</p>
											<p style={{ color: '#F56060', fontSize: '0.7rem' }}>Por favor, revise cada categoria y corrijalos para poder continuar</p>
										</div>
									</div> : null}
								<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
									{edit === false ?
										<Button
											type='button'
											onClick={() => {
												if (familyMembers[0].typeOfWorkPermit === "Postulación a Working Holiday"
													|| familyMembers[0].typeOfWorkPermit === "Postulación a Young Professionals"
													|| familyMembers[0].typeOfWorkPermit === "Postulación a International CoOp") {
													setCurrentMember(familyMembers[0])
													setStep(4)
												}
												if (((familyMembers[0].wantAddLmiaPositive === "si" && familyMembers[0].province !== 'Quebec')
													|| (familyMembers[0].wantAddLmiaPositive === "no" && familyMembers[0].province !== 'Quebec'))
													&& familyMembers[0].typeOfClosedWorkPermit === "REQUIERE de una evaluación de impacto del mercado laboral") {
													setCurrentMember(familyMembers[0])
													setStep(6)
												} else if ((familyMembers[0].wantCaq === "si" || familyMembers[0].wantCaq === "no") &&
													currentMember.province === "Quebec"
												) {
													setCurrentMember(familyMembers[0])
													setStep(7)
												} else if ((familyMembers[0].wantValidationLmiaExemptJobOffer === "si"
													|| familyMembers[0].wantValidationLmiaExemptJobOffer === "no") &&
													(familyMembers[0].typeOfClosedWorkPermit === "ESTÁ EXENTA de una evaluación de impacto del mercado laboral")
												) {
													setCurrentMember(familyMembers[0])
													setStep(8)
												} else if (familyMembers[0].elegiblePGWP === "si") {
													setCurrentMember(familyMembers[0])
													setStep(8)
												} else {
													setCurrentMember(familyMembers[0])
													setStep(2)
												}
											}} className='backbutton-disc2'>
											Atras
										</Button> : null}
									<Button
										type='button'
										onClick={() => {
											console.log('hola andrea', familyMembers)
											window.scrollTo(0, 0)
											checkErrorFamily()
											if (checkIfAllowSave() === true && (checkErrorFamily().some((item: any) => item.errors > 0) === false)) {
												jsonArray.length = 0
												handleChange('principal', true)
												handleChange('valid', true)
												for (const familyMem of familyMembers) {
													const answers: any = {
													}
													for (const key in keys) {
														answers[keys[key]] = familyMem[key as keyof typeof familyMem]
													}
													jsonArray.push(answers)
													familyMem.answers = []
													familyMem.answers[0] = answers
												}
												closeModal()
												setDisplayErrors(false)
												increaseEstep()
											} else {
												checkErrorFamily()
												setDisplayErrors(true)
											}
										}} className='startbutton-disc2'>
										Guardar
									</Button>
								</div>
								{/* end page 5 */}
							</div>
						</div>
					</form>
				</>
			</Box>
		</div >
	)
})
export default PersonalDataWork
