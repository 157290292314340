/* eslint-disable no-empty-pattern */
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import {  Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import { current } from '@reduxjs/toolkit'


interface IProps {
    products: Product[]
}
const CalculatePNAB: React.FC<IProps> = ({
    products
}) => {
    const expressEntryFee = products.find(element => element.contratationProductId == "117")

    const rootStore = useContext(RootStoreContext)

    const { familyMembers, setPNAB, getPNAB,principalUsed} =
        rootStore.familyStore
    const {
    } = rootStore.familyStore
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    let phase = 0
    const arrayOfMembers :any[]= []
    familyMembers.map((member) => {
        if (member.pn === true && principalUsed()?.province==='Alberta' ) {
            phase++
            const memberInfo = {
                    "memberName": member.name + " " + member.lastname,
                    "memberType": member.type
                }
                arrayOfMembers.push(memberInfo)
        }
    })
    if (expressEntryFee !== undefined) {
        setPNAB({
            id: expressEntryFee.id,
            productContratationId: expressEntryFee.contratationProductId,
            description: expressEntryFee.description,
            totalFee: phase * expressEntryFee.fee,
            name: expressEntryFee.name,
            quantity: phase,
            fee: expressEntryFee.price,
            memberInfo:arrayOfMembers,
            type:'gf',
        })
    }

    return (
        <>
            {getPNAB().quantity > 0 && (
                <>
                    <TableCell>
                        <Span style={{ fontWeight: 'bold' }}>Servicio</Span>
                        {getPNAB().name}
                    </TableCell>
                    <TableCell style={{ display: 'flex' }}>
                        <Span>Descripcion</Span><Description>{getPNAB().description}</Description>
                    </TableCell>
                    <TableCell >
                        <Span>Cantidad</Span>
                        {getPNAB().quantity}
                    </TableCell>
                    <TableCell className='border-b border-indigo-500'>
                        <Span>Precio </Span> CAD {formatter.format(getPNAB().totalFee)}
                    </TableCell>
                </>
            )}
        </>
    )
}

export default observer(CalculatePNAB)
