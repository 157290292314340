/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
	Box,
	Select,
} from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import {
	FileUploadSelectEvent,
} from 'primereact/fileupload'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { validateGeneral } from '../../utils/validateGeneral'
import { validateVisitor } from '../../utils/validateVisitor'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import PersonalData from './PersonalData'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const PreMandamusOut: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
		familyMembers
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [etaCountry, setEtaCountry] = React.useState(currentMember.etaCountry)
	const [usVisa, setUSVisa] = React.useState(false)
	const [showEtaImmiland, setShowEtaImmiland] = React.useState(false)

	const [emailForUploaders, setEmailForUploaders] = React.useState('')

	const fileUploadRef = useRef(null)
	const [displayErrors, setDisplayErrors] = React.useState(false)

	useEffect(() => { }, [currentMember])

	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])

	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])

	useEffect(() => { }, [open])

	useEffect(() => { }, [principalUsed])

	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateEtaCountry = (nationality: string) => {
		const isEtaCountry = ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === nationality.toLocaleLowerCase()
		)
		setEtaCountry(false)
		setShowEtaImmiland(false)
		handleChange('etaCountry', false)
		handleChange('validEta', false)
		if (isEtaCountry) {
			handleChange('etaCountry', true)
			handleChange('validEta', true)
			setEtaCountry(true)
		}
		validateBrazil()
	}

	const validateBrazil = () => {
		if (currentMember.nationality.toLocaleLowerCase() === 'brazil') {
			setUSVisa(true)
			if (currentMember.etaRejection === 'si') {
				handleChange('validEta', false)
				setShowBiometricSelect(true)
				setShowEtaImmiland(false)
			} else {
				if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'no') {
					handleChange('validEta', false)
					setShowBiometricSelect(true)
					setShowEtaImmiland(false)
				} else if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'si') {
					handleChange('validEta', true)
					setShowBiometricSelect(false)
					//  handleChange('hasBiometrics', false)
					setShowEtaImmiland(true)
				}
			}
		} else {
			setUSVisa(false)
		}
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}




	return (
		<div className='m-4'>
			<Box>
				<>
					<form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
						<div className='flex flex-wrap -sm:mx-3 mb-6'>
							{/* start page 3 */}
							{step === 3 ?
								<div className='container-familiar'>
									<div className='flex flex-wrap justify-center'>
										<BoxModal>
											<Label>Seleccione el país emisor de su pasaporte</Label>
											<div >
												<CountrySelector
													handleChange={(e: any) => {
														{ e.target.value != '' ? handleChange('passportCountry', e.target.value) : handleChange('passportCountry', undefined) }
														validateEtaCountry(e.target.value)
														{ e.target.value != '' && currentMember.type === FamilyTypes.principal ? handleChange('countryOfCitizenship', e.target.value) : null }
													}}
													value={currentMember.passportCountry || ''}
													label='Seleccione el país emisor de su pasaporte'
												/>
											</div>
											{!currentMember.passportCountry && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
												Debe seleccionar el pais emisor  de su pasaporte
											</span>}
										</BoxModal>
										<BoxModal >
											<Label>Provincia de destino:</Label>
											<ProvinceSelector className='custom-input'
												handleChange={(e: any) => {
													{ e.target.value != '' ? handleChange('province', e.target.value) : handleChange('province', undefined) }
												}}
												value={currentMember.province || ''}
												label='Provinces'
											/>
											{!currentMember.province && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe seleccionar una provincia
											</span>}
										</BoxModal>
									</div>
									<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
										<Button
											type='button'
											onClick={() => setStep(2)}
											className='backbutton-disc2'>
											Atras
										</Button>
										<Button
											className='startbutton-disc2'
											type="button"
											onClick={() => {
												if (!currentMember.province || !currentMember.passportCountry) {
													setDisplayErrors(true);
												} else {
													setDisplayErrors(false);
													setStep(4);
												}
											}}>
											Siguiente
										</Button>
									</div>
									{/* end page 3 */}
								</div> : null}

							{/* start page 4 */}
							{step === 4 ? <>
								<div>
												  <div>
													<div className='titledivquestions'>
													  <h1>¿Qué proceso necesita?</h1>
													</div>
													<div className='containerbackandnextbuttons flex flex-wrap gap-8'>
													  <div>
														<button
														  className='rcorners2'
														  onClick={() => {
															
															handleChange('typeOfApplication', 'Pre Mandamus Demand Letter')
															setStep(5)
														  }}
														>
														  <p>Pre Mandamus Demand Letter</p>
														</button>
													  </div>
													</div>
												  </div>
												  <div
													style={{
													  display: 'flex',
													  marginBottom: '60px',
													  marginTop: '60px',
													  paddingBottom: '50px',
													  justifyContent: 'space-evenly',
													}}
												  >
													<Button
													  onClick={() => {
														handleChange('typeOfApplication', 'Otros Servicios')
														setStep(2) // Change step
													  }}
													  className='backbutton-disc2'
													>
													  Atras
													</Button>
												  </div>
												</div>
							</> : null}
							{/* end page 4 */}

							{step === 5 && <PersonalData closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />}
						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default PreMandamusOut
