/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React, { useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span, TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'

interface IProps {
	products: Product[]
}
const CalculateDependentOtherPrFederal: React.FC<IProps> = ({
	products
}) => {
	const OWPMAinAplicant = products.find(element => element.contratationProductId == "142")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers, setdepedantotherPRFederal, getdepedantotherPRFederal, principalUsed } =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	})
	let countwpFee = 0
	const arrayOfMembers: any[] = []
	familyMembers.map((member) => {

		if (principalUsed()?.typeOfApplication === "Otros programas de residencia permanente" &&
			member.type === FamilyTypes.children
		) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.rpcsq === true && member.type === FamilyTypes.children) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.rpcsqPEQ === true && member.type === FamilyTypes.children) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
		if (principalUsed()?.prendorsement === true && member.type === FamilyTypes.children) {
			countwpFee++
			const memberInfo = {
				"memberName": member.name + " " + member.lastname,
				"memberType": member.type
			}
			arrayOfMembers.push(memberInfo)
		}
	})
	if (OWPMAinAplicant !== undefined) {
		console.log("dentrooo",countwpFee)
		setdepedantotherPRFederal({
			id: OWPMAinAplicant.id,
			productContratationId: OWPMAinAplicant.contratationProductId,
			description: OWPMAinAplicant.description,
			totalFee: countwpFee * OWPMAinAplicant.fee,
			name: OWPMAinAplicant.name,
			quantity: countwpFee,
			fee: OWPMAinAplicant.price,
			memberInfo: arrayOfMembers,
			type: 'pf'

		})
	}
	return (
		<>
		
			{getdepedantotherPRFederal().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getdepedantotherPRFederal().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span>{getdepedantotherPRFederal().description}
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span> <Description>{getdepedantotherPRFederal().quantity}</Description>
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getdepedantotherPRFederal().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculateDependentOtherPrFederal)
