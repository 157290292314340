/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
	Box,
	Select,
} from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useRef } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import {
	FileUploadSelectEvent,
} from 'primereact/fileupload'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import { validateGeneral } from '../../utils/validateGeneral'
import { validateVisitor } from '../../utils/validateVisitor'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import PersonalData from './PersonalData'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}
const Visitor: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
		familyMembers
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [etaCountry, setEtaCountry] = React.useState(currentMember.etaCountry)
	const [usVisa, setUSVisa] = React.useState(false)
	const [showEtaImmiland, setShowEtaImmiland] = React.useState(false)

	const [emailForUploaders, setEmailForUploaders] = React.useState('')

	const fileUploadRef = useRef(null)
	const [displayErrors, setDisplayErrors] = React.useState(false)

	const [isMexicanPassport, setIsMexicanPassport] = React.useState(false)
	const [upTpSixMonths, setUpTpSixMonths] = React.useState('')

	const countriesWithETACriteria = [
		'antigua and barbuda',
		'argentina',
		'brazil',
		'costa rica',
		'mexico',
		'morocco',
		'panama',
		'philippines',
		'st. kitts and nevis',
		'st. lucia',
		'st. vincent and the grenadines',
		'seychelles',
		'thailand',
		'trinidad and tobago',
		'uruguay'
	]
	

	useEffect(() => { }, [currentMember])

	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])

	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])

	useEffect(() => { }, [open])

	useEffect(() => { }, [principalUsed])

	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const validateEtaCountry = (nationality: string) => {
		const isEtaCountry = ETA.find(
			(etas) => etas.country.toLocaleLowerCase() === nationality.toLocaleLowerCase()
		)
		setEtaCountry(false)
		setShowEtaImmiland(false)
		handleChange('etaCountry', false)
		handleChange('validEta', false)
		if (isEtaCountry) {
			handleChange('etaCountry', true)
			handleChange('validEta', true)
			setEtaCountry(true)
		}
		validateBrazil()
	}

	const validateBrazil = () => {
		if (currentMember.nationality.toLocaleLowerCase() === 'brazil') {
			setUSVisa(true)
			if (currentMember.etaRejection === 'si') {
				handleChange('validEta', false)
				setShowBiometricSelect(true)
				setShowEtaImmiland(false)
			} else {
				if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'no') {
					handleChange('validEta', false)
					setShowBiometricSelect(true)
					setShowEtaImmiland(false)
				} else if (currentMember.etaRejection === 'no' && currentMember.visaEU === 'si') {
					handleChange('validEta', true)
					setShowBiometricSelect(false)
					//  handleChange('hasBiometrics', false)
					setShowEtaImmiland(true)
				}
			}
		} else {
			setUSVisa(false)
		}
	}

	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}




	return (
    <div className='m-4'>
      <Box>
        <>
          <form className='w-full' id='fromEl' style={{fontFamily: 'Lato, sans-serif'}}>
            <div className='flex flex-wrap -sm:mx-3 mb-6'>
              {/* start page 3 */}
              {step === 3 ? (
                <div className='container-familiar'>
                  <div className='flex flex-wrap justify-center'>
                    <BoxModal>
                      <Label>Seleccione el país emisor de su pasaporte</Label>
                      <div>
                        <CountrySelector
                          handleChange={(e: any) => {
                            const value = e.target.value
                            handleChange('passportCountry', value !== '' ? value : undefined)
                            validateEtaCountry(value)
                            if (value !== '' && currentMember.type === FamilyTypes.principal) {
                              handleChange('countryOfCitizenship', value)
                            }
                            setIsMexicanPassport(
                              countriesWithETACriteria.includes(value.toLowerCase())
                            )
                          }}
                          value={currentMember.passportCountry || ''}
                          label='Seleccione el país emisor de su pasaporte'
                        />
                      </div>
                      {!currentMember.passportCountry && displayErrors === true && (
                        <span
                          className='text-tomato-500 text-xs italic'
                          style={{marginTop: '-11px', marginBottom: '11px'}}
                        >
                          Debe seleccionar el pais emisor de su pasaporte
                        </span>
                      )}
                    </BoxModal>
                    <BoxModal>
                      <Label>Provincia de destino:</Label>
                      <ProvinceSelector
                        className='custom-input'
                        handleChange={(e: any) => {
                          {
                            e.target.value != ''
                              ? handleChange('province', e.target.value)
                              : handleChange('province', undefined)
                          }
                        }}
                        value={currentMember.province || ''}
                        label='Provinces'
                      />
                      {!currentMember.province && displayErrors === true && (
                        <span
                          className='text-tomato-500 text-xs italic'
                          style={{marginTop: '-11px', marginBottom: '11px'}}
                        >
                          Debe seleccionar una provincia
                        </span>
                      )}
                    </BoxModal>
                    <BoxModal>
                      <Label>
                        ¿Tiene hijos o nietos residentes permanentes o ciudadanos canadienses?
                      </Label>
                      <Select
                        className='custom-input'
                        name='Tiene un hijo(s) o nieto(s) que sea(n) residente(s) permanente(s) o ciudadano(s) canadiense(s)?'
                        defaultValue={currentMember.canadianChildren}
                        style={{backgroundColor: '#fff2f3 '}}
                        labelId='demo-simple-select-label'
                        label='Seguro privado'
                        onChange={(e: any) => {
                          {
                            e.target.value != ''
                              ? handleChange('canadianChildren', e.target.value)
                              : handleChange('canadianChildren', undefined)
                          }
                        }}
                      >
                        <option value=''>Seleccione</option>
                        <option value='si'>Si</option>
                        <option value='no'>No</option>
                      </Select>
                      {!currentMember.canadianChildren && displayErrors === true && (
                        <span
                          className='text-tomato-500 text-xs italic'
                          style={{marginTop: '-11px', marginBottom: '11px'}}
                        >
                          Debe responder si Tiene hijos o nietos que sean residente permanente o
                          ciudadano canadiense
                        </span>
                      )}
                    </BoxModal>

                    {isMexicanPassport && (
                      <>
                        <BoxModal>
                          <Label>
                            ¿Ha tenido una visa de visitante canadiense en los últimos 10 años o
                            posee una visa de visitante americana válida y planea ingresar a Canadá
                            por vía aérea?
                          </Label>
                          <div className='relative'>
                            <Select
                              name='¿Cumple con los siguientes criterios?'
                              defaultValue={currentMember.havePreviousUSAVisa}
                              className='custom-input'
                              labelId='demo-simple-select-label'
                              label='Procesos en el pasado'
                              value={currentMember.havePreviousUSAVisa}
                              onChange={(e: any) => {
                                handleChange(
                                  'havePreviousUSAVisa',
                                  e.target.value !== '' ? e.target.value : undefined
                                )
                              }}
                            >
                              <option value=''>Seleccione</option>
                              <option value='si'>Si</option>
                              <option value='no'>No</option>
                            </Select>
                          </div>
                          {!currentMember.havePreviousUSAVisa && displayErrors === true && (
                            <span
                              className='text-tomato-500 text-xs italic'
                              style={{marginTop: '-11px', marginBottom: '11px'}}
                            >
                              Debe seleccionar si cumple con los criterios mencionados
                            </span>
                          )}
                        </BoxModal>
                      </>
                    )}
                  </div>
                  <div
                    className='button-container-disc2'
                    style={{
                      display: 'flex',
                      width: '100%',
                      gap: '3rem',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}
                  >
                    <Button type='button' onClick={() => setStep(2)} className='backbutton-disc2'>
                      Atras
                    </Button>
                    <Button
                      className='startbutton-disc2'
                      type='button'
                      onClick={() => {
                        const missingBasicFields =
                          !currentMember.canadianChildren ||
                          !currentMember.province ||
                          !currentMember.passportCountry

                        const missingMexicoQuestions =
                          isMexicanPassport && !currentMember.havePreviousUSAVisa

                        if (missingBasicFields || missingMexicoQuestions) {
                          setDisplayErrors(true)
                          return
                        }

                        setDisplayErrors(false)

                        // Si es pasaporte mexicano, aplicamos la lógica de bifurcación
                        if (isMexicanPassport) {
                          if (currentMember.havePreviousUSAVisa === 'si') {
                            setStep(5)
                            handleChange(
                              'typeOfApplication',
                              'Electronic Travel Authorization - eTA'
                            )
                          } else {
                            setStep(4)
                          }
                        } else {
                          // Para otros países, seguimos al paso 4 como antes
                          setStep(4)
                        }
                      }}
                    >
                      Siguiente
                    </Button>
                  </div>
                  {/* end page 3 */}
                </div>
              ) : null}

              {/* start page 4 */}
              {step === 4 ? (
                <>
                  <div>
                    <div>
                      <div className='titledivquestions'>
                        <h1>Seleccione una de las siguientes opciones</h1>
                      </div>
                      <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                        {currentMember.etaCountry === true && (
                          <div className='flex flex-col'>
                            <button
                              type='button'
                              className='rcorners2'
                              onClick={() => {
                                setStep(5)
                                handleChange(
                                  'typeOfApplication',
                                  'Electronic Travel Authorization - eTA'
                                )
                              }}
                            >
                              Electronic Travel Authorization - eTA
                            </button>
                            {/* <button type='button' className='button-text' onClick={popupDisplayeTa} >¿Quién puede aplicar? </button> */}
                          </div>
                        )}
                        {currentMember.etaCountry === false && (
                          <div className='flex flex-col'>
                            <button
                              type='button'
                              className='rcorners2'
                              onClick={() => {
                                setStep(6)
                                handleChange('typeOfApplication', 'Visa de visitante')
                              }}
                            >
                              Visa de visitante
                            </button>
                            {/* <button type='button' className='button-text' onClick={popupDisplayVisitor} >¿Quién puede aplicar? </button> */}
                          </div>
                        )}
                        {currentMember.etaCountry === false && (
                          <div className='flex flex-col'>
                            <button
                              type='button'
                              className='rcorners2'
                              onClick={() => {
                                setStep(5)
                                handleChange('typeOfApplication', 'Visa de tránsito por Canadá')
                              }}
                            >
                              Visa de tránsito
                            </button>
                            {/* 	<button type='button' className='button-text' onClick={popupDisplayTransit} >¿Quién puede aplicar? </button> */}
                          </div>
                        )}
                        {currentMember.canadianChildren === 'si' && (
                          <div className='flex flex-col'>
                            <button
                              type='button'
                              className='rcorners2'
                              onClick={() => {
                                setStep(5)
                                handleChange('typeOfApplication', 'Super visa')
                              }}
                            >
                              Super visa
                            </button>
                            {/* <button type='button' className='button-text' onClick={popupDisplaySuper} >¿Quién puede aplicar? </button> */}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '60px',
                        marginTop: '60px',
                        paddingBottom: '50px',
                      }}
                    >
                      <Button type='button' onClick={() => setStep(3)} className='backbutton-disc2'>
                        Atras
                      </Button>
                    </div>
                  </div>
                </>
              ) : null}
              {/* end page 4 */}

              {/* start page 3 */}
              {step === 6 ? (
                <div className='container-familiar'>
                  <div className='flex flex-wrap justify-center'>
                    <BoxModal>
                      <Label>¿Planea estudiar un curso de menos de 6 meses?</Label>
                      <Select
                        className='custom-input'
                        name='¿Planea estudiar un curso de menos de 6 meses?'
                        defaultValue='' // Cambiado el valor predeterminado a ''
                        style={{backgroundColor: '#fff2f3 '}}
                        labelId='demo-simple-select-label'
                        label='studen less 6 month'
                        onChange={(e: any) => {
                          const selectedValue = e.target.value
                          handleChange(
                            'lessThanSixMonths',
                            selectedValue !== '' ? selectedValue : undefined
                          )
                        }}
                      >
                        <option value='' disabled>
                          Seleccione
                        </option>
                        <option value='si'>Si</option>
                        <option value='no'>No</option>
                      </Select>
                      {!currentMember.lessThanSixMonths && displayErrors === true && (
                        <span
                          className='text-tomato-500 text-xs italic'
                          style={{marginTop: '-11px', marginBottom: '11px'}}
                        >
                          Debe responder si Planea estudiar un curso de menos de 6 meses?
                        </span>
                      )}
                    </BoxModal>
                  </div>
                  <div
                    className='button-container-disc2'
                    style={{
                      display: 'flex',
                      width: '100%',
                      gap: '3rem',
                      justifyContent: 'center',
                      marginTop: '2rem',
                    }}
                  >
                    <Button type='button' onClick={() => setStep(4)} className='backbutton-disc2'>
                      Atras
                    </Button>
                    <Button
                      className='startbutton-disc2'
                      type='button'
                      onClick={() => {
                        if (!currentMember.lessThanSixMonths) {
                          setDisplayErrors(true)
                        } else {
                          setDisplayErrors(false)
                          setStep(5)
                        }
                      }}
                    >
                      Siguiente
                    </Button>
                  </div>
                  {/* end page 3 */}
                </div>
              ) : null}
              {step === 5 && familyMembers[0].purposeVisit === 'Visitar o transito' && (
                <PersonalData
                  closeModal={() => closeModal()}
                  edit={edit}
                  step={step}
                  setStep={setStep}
                />
              )}
            </div>
          </form>
        </>
      </Box>
    </div>
  )
}
export default Visitor
