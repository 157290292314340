/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from 'Components'
import { FamilyTypes } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Button } from 'Components'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import frame from '../../../../../Components/Dialog/Frame.svg'
import arrow from '../../../../../Components/Dialog/arrow-left.svg'
import check from '../../../../../Components/Dialog/checkmark.svg'
import group from '../../../../../Components/Dialog/Group.svg'
import Swal from 'sweetalert2'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}


const OutsideCanada: React.FC<IProps> = ({ edit, step, setStep }) => {
	const rootStore = useContext(RootStoreContext)
	const {
		principalUsed,
		currentMember,
		handleChangeFamilyMember,
		getVisaTypeFromSurvey,
	} = rootStore.familyStore
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
		validateShowBiometrics()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => { }, [emailForUploaders])

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}

	const exitDisplayInfo = () => {
		Swal.fire({
		  html: `
				<div class="popup-container">
				  <div class="popup-header">
					<div class="popup-icon">
					  <div class="popup-image-wrapper">
						<img src=${group} alt="" />
					  </div>
					</div>
					<div class="popup-title">¿Está seguro que desea reiniciar su cotización?</div>
					<div class="popup-subtitle">Al hacerlo perderá los datos ingresados hasta ahora. Si desea modificar un dato ya ingresado es necesario iniciar el proceso nuevamente.</div>
				  </div>
				  <div class="popup-footer">
					<div class="popup-button-group">
					  <div id="backButton" class="popup-button back-button">
						<div class="popup-button-icon">
						  <img src=${arrow} alt="" />
						</div>
						<div class="popup-button-text">VOLVER</div>
					  </div>
					  <div id="confirmButton" class="popup-button confirm-button">
						<div class="popup-button-text">CONFIRMAR</div>
						<div class="popup-button-icon">
						  <img src=${check} alt="" />
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  `,
		  showCloseButton: true,
		  buttonsStyling: false,
		  showConfirmButton: false,
		  didOpen: () => {
			// Add event listeners for the buttons
			const backButton = document.getElementById('backButton')
			const confirmButton = document.getElementById('confirmButton')
	
			if (backButton) {
			  backButton.addEventListener('click', () => {
				console.log('Back button clicked')
				Swal.close() // Close the popup
			  })
			}
	
			if (confirmButton) {
			  confirmButton.addEventListener('click', () => {
				console.log('Confirm button clicked')
				Swal.close() // Close the popup
				window.location.reload()
			  })
			}
		  },
		})
	  }
	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			//it was visatype=before
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
	}
	const [selectedPurpose, setSelectedPurpose] = useState(null);
	const [isnextbuttonDisabled, setIsnextbuttonDisabled] = useState(true);
	const handleOptionChange = (value: any | React.SetStateAction<null>) => {
		setSelectedPurpose(value);
		setIsnextbuttonDisabled(value === null);
	};
	const scrollToTop = () => {
		window.scrollTo({
		  top: 0,
		  behavior: 'smooth'
		});
	  }
	const handleContinuarClick = () => {

		
		// Use the selectedPurpose to determine the next step
		if (selectedPurpose === "Visitar o transito") {
			handleChange('purposeVisit', "Visitar o transito");
		} else if (selectedPurpose === "Estudios") {
			handleChange('purposeVisit', "Estudios");
		} else if (selectedPurpose === "Trabajo") {
			handleChange('purposeVisit', "Trabajo");
		} else if (selectedPurpose === "Residencia Permanente") {
			handleChange('purposeVisit', "Residencia Permanente");
		} else if (selectedPurpose === "Otros Servicios") {
			handleChange('purposeVisit', "Otros Servicios");
		}
		// Set the step to 3
		setStep(3);
	};
	return (
		<div className='my-4 h-full bg-white'>
			<Box>
				<>
					<form className='w-full' id='fromEl'>
						<div className='flex flex-wrap mb-6'>
							{step === 2 && <> <div>
								<div className='titledivquestions'>
									<h1>¿Cuál es el propósito de su solicitud?</h1>
								</div>
								<div className='container-radio-select flex flex-wrap gap-8  flex-column items-center'>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Visitar o transito"
												checked={selectedPurpose === "Visitar o transito"}
												onChange={() => handleOptionChange("Visitar o transito")}
											/>
											<p>Visita o tránsito</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Estudios"
												checked={selectedPurpose === "Estudios"}
												onChange={() => handleOptionChange("Estudios")}
											/>
											<p>Estudios</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Trabajo"
												checked={selectedPurpose === "Trabajo"}
												onChange={() => handleOptionChange("Trabajo")}
											/>
											<p>Trabajo</p>
										</label>
									</div>
									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Residencia Permanente"
												checked={selectedPurpose === "Residencia Permanente"}
												onChange={() => handleOptionChange("Residencia Permanente")}
											/>
											<p>Residencia Permanente</p>
										</label>
									</div>

									<div>
										<label className="radio-selector">
											<input
												type="radio"
												value="Otros Servicios"
												checked={selectedPurpose === "Otros Servicios"}
												onChange={() => handleOptionChange('Otros Servicios')}
											/>
											<p>Otros servicios</p>
										</label>
									</div>

								</div>
								<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop:'2rem' }}>
									<Button 
									type='button'
									onClick={() => {
										console.log("aki toy");
										exitDisplayInfo();
									  }}
									 className='backbutton-disc2'>
										Reiniciar
									</Button>
									<Button
									type='button'
									onClick={() => {
										scrollToTop(); // Scroll to top
										handleContinuarClick(); // Change step
									  }}
									className={`startbutton-disc2 ${isnextbuttonDisabled ? 'button-disabled' : ''}`}
									disabled={isnextbuttonDisabled}>
										Continuar
									</Button>
								</div>
							</div>
							</>}
						</div>
					</form>
				</>
			</Box>
		</div>
	)
}
export default OutsideCanada
