/* eslint-disable no-empty-pattern */
import { FamilyTypes } from 'FormBuilder/components/FamilyModule/types'
import React,{ useContext } from 'react'
import { RootStoreContext } from 'stores/rootStore'
import { Span,TableCell } from 'Components'
import { observer } from 'mobx-react'
import { Product } from 'modules/flows/products/module'
import { Description } from 'Components/Tables/description'


interface IProps {
	products: Product[]
}

const CalculatePreMandamusPrior: React.FC<IProps> = ({
	products
}) => {
	const product = products.find(element => element.contratationProductId == "165")
	const rootStore = useContext(RootStoreContext)

	const { familyMembers,getPremandamus, setPremandamus} =
		rootStore.familyStore
	const {
	} = rootStore.familyStore
	const formatter = new Intl.NumberFormat('en-US',{
		style: 'currency',
		currency: 'USD',
	})
    const arrayOfMembers :any[]= []
	familyMembers.map((member) => {
console.log("entre acaaaa ",JSON.stringify(familyMembers))


	
		if (product !== undefined && member.typeOfApplication === "Pre Mandamus Demand Letter" 
			&& member.purposeVisit === "Pre Mandamus Prior"
		) {
		console.log("estoy por aca: producto: ", product)
		setPremandamus({
			id: product.id,
			productContratationId: product.contratationProductId,
			description: product.description,
			totalFee: product.fee,
			name: product.name,
			quantity: 1,
			fee: product.price,
			memberInfo:arrayOfMembers,
			type:'pf'
		})
		
	}
})

	return (
		<>
			{getPremandamus().quantity > 0 && (
				<>
					<TableCell>
						<Span style={{ fontWeight: 'bold' }}>Servicio</Span>
						{getPremandamus().name}
					</TableCell>
					<TableCell style={{ display: 'flex' }}>
						<Span>Descripcion</Span> <Description>{getPremandamus().description}</Description>
					</TableCell>
					<TableCell >
						<Span>Cantidad</Span>{getPremandamus().quantity}
					</TableCell>
					<TableCell className='border-b border-indigo-500'>
						<Span>Precio </Span> CAD {formatter.format(getPremandamus().totalFee)}
					</TableCell>
				</>
			)}
		</>
	)
}

export default observer(CalculatePreMandamusPrior)
