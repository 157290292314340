import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {Button, Link} from 'Components'
import gif from './Img/Time-line-02.gif'
import giflawhoresp from './Img/Horizontal Spanish.json'
import giflawveresp from './Img/Vertical Spanish.json'
import giflawhoreng from './Img/Horizontal English.json'
import giflawverengp from './Img/Vertical English.json'
import giflawhorfre from './Img/Horizontal French.json'
import giflawverfre from './Img/Vertical French.json'
import gif2 from './Img/gif-edit.gif'
import Player from 'lottie-react'
import './style/style.css'
interface IProps {
  setInfoDisclaimerStep: React.Dispatch<React.SetStateAction<number>>
}
const InfoDisclaimer: React.FC<IProps> = ({setInfoDisclaimerStep}) => {
  const [animationData, setAnimationData] = useState(giflawhoresp)
  const [videoSrc, setVideoSrc] = useState(
    'https://player.vimeo.com/video/781943402?h=f84975d09d?version=3&autoplay=1&controls=1&&showinfo=0&loop=1'
  )

  useEffect(() => {
    const updateContent = () => {
      if (typeof window !== 'undefined' && (window as any).Weglot) {
        const lang = (window as any).Weglot.getCurrentLang()
        const isMobile = window.innerWidth < 990

        if (lang === 'es') {
          setAnimationData(isMobile ? giflawveresp : giflawhoresp)
          setVideoSrc(
            'https://player.vimeo.com/video/781943402?h=f84975d09d&autoplay=1&controls=1&showinfo=0&loop=1'
          )
        } else if (lang === 'en') {
          setAnimationData(isMobile ? giflawverengp : giflawhoreng)
          setVideoSrc(
            'https://player.vimeo.com/video/1018330920?h=4547c76903&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1'
          )
        } else if (lang === 'fr') {
          setAnimationData(isMobile ? giflawverfre : giflawhorfre)
          setVideoSrc(
            'https://player.vimeo.com/video/1018458174?h=d928f7fdc3&title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1'
          )
        }
      }
    }

    updateContent()

    if (typeof window !== 'undefined' && (window as any).Weglot) {
      ;(window as any).Weglot.on('languageChanged', updateContent)
    }

    window.addEventListener('resize', updateContent)

    return () => {
      if (typeof window !== 'undefined' && (window as any).Weglot) {
        ;(window as any).Weglot.off('languageChanged', updateContent)
      }
      window.removeEventListener('resize', updateContent)
    }
  }, [])

  return (
    <>
      <div className='mt-5 mb-5 pl-5 pr-5' style={{fontFamily: 'Lato, sans-serif'}}>
        <div className='flex flex-col'>
          <h3 style={{fontSize: '2rem', color: '#000000', marginBottom:'10px', marginLeft: '5%'}}>
            <b>Cotice al instante</b>
          </h3>

          <div
            style={{width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5%'}}
          >
            <div
              className='frame-info'
              style={{
                backgroundColor: '#C7B299',
                paddingTop: '0px',
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
				borderRadius: '15px',
              }}
            >
              <iframe
                className='frame'
                src={`${videoSrc}&autoplay=1`}
                title='Vimeo video player'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                
				frameBorder='0'
              />
              <div
                className='info'
                style={{
                  color: 'white',
                  padding: '40px',
                  width: '30rem',
                  height: '27rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    fontWeight: '800',
                    marginBottom: '10px',
                    fontSize: '1.4rem',
                    color: '#000000',
                  }}
                >
                  Obtenga sus cotizaciones al instante
                </p>
                <p
                  style={{
                    fontWeight: '500',
                    marginBottom: '12px',
                    fontSize: '0.8rem',
                    color: '#000000',
                  }}
                >
                  Respondiendo algunas preguntas. Nuestro sistema identificará sus opciones
                  elegibles.
                </p>
                <p
                  style={{
                    fontWeight: '500',
                    marginBottom: '12px',
                    fontSize: '0.8rem',
                    color: '#000000',
                  }}
                >
                  Tendrá sus aplicaciones aproximadamente en (*) :
                </p>
                <div className='info-three'>
                  <p>
                    <img style={{width: '20px'}} src='/icons/graduation-hat-black.svg' alt='Home' />
                  </p>
                  <p
                    style={{
                      paddingLeft: '5px',
                      width: '198px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    Estudios y turismo
                  </p>
                  <p
                    style={{
                      paddingLeft: '30px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    15 días hábiles
                  </p>
                </div>
                <div className='info-three'>
                  <p>
                    <img style={{width: '20px'}} src='/icons/work-case-black.svg' alt='Home' />
                  </p>
                  <p
                    style={{
                      paddingLeft: '5px',
                      width: '198px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    Trabajo
                  </p>
                  <p
                    style={{
                      paddingLeft: '30px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    20 días hábiles
                  </p>
                </div>
                <div className='info-three'>
                  <p>
                    <img style={{width: '20px'}} src='/icons/home-black.svg' alt='Home' />
                  </p>
                  <p
                    style={{
                      paddingLeft: '5px',
                      width: '198px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    Residencia permanente
                  </p>
                  <p
                    style={{
                      paddingLeft: '30px',
                      fontSize: '0.7rem',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    30 días hábiles
                  </p>
                </div>
                <p
                  style={{
                    fontWeight: '700',
                    marginBottom: '20px',
                    fontSize: '0.6rem',
                    marginTop: '12px',
                    color: '#000000',
                  }}
                >
                  (*) Desde la recepción de todos los documentos esenciales para su aplicación.
                </p>
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '15px',
                    fontSize: '0.8rem',
                    color: '#000000',
                    fontWeight: 'bold',
                  }}
                >
                  ¡Simplificamos sus procesos!
                </p>
                <div style={{display: 'flex', justifyContent: 'end'}}>
                  <Button
                    className='startbutton-first-page'
                    onClick={() => {
                      setInfoDisclaimerStep(2)
                      scrollTo(0, 0)
                    }}
                  >
                    Comenzar
                    <img
                      src='/icons/arrowwhite.png'
                      alt='Home'
                      style={{width: '20px', height: '20px'}}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id='employer-section-container'>
          <div id='employer-section'>
            <a className='employer-heading'>¿ERES EMPLEADOR?</a>
            <div className='employer-content'>
              <a className='employer-description'>
                Obtenga permisos, asistencias y evaluaciones{' '}
                <strong>para trabajadores extranjeros.</strong>
              </a>
              <div className='employer-link-container'>
                <Link to='/employer'>
                  <a
                    className='employer-link'
                    onClick={() => {
                      setInfoDisclaimerStep(10)
                      scrollTo(0, 0)
                    }}
                  >
                    INGRESAR COMO EMPLOYER
                    <img src='/icons/arrow-top-right.svg' alt='Home' />
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Player
            animationData={animationData}
            className='lottie-container2'
            loop={false}
            autoplay
            style={{width: '100%', maxWidth: '1500px'}}
          />
        </div>
        <div
          style={{
            backgroundColor: '#F9FAFB',
            display: 'flex',
            paddingTop: '60px',
            paddingBottom: '120px',
            justifyContent: 'center',
            gap: '40px',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            {/* 					<p style={{ color: '#5B21B6', textAlign: 'center', marginBottom: '10px', fontSize:'24px' }}>Qué incluyen nuestros servicios:</p>
					<p style={{ textAlign: 'center' }}>-  Revición exhaustiva de documentos. </p>
					<p style={{ textAlign: 'center' }}>-  Estrategias para mejorar el perfil. </p>
					<p style={{ textAlign: 'center' }}>-  Redacción de cartas de motivos y presentación de fondos. </p>
					<p style={{ textAlign: 'center' }}>-  Envio de aplicaciones. </p>
					<p style={{ textAlign: 'center' }}>-  Monitoreo de la aplicación. </p>
					<p style={{ textAlign: 'center' }}>-  Notificación de comunicaciones. </p>
					<p style={{ textAlign: 'center', marginBottom:'30px' }}>-  De ser necesario, comunicación directa con el Gobierno de Canadá. </p> */}
            <p
              style={{color: '$beige', textAlign: 'center', marginBottom: '10px', fontSize: '20px'}}
            >
              Tabla de precios
            </p>
            <p style={{fontSize: '1.5rem', textAlign: 'center', marginBottom: '10px'}}>
              Ofrecemos precios alcanzables
            </p>
            <p style={{textAlign: 'center'}}>
              Elija el servicio que necesite y pague solo una vez.{' '}
            </p>
            <p style={{textAlign: 'center'}}>
              Nuestros valores se ajustan adaptadonos a sus necesidades y las de sus acompañantes.{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            <div
              style={{
                padding: '20px 20px 40px 20px',
                border: '2px solid #DFE4EA',
                borderRadius: '10px',
                backgroundColor: '#fafafa',
                width: '256px',
              }}
            >
              <p style={{marginBottom: '20px', color: '#000000', display: 'flex', gap: '10px'}}>
                <img
                  src='/icons/location.svg'
                  style={{width: '20px', height: '21px', fontWeight: 'bold'}}
                  alt='Home'
                />{' '}
                VISITANTES
              </p>
              <p style={{marginBottom: '36px '}}>
                <span>Desde </span>
                <span style={{fontSize: '2rem', marginLeft: '12px'}}> $600</span>{' '}
              </p>
              <p
                style={{
                  borderBottom: '1px solid black',
                  marginBottom: '10px ',
                  paddingBottom: '20px',
                  fontSize: '0.8rem',
                }}
              >
                Ejemplo{' '}
              </p>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Eta</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Visa de turismo</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Super visa</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '10px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img
                    src='/icons/arrow-right-bp2.png'
                    style={{width: '20px', height: '21px', fontWeight: 'bold'}}
                    alt='Home'
                  />{' '}
                </span>
                <span style={{fontSize: '0.8rem'}}>Visa de transito</span>{' '}
              </div>
            </div>
            <div
              style={{
                padding: '20px 20px 40px 20px',
                border: '2px solid #DFE4EA',
                borderRadius: '10px',
                backgroundColor: '#fafafa',
                width: '256px',
              }}
            >
              <p style={{marginBottom: '20px', color: '#000000', display: 'flex', gap: '10px'}}>
                <img
                  src='/icons/graduation-hat.svg'
                  style={{width: '20px', height: '21px', fontWeight: 'bold'}}
                  alt='Home'
                />{' '}
                ESTUDIANTES
              </p>
              <p style={{marginBottom: '36px '}}>
                <span>Desde </span>
                <span style={{fontSize: '2rem', marginLeft: '12px'}}> $2500</span>{' '}
              </p>
              <p
                style={{
                  borderBottom: '1px solid black',
                  marginBottom: '20px ',
                  paddingBottom: '20px',
                  fontSize: '0.8rem',
                }}
              >
                Ejemplo{' '}
              </p>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Permisos de estudio</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>CAQ</span>{' '}
              </div>
            </div>
            <div
              style={{
                padding: '20px 20px 40px 20px',
                border: '2px solid #DFE4EA',
                borderRadius: '10px',
                backgroundColor: '#fafafa',
                width: '256px',
              }}
            >
              <p style={{marginBottom: '20px', color: '#000000', display: 'flex', gap: '10px'}}>
                <img
                  src='/icons/work-case.svg'
                  style={{width: '20px', height: '21px', fontWeight: 'bold'}}
                  alt='Home'
                />{' '}
                TRABAJADORES
              </p>
              <p style={{marginBottom: '36px '}}>
                <span>Desde </span>
                <span style={{fontSize: '2rem', marginLeft: '12px'}}> $2700</span>{' '}
              </p>
              <p
                style={{
                  borderBottom: '1px solid black',
                  marginBottom: '20px ',
                  paddingBottom: '20px',
                  fontSize: '0.8rem',
                }}
              >
                Ejemplo{' '}
              </p>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>PGWP</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Permisos de trabajo</span>{' '}
              </div>
            </div>
            <div
              style={{
                padding: '20px 20px 40px 20px',
                border: '2px solid #DFE4EA',
                borderRadius: '10px',
                backgroundColor: '#fafafa',
                width: '256px',
              }}
            >
              <p style={{color: '#000000', display: 'flex', gap: '10px'}}>
                <img src='/icons/home.svg' alt='Home' style={{width: '24px', height: '25px'}} />
                RESIDENCIA PERMANENTE
              </p>
              <p style={{marginBottom: '36px '}}>
                <span>Desde </span>
                <span style={{fontSize: '2rem', marginLeft: '12px'}}> $3000</span>{' '}
              </p>
              <p
                style={{
                  borderBottom: '1px solid black',
                  marginBottom: '20px ',
                  paddingBottom: '20px',
                  fontSize: '0.8rem',
                }}
              >
                Ejemplo{' '}
              </p>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Express entry</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Sponsorship</span>{' '}
              </div>
              <div
                style={{
                  display: 'flex',
                  paddingBottom: '14px',
                  fontSize: '0.8rem',
                  marginLeft: '12px',
                }}
              >
                <span>
                  <img src='/icons/arrow-right-bp2.png' alt='Home' />{' '}
                </span>
                <span>Programas provinciales y federales</span>{' '}
              </div>
            </div>
          </div>
          <div className='blue-div'>
  <div className='blue-important'>
    <p>(*) IMPORTANTE: Los precios publicados no incluyen lo siguiente:</p>
    <div className='blue-services'>
      <div>
        <p>
          <strong>1. Servicios y desembolsos adicionales opcionales</strong>
          Servicios o gastos adicionales que excedan el alcance estándar del trabajo.
        </p>
        <p>
          <strong>2. Honorarios por circunstancias especiales</strong>
          Cargos aplicables para situaciones como la restauración del estatus o la tramitación interna acelerada.
        </p>
        <p>
          <strong>3. Tasas gubernamentales</strong>
          Tarifas exigidas por las autoridades federales, provinciales o territoriales.
        </p>
        <p>
          <strong>4. Impuestos aplicables</strong>
          Impuestos provinciales y federales según lo exijan.
        </p>
      </div>
    </div>
  </div>
</div>

        </div>
        <footer className='footer-style'>
          <div className='footer-style-continer'>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>¿Quienes somos?</strong>
              </h3>
              <a href='https://www.immilandcanada.com/quienes-somos'>Historia y Valores</a>
              <a href='https://www.immilandcanada.com/equipo-immiland'>Nuestro Equipo</a>
              <a href='https://www.immilandcanada.com/testimonios'>Testimonios</a>
              <a href='https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#'>
                Preguntas Frecuentes
              </a>
              <a href='https://www.immilandcanada.com/terminos-y-condiciones'>
                <strong>Términos y condiciones.</strong>
              </a>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Servicios</strong>
              </h3>
              <a href='https://www.immilandcanada.com/migracion/inicio'>Migración</a>
              <a href='https://www.immilandcanada.com/reclutamiento/inicio'>Trabajo</a>
              <a href='https://www.immilandcanada.com/educacion/inicio'>Educacion</a>
              <a href='https://www.immilandcanada.com/traduccion/inicio'>Traducciones</a>
              <a href='https://www.immilandcanada.com/establecimiento/inicio'>Establecimiento</a>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Ubicación</strong>
              </div>
              <div>
                <a href='https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share'>
                  <div>
                    2025 Rue Parthenais #320
                    <br />
                    Montreal, Quebec
                    <br />
                    H2K 3T2, Canadá
                  </div>
                </a>
              </div>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                <strong>Blog</strong>
              </h3>
              <div>
                <a href='https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio'>
                  <div>
                    ¿Puedo viajar fuera <br />
                    de Canada mientras estudio?
                  </div>
                </a>
              </div>
            </div>
            <div
              style={{
                width: '10rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div>
                <h3 style={{color: 'white', marginBottom: '1rem', fontSize: '1.1rem'}}>
                  <strong>¡Sigamos en contacto!</strong>
                </h3>
                <div>
                  <span>Info@immilandcanada.com</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    paddingRight: '2rem',
                    alignItems: 'center',
                  }}
                >
                  <a href='https://www.youtube.com/immiland'>
                    <img
                      src='https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png'
                      alt=''
                    />
                  </a>
                  <a href='https://www.instagram.com/immilandcanada/'>
                    <img src='https://img.icons8.com/windows/25/ffffff/instagram-new.png' alt='' />
                  </a>
                  <a href='https://www.facebook.com/immilandcanada'>
                    <img
                      src='https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png'
                      alt=''
                    />
                  </a>
                  <a href='https://www.tiktok.com/@immilandcanada'>
                    <img src='https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png' alt='' />
                  </a>
                  <a href='https://ca.linkedin.com/company/immiland-canada'>
                    <img
                      src='https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png'
                      alt=''
                    />
                  </a>
                  <a href='https://twitter.com/immilandcanada'>
                    <img src='https://img.icons8.com/material/25/ffffff/twitter--v2.png' alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

export default observer(InfoDisclaimer)
