/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Input, Select
} from 'Components'
import React, { Dispatch, SetStateAction, useContext, useEffect } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import Swal from 'sweetalert2'
import '../../styles/uploadersStyle.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../styles/index.css'
import { ProvinceSelector } from 'Components/ProvinceSelector'
import '../../../../../Components/Modal/modal.css'
import PersonalDataPermanentRecidence from './PersonalDataPermanentResidence'

interface IProps {
  closeModal: () => void
  edit: boolean
  step: number
  setStep: Dispatch<SetStateAction<number>>
}
const PermanentRecidence: React.FC<IProps> = ({ closeModal, edit, step, setStep }) => {
  const rootStore = useContext(RootStoreContext)
  const {
    principalUsed,
    currentMember,
    handleChangeFamilyMember,
  } = rootStore.familyStore
  const [birthday, setBirthday] = React.useState(currentMember.birthday)
  const [emailForUploaders, setEmailForUploaders] = React.useState('')
  const [displayErrors, setDisplayErrors] = React.useState(false)
  const [open, setOpen] = React.useState(true)

  useEffect(() => { }, [currentMember])
  useEffect(() => {
    if (currentMember.age > -1) handleChange('valid', true)
    handleChange('age', moment().diff(birthday, 'years', false))
  }, [birthday])
  useEffect(() => { }, [open])
  useEffect(() => { }, [principalUsed])
  useEffect(() => { }, [emailForUploaders])

  const handleChange = (
    target: string,
    value: string | number | Date | File | boolean | undefined
  ) => {
    //const newFamily = {...family}
    const newFamily = Object.assign(currentMember, { [target]: value })
    handleChangeFamilyMember(newFamily)
  }

  const popupSkillWorkers = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p>Este programa requiere que usted posea: </p>' +
        '<p style=" text-align: left">🔸CELPIP o IELTs de mínimo CLB 7 o superior (ideal). </p>' +
        '<p style=" text-align: left">🔸Education Credential Assessment (ECA)</p>' +
        '<p style=" text-align: left">🔸Fondos de asentamiento: </p>' +
        '<a style="color: red; text-decoration: underline;"  target="_blank" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/documents/proof-funds.html</a>' +
        '<p style=" text-align: left">🔸Experiencia laboral comprobable en ocupaciones calificadas de mínimo 1 año en los últimos 10 años. Tener por lo menos 3 años de experiencia laboral calificada es ideal</p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
   customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
    })
  }

  const popupCanadianExperienceClass = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p>Este programa requiere que usted posea: </p>' +
        '<p style=" text-align: left">🔸CELPIP o IELTs de mínimo CLB 7 o superior (ideal). </p>' +
        '<p style=" text-align: left">🔸Education Credential Assessment (ECA) y/o diploma académico post secundario Canadiense.' +
        '<p style=" text-align: left">🔸1 año de experiencia con empleador Canadiense comprobable en una ocupación calificada. </p>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
   customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
    })
  }

  const popupFederalSkilledTradeClass = () => {
    Swal.fire({
      title: '<span style="color: #13C296;">Importante</span>',
      icon: 'info',
      html:
        '<p>Este programa tiene varios requisitos importantes incluyendo que ya posea una oferta de empleo con empleador Canadiense,  por favor revisar el siguiente link y asegurarse de que los cumpla antes de continuar:</p>' +
        '<a style="color: red; text-decoration: underline;"  target="_blank" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/skilled-trades.html">https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/skilled-trades.html</a>',
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'Entendido!',
   customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
    })
  }

  return (
    <>
      {/* start Step 3 */}
      {step === 3 ? (
        <div>
          <div className='container-familiar'>
            <div>
              <div className='titledivquestions'>
                <h1>Seleccione una de las siguientes opciones</h1>
              </div>
              <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(4)
                      handleChange('typeOfApplication', 'Patrocinio familiar')
                    }}
                  >
                    <img src='/icons/patrocinio.png' alt='Icono' style={{width: '60px'}} />
                    <p>Apadrinamiento familiar</p>
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(4)
                      handleChange('typeOfApplication', 'Express Entry')
                    }}
                  >
                    <img src='/icons/express.png' alt='Icono' style={{width: '60px'}} />
                    <p>Express Entry</p>
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(6)
                      handleChange('typeOfApplication', 'PNP')
                    }}
                  >
                    <img src='/icons/express.png' alt='Icono' style={{width: '60px'}} />
                    <p>Nominación provincial (PNP) o Quebec</p>
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignContent: 'center',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(5)
                      handleChange('typeOfApplication', 'Otros programas de residencia permanente')
                    }}
                  >
                    <img src='/icons/express.png' alt='Icono' style={{width: '60px'}} />
                    <div>
                    <p>Otros programas federales como:</p>
                    <div style={{textAlign:'initial'}}>
                    <p>-Programa de Caregives</p>
                    <p>-Pilotos para zonas rurales</p>
                    <p>-Pilotos para comunidades</p>
                    <p>-Otros programas piloto:</p>
                    </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '4rem',
              }}
            >
              <Button onClick={() => setStep(2)} className='backbutton-disc2'>
                Atras
              </Button>
            </div>
          </div>
          
        </div>
      ) : null}
      {/* end Step 3 */}

      {/* start Step 4 */}
      {step === 4 && currentMember.typeOfApplication === 'Patrocinio familiar' ? (
        <>
          <div className='container-familiar'>
            <div>
              <div className='titledivquestions'>
                <h1>¿Cuál es su vínculo familiar con el patrocinador?</h1>
              </div>
              <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(6)
                      handleChange('sponsorship', 'spouseOrPartner')
                    }}
                  >
                    <img src='/icons/pareja.png' alt='Icono' style={{width: '60px'}} />
                    <p style={{textAlign: 'start'}}>Cónyuge o pareja de hecho.</p>
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(6)
                      handleChange('sponsorship', 'parents')
                    }}
                  >
                    <img src='/icons/familia.png' alt='Icono' style={{width: '60px'}} />
                    <p>Padre/madre </p>
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    style={{
                      width: '20rem',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                    onClick={() => {
                      setStep(6)
                      handleChange('sponsorship', 'minorchildren')
                    }}
                  >
                    <img src='/icons/familia.png' alt='Icono' style={{width: '60px'}} />
                    <p>Hijo menor de 22 años</p>
                  </button>
                </div>
              </div>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
            </div>
          </div>
        </>
      ) : null}

      {step === 4 && currentMember.typeOfApplication === 'Express Entry' ? (
        <>
          <div>
            <div>
              <div className='titledivquestions'>
                <h1>Elija el programa que le aplica:</h1>
              </div>
              <div className='containerbackandnextbuttons flex flex-wrap gap-8'>
                <div>
                  <button
                    className='rcorners2'
                    onClick={() => {
                      popupSkillWorkers()
                      setStep(6)
                      handleChange('programAplication', 'skill worker program')
                    }}
                  >
                    Federal Skilled Worker Program
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    onClick={() => {
                      popupCanadianExperienceClass()
                      setStep(6)
                      handleChange('programAplication', 'canadian experience class')
                    }}
                  >
                    Canadian Experience Class
                  </button>
                </div>
                <div>
                  <button
                    className='rcorners2'
                    onClick={() => {
                      popupFederalSkilledTradeClass()
                      setStep(6)
                      handleChange('programAplication', 'federal skilled trades class')
                    }}
                  >
                    Federal Skilled Trades Class
                  </button>
                  <b className='bottoninfo'></b>
                </div>
              </div>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '2rem',
              }}
            >
              <div
            style={{
              width: '80%',
              fontSize: '17px',
              backgroundColor: '#fff3cd', 
              color: '#856404', // Color de texto oscuro para contraste
              marginTop: '40px',
              fontWeight: 'bold',
              alignItems: 'center',
              textAlign: 'justify',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'center',
              padding: '15px',
              borderRadius: '8px',
              border: '1px solid #ffeeba', // Borde sutil
              boxShadow: '2px 2px 10px rgba(0,0,0,0.1)', // Sombra ligera
            }}
          >
            <p style={{maxWidth: '80%'}}>
              <strong>Nota:</strong> <br /><br />
              Para hacerse elegible a las categorías de Express Entry (Francófona, STEM, Salud,
              Educación, etc), primero debe ser elegible a uno de los 3 programas mencionados más
              arriba. <br /><br />
              Immiland Law, luego de una evaluación de su perfil, le ayudará a determinar si su
              perfil es elegible a categorización una vez se trabaje en su caso.<br /><br />
              Para continuar, por favor seleccione el programa principal al cual es
              elegible.
            </p>
          </div>
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              
            </div>
          </div>
        </>
      ) : null}

      {step === 4 && currentMember.typeOfApplication === 'RP nominación provincial' ? (
        <>
          <div>
            <div
              className='flex flex-wrap mb-6 justify-center'
              style={{fontFamily: 'Lato, sans-serif', padding: '30px'}}
            >
              <BoxModal>
                <Label>Provincia de destino:</Label>
                <ProvinceSelector
                  className='custom-input'
                  handleChange={(e: any) => {
                    {
                      e.target.value != ''
                        ? handleChange('province', e.target.value)
                        : handleChange('province', undefined)
                    }
                  }}
                  value={currentMember.province || ''}
                  label='Provinces'
                />
                {!currentMember.province && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe seleccionar una provincia
                  </span>
                )}
              </BoxModal>
              <BoxModal>
                <Label>Por favor indique el programa provincial que desee aplicar.</Label>
                <Input
                  name='Programa a aplicar'
                  style={{backgroundColor: '#fff2f3 '}}
                  label='Programa a aplicar'
                  placeholder='Programa a aplicar'
                  type='text'
                  value={currentMember.programAplicationExplain}
                  onChange={(e: any) => {
                    {
                      e.target.value != ''
                        ? handleChange('programAplicationExplain', e.target.value)
                        : handleChange('programAplicationExplain', undefined)
                    }
                  }}
                  size='small'
                />
                {!currentMember.programAplicationExplain && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe indicar el programa provincial que desee aplicar.
                  </span>
                )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.province || !currentMember.programAplicationExplain) {
                    setDisplayErrors(true)
                  } else {
                    setDisplayErrors(false)
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        </>
      ) : null}

      {step === 4 && currentMember.typeOfApplication === 'RP nivel federal' ? (
        <>
          <div>
            <div
              className='flex flex-wrap mb-6 justify-center'
              style={{fontFamily: 'Lato, sans-serif', padding: '30px'}}
            >
              <BoxModal>
                <Label>Provincia de destino:</Label>
                <ProvinceSelector
                  className='custom-input'
                  handleChange={(e: any) => {
                    {
                      e.target.value != ''
                        ? handleChange('province', e.target.value)
                        : handleChange('province', undefined)
                    }
                  }}
                  value={currentMember.province || ''}
                  label='Provinces'
                />
                {!currentMember.province && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe seleccionar una provincia
                  </span>
                )}
              </BoxModal>
              <BoxModal>
                <Label>Por favor indique el programa que desee aplicar.</Label>
                <Input
                  name='Programa a aplicar'
                  style={{backgroundColor: '#fff2f3 '}}
                  label='Programa a aplicar'
                  placeholder='Programa a aplicar'
                  type='text'
                  value={currentMember.programAplicationExplain}
                  onChange={(e: any) => {
                    {
                      e.target.value != ''
                        ? handleChange('programAplicationExplain', e.target.value)
                        : handleChange('programAplicationExplain', undefined)
                    }
                  }}
                  size='small'
                />
                {!currentMember.programAplicationExplain && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe indicar el programa que desee aplicar
                  </span>
                )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.programAplicationExplain || !currentMember.province) {
                    setDisplayErrors(true)
                  } else {
                    setDisplayErrors(false)
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        </>
      ) : null}
      {/* end Step 4 */}
      {/* start step 5*/}
      {step === 5 &&
        (currentMember.typeOfApplication === 'Patrocinio familiar' ||
          currentMember.typeOfApplication === 'PNP' ||
          currentMember.typeOfApplication === 'Express Entry'||
          currentMember.typeOfApplication === 'Otros programas de residencia permanente') && (
          <>
            <PersonalDataPermanentRecidence
              closeModal={() => closeModal()}
              edit={edit}
              step={step}
              setStep={setStep}
            />
          </>
        )}
      {/*  {step === 5 && (currentMember.programAplication === 'skill worker program' || currentMember.programAplication === 'canadian experience class' || currentMember.programAplication === 'federal skilled trades class') && <>
      <PersonalDataPermanentRecidence closeModal={() => closeModal()} edit={edit} step={step} setStep={setStep} />
    </>} */}
      {/* end step 5*/}

      {/*step 6 */}
      {step === 6 &&
        (currentMember.sponsorship === 'parents' ||
          currentMember.typeOfApplication === 'Patrocinio familiar' ||
          currentMember.typeOfApplication === 'PNP') && (
          <div>
            <div
              className='flex flex-wrap mb-6 justify-center'
              style={{fontFamily: 'Lato, sans-serif'}}
            >
              <BoxModal>
                <Label>Provincia de destino:</Label>
                <ProvinceSelector
                  className='custom-input'
                  handleChange={(e: any) => {
                    {
                      e.target.value != ''
                        ? handleChange('province', e.target.value)
                        : handleChange('province', undefined)
                    }
                  }}
                  value={currentMember.province || ''}
                  label='Provinces'
                />
                {!currentMember.province && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe seleccionar una provincia
                  </span>
                )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.province) {
                    setDisplayErrors(true)
                  } else if (
                    currentMember.province !== 'Quebec' &&
                    currentMember.inCanada === 'si' &&
                    currentMember.sponsorship === 'spouseOrPartner'
                  ) {
                    setStep(8)
                  } else if (currentMember.province && currentMember.typeOfApplication === 'PNP') {
                    setStep(7)
                  } else {
                    if (currentMember.province !== 'Quebec') {
                      setStep(5)
                    } else {
                      setStep(7)
                    }
                    setDisplayErrors(false)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}

      {step === 7 &&
        currentMember.typeOfApplication === 'PNP' &&
        currentMember.province !== 'Quebec' && (
          <div>
            <div className='flex flex-wrap -mx-3 mb-6' style={{fontFamily: 'Lato, sans-serif'}}>
              <div>
                <div className='titledivquestions'>
                  <h1>¿A qué desea aplicar?</h1>
                </div>
                <div className='flex gap-8 w-full'>
                  <label className='radio-selector'>
                    <input
                      type='checkbox'
                      checked={currentMember.pn === true}
                      onChange={(e) => handleChange('pn', e.target.checked)}
                    />
                    <p style={{fontSize: '14px', marginLeft: '8px'}}>Nominación provincial </p>
                  </label>
                  <label className='radio-selector'>
                    <input
                      type='checkbox'
                      checked={currentMember.prendorsement === true}
                      onChange={(e) => {
                        handleChange('prendorsement', e.target.checked)
                      }}
                    />
                    <p style={{fontSize: '14px', marginLeft: '8px'}}>
                      Residencia permanente (cuenta ya con su nominación provincial/ endorsement)
                    </p>
                  </label>
                </div>
                {currentMember.prendorsement !== true &&
                  currentMember.pn !== true &&
                  displayErrors === true && (
                    <span
                      className='text-tomato-500 text-xs italic'
                      style={{marginTop: '-11px', marginBottom: '11px'}}
                    >
                      Debe seleccionar una opcion
                    </span>
                  )}
              </div>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (currentMember.prendorsement !== true && currentMember.pn !== true) {
                    setDisplayErrors(true)
                  } else {
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}
      {step === 6 && currentMember.typeOfApplication === 'Express Entry' && (
        <div>
          <div
            className='flex flex-wrap justify-center mb-6'
            style={{fontFamily: 'Lato, sans-serif'}}
          >
            <BoxModal>
              <Label>¿A qué desea aplicar? (Que pueda elegir una o ambas)</Label>
              <Select
                className='custom-input'
                value={currentMember.phaseexpress || ''}
                label=''
                onChange={(e: any) => {
                  handleChange('phaseexpress', e.target.value)
                }}
              >
                <option value=''>Seleccione</option>
                <option value='fase1'> Expresión de interés (postulación para ser invitado)</option>
                <option value='fase2'>
                  Residencia permanente (cuenta con invitación para aplicar).
                </option>
              </Select>
              {!currentMember.phaseexpress && displayErrors === true && (
                <span
                  className='text-tomato-500 text-xs italic'
                  style={{marginTop: '-11px', marginBottom: '11px'}}
                >
                  Debe seleccionar una opcion
                </span>
              )}
            </BoxModal>
          </div>
          <div
            className='button-container-disc2'
            style={{
              display: 'flex',
              width: '100%',
              gap: '3rem',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            <Button onClick={() => setStep(3)} className='backbutton-disc2'>
              Atras
            </Button>
            <Button
              type='button'
              className='startbutton-disc2'
              onClick={() => {
                if (!currentMember.phaseexpress) {
                  setDisplayErrors(true)
                } else {
                  setStep(5)
                }
              }}
            >
              Siguiente
            </Button>
          </div>
        </div>
      )}

      {step === 7 &&
        (currentMember.sponsorship === 'parents' ||
          currentMember.sponsorship === 'minorchildren' ||
          currentMember.sponsorship === 'spouseOrPartner') &&
        currentMember.typeOfApplication === 'Patrocinio familiar' &&
        currentMember.province === 'Quebec' && (
          <div>
            <div className='titledivquestions'>
              <h1></h1>
            </div>
            <div
              className='flex flex-wrap justify-center mb-6'
              style={{fontFamily: 'Lato, sans-serif'}}
            >
              <BoxModal>
                <Label>¿A qué desea aplicar? (Que pueda elegir una o ambas)</Label>
                <Select
                  className='custom-input'
                  value={currentMember.phase || ''}
                  label=''
                  onChange={(e: any) => {
                    handleChange('phase', e.target.value)
                  }}
                >
                  <option value=''>Seleccione</option>
                  <option value='fase1'>
                    Aplicación al gobierno de Quebec (ya posee la aceptación del gobierno federal)
                  </option>
                  {/*<option value='fase2'>Residencia permanente (cuenta con CSQ)</option>*/}
                  <option value='ambas'>
                    Proceso completo residencia permanente (Federal y Quebec)
                  </option>
                </Select>
                {!currentMember.phase && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe seleccionar una opcion
                  </span>
                )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.phase && currentMember.province === 'Quebec') {
                    setDisplayErrors(true)
                  } else if (
                    currentMember.sponsorship === 'spouseOrPartner' &&
                    currentMember.province === 'Quebec' &&
                    currentMember.phase &&
                    currentMember.inCanada === 'si'
                  ) {
                    setStep(8)
                  } else {
                    setDisplayErrors(false)
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}

      {step === 7 &&
        currentMember.typeOfApplication === 'PNP' &&
        currentMember.province === 'Quebec' && (
          <div>
            <div
              className='flex flex-wrap -mx-3 mb-6'
              style={{
                fontFamily: 'Lato, sans-serif',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BoxModal>
                <Label>¿A qué desea aplicar? </Label>
                <div className='checkboxes-container'>
                  <Select
                    className='custom-input'
                    value={currentMember.pnpprogram || ''}
                    label=''
                    onChange={(e: any) => {
                      handleChange('pnpprogram', e.target.value)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='RSWP'>
                      Programa Regular de Trabajadores Calificados (RSWP)
                    </option>
                    <option value='PEQ'>Programa Experiencia Québec (PEQ)</option>
                  </Select>
                </div>
                {!currentMember.pnpprogram && displayErrors === true && (
                  <span
                    className='text-tomato-500 text-xs italic'
                    style={{marginTop: '-11px', marginBottom: '11px'}}
                  >
                    Debe seleccionar una opcion
                  </span>
                )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.pnpprogram) {
                    setDisplayErrors(true)
                  } else {
                    setStep(8)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}

      {step === 8 &&
        currentMember.typeOfApplication === 'PNP' &&
        currentMember.pnpprogram === 'RSWP' && (
          <div>
            <div className='titledivquestions'>
              <h1></h1>
            </div>
            <div
              className='flex flex-wrap -mx-3 mb-6'
              style={{
                fontFamily: 'Lato, sans-serif',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BoxModal>
                <div className='titledivquestions'>
                  <h1>¿A qué desea aplicar?</h1>
                </div>
                <div className='checkboxes-container'>
                  <label className='radio-selector'>
                    <input
                      style={{marginRight: '10px'}}
                      type='checkbox'
                      checked={currentMember.expresion === true}
                      onChange={(e) => {
                        if (currentMember.csq !== true && currentMember.rpcsq !== true) {
                          handleChange('expresion', e.target.checked)
                        }
                      }}
                    />
                    <p>Expresión de interés (postulación para ser invitado)</p>
                  </label>
                  <label className='radio-selector'>
                    <input
                      style={{marginRight: '10px'}}
                      type='checkbox'
                      checked={currentMember.csq === true}
                      onChange={(e) => {
                        if (currentMember.expresion !== true) {
                          handleChange('csq', e.target.checked)
                        }
                      }}
                    />
                    <p>CSQ</p>
                  </label>
                  <label className='radio-selector'>
                    <input
                      style={{marginRight: '10px'}}
                      type='checkbox'
                      checked={currentMember.rpcsq === true}
                      onChange={(e) => {
                        if (currentMember.expresion !== true) {
                          handleChange('rpcsq', e.target.checked)
                        }
                      }}
                    />
                    <p> Residencia permanente (cuenta con CSQ).</p>
                  </label>
                </div>
                {currentMember.expresion !== true &&
                  currentMember.csq !== true &&
                  currentMember.rpcsq !== true &&
                  displayErrors === true && (
                    <span
                      className='text-tomato-500 text-xs italic'
                      style={{marginTop: '-11px', marginBottom: '11px'}}
                    >
                      Debe seleccionar una opcion
                    </span>
                  )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (
                    currentMember.expresion !== true &&
                    currentMember.csq !== true &&
                    currentMember.rpcsq !== true
                  ) {
                    setDisplayErrors(true)
                  } else {
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}

      {step === 8 &&
        currentMember.typeOfApplication === 'PNP' &&
        currentMember.pnpprogram === 'PEQ' && (
          <div>
            <div
              className='flex flex-wrap -mx-3 mb-6'
              style={{
                fontFamily: 'Lato, sans-serif',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <BoxModal>
                <div className='titledivquestions'>
                  <h1>¿A qué desea aplicar?</h1>
                </div>
                <div className='checkboxes-container'>
                  <label className='radio-selector'>
                    <input
                      style={{marginRight: '10px'}}
                      type='checkbox'
                      checked={currentMember.csqPEQ}
                      onChange={(e) => handleChange('csqPEQ', e.target.checked)}
                    />
                    <p>CSQ</p>
                  </label>
                  <label className='radio-selector'>
                    <input
                      style={{marginRight: '10px'}}
                      type='checkbox'
                      checked={currentMember.rpcsqPEQ === true}
                      onChange={(e) => {
                        handleChange('rpcsqPEQ', e.target.checked)
                      }}
                    />
                    <p>Residencia permanente (cuenta con CSQ)</p>
                  </label>
                </div>
                {currentMember.csqPEQ !== true &&
                  currentMember.rpcsqPEQ !== true &&
                  displayErrors === true && (
                    <span
                      className='text-tomato-500 text-xs italic'
                      style={{marginTop: '-11px', marginBottom: '11px'}}
                    >
                      Debe seleccionar una opcion
                    </span>
                  )}
              </BoxModal>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (currentMember.csqPEQ !== true && currentMember.rpcsqPEQ !== true) {
                    setDisplayErrors(true)
                  } else {
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}
      {step === 8 &&
        currentMember.sponsorship === 'spouseOrPartner' &&
        currentMember.typeOfApplication === 'Patrocinio familiar' &&
        currentMember.inCanada === 'si' && (
          <div>
            <div
              className='flex flex-wrap justify-center mb-6 mt-6'
              style={{fontFamily: 'Lato, sans-serif'}}
            >
              <div className='flex flex-col justify-center items-center'>
                <Label style={{maxWidth: '35rem', padding: '20px'}}>
                  Es probable que sea elegible para solicitar un permiso de trabajo una vez la
                  solicitud de residencia permanente este siendo procesada por el Gobierno de
                  Canadá. ¿Desea incluir este servicio?
                </Label>

                <BoxModal>
                  <Select
                    className='custom-input'
                    value={currentMember.addOpenWork || ''}
                    label=''
                    onChange={(e: any) => {
                      handleChange('addOpenWork', e.target.value)
                    }}
                  >
                    <option value=''>Seleccione</option>
                    <option value='si'>Si</option>
                    <option value='no'>No</option>
                  </Select>
                  {!currentMember.addOpenWork && displayErrors === true && (
                    <span
                      className='text-tomato-500 text-xs italic'
                      style={{marginTop: '-11px', marginBottom: '11px'}}
                    >
                      Debe seleccionar una opcion
                    </span>
                  )}
                </BoxModal>
              </div>
            </div>
            <div
              className='button-container-disc2'
              style={{
                display: 'flex',
                width: '100%',
                gap: '3rem',
                justifyContent: 'center',
                marginTop: '2rem',
              }}
            >
              <Button onClick={() => setStep(3)} className='backbutton-disc2'>
                Atras
              </Button>
              <Button
                type='button'
                className='startbutton-disc2'
                onClick={() => {
                  if (!currentMember.addOpenWork) {
                    setDisplayErrors(true)
                  } else {
                    setDisplayErrors(false)
                    setStep(5)
                  }
                }}
              >
                Siguiente
              </Button>
            </div>
          </div>
        )}

      {/*  {step === 6 && (currentMember.programAplication === 'canadian experience class' || currentMember.typeOfApplication === 'Express Entry' || currentMember.typeOfApplication === 'RP nominación provincial' || currentMember.typeOfApplication === 'RP nivel federal') &&
      <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Label><h1>Opción de visa todavia en construccion, para mas informacio llene el siguiente formulario </h1>  </Label>
        <Label><a style={{ fontFamily: 'Lato, sans-serif', color: 'red', textDecoration: 'underline', fontSize: '1.5rem', marginBottom: '1rem' }} target="_blank" href='https://form.jotform.com/222796187234666' rel="noreferrer">https://form.jotform.com/222796187234666</a></Label>
        <img src="https://i.ibb.co/59HShXT/WORK-IN-PROCESS-title.png" alt="Imagen de obrero construyendo" />
      </div>
    } */}
    </>
  )

}

export default PermanentRecidence
